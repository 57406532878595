import React from 'react'

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { ResourceContextProvider, useNotify, useRefresh, useTranslate } from 'react-admin'

import { BeneficiaryCreate } from '../Beneficiaries/BeneficiaryCreate'
import genericStyles from './../../generics.module.scss'

type ImportCreateBeneficiaryModalProps = {
  isModalOpen: boolean
  setIsModalOpen: (b: boolean) => void
}

export const ImportCreateBeneficiaryModal = (props: ImportCreateBeneficiaryModalProps) => {
  const { isModalOpen, setIsModalOpen } = props
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()

  const onCreate = () => {
    refresh()
    setIsModalOpen(false)
    notify(translate('pages.importsAmendment.toast_addBeneficiarySuccess'), {
      type: 'success',
    })
  }

  return (
    <Dialog aria-labelledby="create-beneficiary-modal" maxWidth="md" open={isModalOpen}>
      <DialogTitle id="create-beneficiary-modal" className={genericStyles.modalTitle}>
        {translate('pages.beneficiaries.addBeneficiary')}
        <Close className={genericStyles.closeModal} onClick={() => setIsModalOpen(false)} />
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Grid item direction="row" spacing={4}>
          <ResourceContextProvider value="beneficiaries">
            <BeneficiaryCreate resource="beneficiaries" onCreate={onCreate} />
          </ResourceContextProvider>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

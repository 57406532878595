import React, { useCallback, useContext, useState } from 'react'

import SaveIcon from '@mui/icons-material/Save'
import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material'
import { AxiosError } from 'axios'
import { Button, useNotify, useTranslate } from 'react-admin'

import { LXField } from '../../components/LXField'
import { AnalyticsContext, AnalyticsDispatchContext } from '../../contexts/AnalyticsContext'
import { API } from '../../helpers/Api.helper'
import { AnalyticsPreset, CreateAnalyticsPreset } from '../../types/Analytics'

export const AnalyticsSavePreset = (): JSX.Element => {
  const context = useContext(AnalyticsContext)
  const { setCurrentPreset } = useContext(AnalyticsDispatchContext)
  const notify = useNotify()
  const translate = useTranslate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [presetName, setPresetName] = useState<string>('')

  const onPresetSave = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await API.post<CreateAnalyticsPreset, AnalyticsPreset>(
        '/api/datavizPresets/',
        {
          name: presetName,
          settings: {
            startDate: context.startDate.toDate(),
            endDate: context.endDate.toDate(),
            groupBy: context.groupBy ? [context.groupBy] : [],
            artworkIds: context.selectedArtworks.map(sa => sa.id),
            beneficiaryIds: context.selectedBeneficiaries.map(sb => sb.id),
            CMSs: context.selectedCMSs.map(sc => sc.id),
            productIds: context.selectedProducts.map(sp => sp.id),
            productTypeIds: context.selectedProductTypes.map(sp => sp.id),
            revenueTypes: context.selectedRevenueTypes.map(rt => rt.id),
            youtubeAssetChannelIds: context.selectedRevenueTypes.map(rt => rt.id),
          },
        },
      )
      notify(translate('pages.analytics.toast_createPresetSuccess'), {
        type: 'success',
      })
      setCurrentPreset(response.data)
      setIsLoading(false)
      setIsModalOpen(false)
      setPresetName('')
    } catch (e: unknown) {
      const message =
        (e as AxiosError)?.response?.data.message || (e as AxiosError)?.response?.data || e

      notify(`${translate('pages.analytics.toast_createPresetError')} - ${message}`, {
        type: 'error',
      })
      console.error('Error - save preset', e)
    }
  }, [
    context.endDate,
    context.groupBy,
    context.selectedArtworks,
    context.selectedBeneficiaries,
    context.selectedCMSs,
    context.selectedProductTypes,
    context.selectedProducts,
    context.selectedRevenueTypes,
    context.startDate,
    notify,
    presetName,
    setCurrentPreset,
    translate,
  ])

  return (
    <>
      <Button
        variant="contained"
        label={translate('pages.analytics.savePreset')}
        onClick={() => setIsModalOpen(true)}
      >
        <SaveIcon />
      </Button>
      <Dialog
        aria-labelledby="analytics-save-preset"
        open={isModalOpen}
        maxWidth="xs"
        fullWidth
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle id="analytics-save-preset">
          {translate('pages.analytics.savePreset')}
        </DialogTitle>
        <DialogContent dividers>
          <LXField label={translate('pages.analytics.presetName')} size={12} labeled>
            <Input
              id="preset-name"
              type="string"
              value={presetName}
              onChange={e => {
                setPresetName(e.target.value)
              }}
            />
          </LXField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false)
            }}
            label={translate('_generics.cancel')}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onPresetSave}
            label={translate('_generics.save')}
            disabled={isLoading}
          >
            <SaveIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import React, { useState } from 'react'

import { Grid, GridSize } from '@mui/material'
import { ImageInput, ImageInputProps, RaRecord, useRecordContext } from 'react-admin'

import { FileHelper } from './../helpers/File.helper'
import dropzoneStyles from './LXDropzone/LXDropzone.module.scss'
import styles from './LXImage/LXImage.module.scss'

export const LXImageInput = (props: LXImageInputProps & ImageInputProps): JSX.Element => {
  const { source, gridSize, label, placeholder } = props
  const record = useRecordContext() ?? props.record
  const [imageURL, setImageURL] = useState<string>(record?.[source as string])

  return (
    <Grid item lg={gridSize || 2} className={styles.image}>
      <ImageInput
        source={source}
        accept="image/*"
        label={label}
        placeholder={placeholder}
        className={dropzoneStyles.container}
        onChange={async (f: File) => {
          const base64Image = await FileHelper.convertFileToBase64(f)
          setImageURL((base64Image as string) || '')
        }}
      >
        <img src={imageURL} alt={source} />
      </ImageInput>
    </Grid>
  )
}

type LXImageInputProps = {
  source: string
  gridSize?: GridSize
  record?: RaRecord
}

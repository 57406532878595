import React, { useRef } from 'react'

import { debounce } from '@mui/material'
import { IInfiniteRowModel, PaginationChangedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

export const useAgGridBulkSelect = (
  _gridRef: React.RefObject<AgGridReact<any>>,
  { allowNodeToBeSelected }: { allowNodeToBeSelected?: (node: any) => boolean } = {},
) => {
  const gridRef = useRef<AgGridReact<any> | null>()
  gridRef.current = _gridRef.current

  const [selection, setSelection] = React.useState<any[]>([])
  const totalSelectionCount = React.useMemo(() => {
    return selection.length
  }, [selection])

  const onSelectionChangedToDebounce = React.useCallback(() => {
    const selectedNodes = gridRef.current?.api.getSelectedNodes()
    setSelection(selectedNodes ?? [])
  }, [])

  const onSelectionChanged = React.useMemo(() => {
    return debounce(onSelectionChangedToDebounce, 10)
  }, [onSelectionChangedToDebounce])

  const onPaginationChanged = React.useCallback((event: PaginationChangedEvent) => {
    setCurrentPage(event.api.paginationGetCurrentPage() + 1)
  }, [])

  const [currentPageHasEverythingSelected, setCurrentPageHasEverythingSelected] =
    React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  const currentPageRef = React.useRef(currentPage)
  currentPageRef.current = currentPage

  const getCurrentlyDisplayedRange = React.useCallback(() => {
    const totalRowsCount = gridRef.current?.api?.getDisplayedRowCount()
    const pageSize = gridRef.current?.api?.paginationGetPageSize()
    const totalPages = gridRef.current?.api?.paginationGetTotalPages()

    if (!totalRowsCount || !pageSize || !totalPages) {
      return {}
    }
    let currentPageSize = pageSize
    if (currentPage === totalPages) {
      currentPageSize = totalRowsCount % pageSize
      if (currentPageSize === 0) {
        currentPageSize = pageSize
      }
    }

    const nodeIndexToStartSelection = (currentPage - 1) * pageSize
    const nodeIndexToEndSelection = nodeIndexToStartSelection + currentPageSize

    return {
      currentPageSize,
      nodeIndexToStartSelection,
      nodeIndexToEndSelection,
    }
  }, [currentPage])

  React.useEffect(() => {
    const { currentPageSize, nodeIndexToStartSelection, nodeIndexToEndSelection } =
      getCurrentlyDisplayedRange()
    if (
      typeof nodeIndexToStartSelection !== 'number' ||
      typeof nodeIndexToEndSelection !== 'number'
    ) {
      return
    }
    const currentPageNodes = selection.filter(node => {
      const rowIndex = node.rowIndex

      return rowIndex >= nodeIndexToStartSelection && rowIndex < nodeIndexToEndSelection
    })
    setCurrentPageHasEverythingSelected(currentPageNodes.length === currentPageSize)
  }, [selection, getCurrentlyDisplayedRange])

  const onCheckAllChanged = React.useCallback(
    (_: unknown, checked: boolean) => {
      const { nodeIndexToStartSelection, nodeIndexToEndSelection } = getCurrentlyDisplayedRange()
      if (
        typeof nodeIndexToStartSelection !== 'number' ||
        typeof nodeIndexToEndSelection !== 'number'
      ) {
        return
      }
      ;(gridRef.current?.api.getModel() as IInfiniteRowModel).forEachNode(node => {
        const rowIndex = node.rowIndex
        if (typeof rowIndex !== 'number') {
          return
        }
        if (rowIndex >= nodeIndexToStartSelection && rowIndex < nodeIndexToEndSelection) {
          if (allowNodeToBeSelected && !allowNodeToBeSelected(node)) {
            return
          }
          node.setSelected(checked)
        }
      })
      setCurrentPageHasEverythingSelected(checked)
    },
    [getCurrentlyDisplayedRange, allowNodeToBeSelected],
  )

  return {
    onSelectionChanged,
    onPaginationChanged,
    onCheckAllChanged,
    currentPageHasEverythingSelected,
    totalSelectionCount,
    selection,
  }
}

import dayjs from 'dayjs'
import slug from 'slug'
import { v4 as uuidv4 } from 'uuid'

import { DateHelper } from './../helpers/Date.helper'
import { PeriodicRate, Rate } from './../types/Rates'
import { API } from './Api.helper'
import { FileHelper } from './File.helper'
import { STATEMENT_PERIODS } from './Statements.helper'

export type RateEditable = Rate & {
  isLocal?: boolean
}

type StatementDownloadMonths = {
  beneficiaryId: string
  beneficiaryName: string
  isBasic: boolean
  startMonth: number
  startYear: number
  numberOfMonths: number
  period: STATEMENT_PERIODS
}

export const BeneficiaryHelper = {
  downloadFile: async (
    path: string,
    defaultFilename: string,
    isHeaderFilenameIgnored = false,
  ): Promise<void> => {
    return API.get<BlobPart>(path, { responseType: 'blob' }).then(response => {
      return FileHelper.handleDownloadFileResponse(
        response,
        defaultFilename,
        isHeaderFilenameIgnored,
      )
    })
  },
  downloadPdf: (statementId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/statements/${statementId}/statement.pdf`,
      `${statementId}.pdf`,
    )
  },
  downloadCsv: async (statementId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/statements/${statementId}/statement.csv`,
      `${statementId}.csv`,
    )
  },
  downloadBasicCsv: async (statementId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/statements/${statementId}/basic-statement.csv`,
      `${statementId}.csv`,
    )
  },
  downloadArtworkChannelCsv: async (statementId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/statements/${statementId}/artwork-channel-statement.csv`,
      `${statementId}.csv`,
    )
  },
  downloadMonthlyStatementCsv: async ({
    beneficiaryId,
    beneficiaryName,
    isBasic,
    startMonth,
    startYear,
    numberOfMonths,
    period,
  }: StatementDownloadMonths): Promise<void> => {
    const path =
      `/api/beneficiaries/${beneficiaryId}/monthlyStatement.csv` +
      `?isBasic=${isBasic}` +
      `&startMonth=${startMonth}` +
      `&startYear=${startYear}` +
      `&numberOfMonths=${numberOfMonths}`

    return BeneficiaryHelper.downloadFile(
      path,
      `${slug(beneficiaryName)}_statements_periodic_monthByMonth_${startYear}-${period}${
        isBasic ? '-basic' : ''
      }.csv`,
      true,
    )
  },
  downloadBeneficiaryCatalogCsv: async (beneficiaryId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/beneficiaries/${beneficiaryId}/catalog.csv`,
      `${beneficiaryId}.csv`,
    )
  },
  downloadBeneficiaryAssetsCsv: async (beneficiaryId: string): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/beneficiaries/${beneficiaryId}/assets.csv`,
      `${beneficiaryId}.csv`,
    )
  },
  downloadStatementsSummaryCsv: async (month: number, year: number): Promise<void> => {
    return BeneficiaryHelper.downloadFile(
      `/api/statements/${month}/${year}/summary.csv`,
      `statements_summary_${month}-${year}.csv`,
    )
  },
  downloadSummedStatementCsv: async ({
    beneficiaryId,
    beneficiaryName,
    isBasic,
    startMonth,
    startYear,
    numberOfMonths,
    period,
  }: StatementDownloadMonths): Promise<void> => {
    const path =
      `/api/beneficiaries/${beneficiaryId}/summedStatement.csv` +
      `?isBasic=${isBasic}` +
      `&startMonth=${startMonth}` +
      `&startYear=${startYear}` +
      `&numberOfMonths=${numberOfMonths}`

    return BeneficiaryHelper.downloadFile(
      path,
      `${slug(beneficiaryName)}_statements_periodic_${startYear}-${period}${
        isBasic ? '-basic' : ''
      }.csv`,
      true,
    )
  },
  downloadSummedStatementPdf: async ({
    beneficiaryId,
    beneficiaryName,
    startMonth,
    startYear,
    numberOfMonths,
    period,
  }: Omit<StatementDownloadMonths, 'isBasic'>): Promise<void> => {
    const path =
      `/api/beneficiaries/${beneficiaryId}/summedStatement.pdf` +
      `?startMonth=${startMonth}` +
      `&startYear=${startYear}` +
      `&numberOfMonths=${numberOfMonths}`

    return BeneficiaryHelper.downloadFile(
      path,
      `${slug(beneficiaryName)}_statements_periodic_${startYear}-${period}.pdf`,
      true,
    )
  },
  defaultRate: (defaultDate?: dayjs.Dayjs): RateEditable => {
    return {
      id: uuidv4(),
      rate: 10,
      startDate: defaultDate || DateHelper.january2021Date(),
      endDate: defaultDate || DateHelper.currentDate(),
      isLocal: true,
    }
  },
  formatPeriodicRates: (data: PeriodicRate[]): Rate[] =>
    data.map(r => ({
      id: r.id ?? uuidv4(),
      rateId: r.id,
      rate: Math.round(r.rate * 10000) / 100,
      startDate: dayjs(r.startDate),
      endDate: dayjs(r.endDate),
    })),
  compareStartEndDate: (rA: RateEditable, rB: RateEditable): number => {
    const startA = rA.startDate.startOf('month').unix()
    const startB = rB.startDate.startOf('month').unix()
    if (startA === startB) {
      const endA = rA.endDate?.startOf('month').unix()
      const endB = rB.endDate?.startOf('month').unix()
      if (endA === endB) {
        return 0
      }
      if (typeof endA === 'undefined') {
        return 1
      }
      if (typeof endB === 'undefined') {
        return -1
      }

      return endA - endB
    }

    return startA - startB
  },
}

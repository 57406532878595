import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'

import { API } from './../helpers/Api.helper'
import { getJWT } from './../helpers/Auth.helper'

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  const headers: Headers = options.headers as unknown as Headers
  headers.set(API.KEY_HEADER, API.KEY)
  headers.set('Authorization', `Bearer ${getJWT()}`)
  headers.set('x-current-pathname', window.location.pathname)

  return fetchUtils.fetchJson(url, { ...options, headers })
}

const simpleDataProvider = simpleRestProvider(`${API.URL}/api`, httpClient, 'X-Total-Count')

export const dataProvider = {
  ...simpleDataProvider,
  create: async (resource: string, params: any): Promise<any> => {
    if (resource === 'beneficiaries' && params.data.logoBase64?.rawFile) {
      const file = params.data.logoBase64.rawFile
      const createParams = {
        ...params,
        data: {
          ...params.data,
          logoBase64: null,
        },
      }

      // Create all the other informations before updating the logo
      const dataProviderCreate = await simpleDataProvider.create(resource, createParams)

      // Add logo
      await updateLogo(dataProviderCreate.data.id as string, file)

      return dataProviderCreate
    }

    return simpleDataProvider.create(resource, params)
  },
  update: async (resource: string, params: any): Promise<any> => {
    if (resource === 'beneficiaries' && params.data.logoBase64?.rawFile) {
      const file = params.data.logoBase64.rawFile
      const updatedParams = {
        ...params,
        data: {
          ...params.data,
          logoBase64: null,
        },
      }

      // Update all the other informations before updating the logo
      const dataProviderUpdate = await simpleDataProvider.update(resource, updatedParams)

      // Update logo
      await updateLogo(params.id, file)

      return dataProviderUpdate
    }

    return simpleDataProvider.update(resource, params)
  },
}

const updateLogo = async (beneficiaryId: string, file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    await API.put<FormData>(`/api/beneficiaries/${beneficiaryId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  } catch (e) {
    console.error('Error on image update', e)
  }
}

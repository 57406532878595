import React from 'react'

import { Toolbar, Typography } from '@mui/material'
import { AppBar as RaAppbar, AppBarProps } from 'react-admin'

import styles from './AppBar.module.scss'

const LOGO_PATH = '/lx-logo.png'

export const AppBar = (props: AppBarProps): JSX.Element => {
  const appBarStyle = (() => {
    const { REACT_APP_ENV } = process.env
    if (REACT_APP_ENV === 'production') {
      return {}
    }
    if (REACT_APP_ENV === 'staging') {
      return { backgroundColor: 'orange' }
    }

    return { backgroundColor: 'grey' }
  })()

  return (
    <RaAppbar {...props} color="secondary" style={appBarStyle}>
      <Toolbar>
        <img src={LOGO_PATH} alt="logo" className={styles.logo} />
      </Toolbar>
      <Typography variant="h6" color="inherit" className={styles.title} id="react-admin-title" />
    </RaAppbar>
  )
}

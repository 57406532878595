import React, { useCallback, useEffect, useMemo } from 'react'

import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useGetList, useNotify, useTranslate } from 'react-admin'
import stableStringify from 'safe-stable-stringify'

import {
  AnalyticsProvider,
  useAnalyticsContext,
  useAnalyticsDispatchContext,
} from '../../contexts/AnalyticsContext'
import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { API } from '../../helpers/Api.helper'
import { FileHelper } from '../../helpers/File.helper'
import { AnalyticsDataDTO, AnalyticsDataResponse, AnalyticsSettings } from '../../types/Analytics'
import styles from './Analytics.module.scss'
import { AnalyticsActions } from './AnalyticsActions'
import { AnalyticsFilter } from './AnalyticsFilters'
import { AnalyticsMetricSelector } from './AnalyticsMetricSelector'
import { AnalyticsTable } from './AnalyticsTable'
import { LXChart } from './LXChart'
import { MapChart } from './MapChart'

const ContextualisedAnalytics = (): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()
  const context = useAnalyticsContext()
  const { setSelectedMapMetric, setSelectedChartMetrics, setSelectedSeriesIdsByGroupBy } =
    useAnalyticsDispatchContext()

  const beneficiariesQuery = useGetList('beneficiaries', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const productsQuery = useGetList(
    'products',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        beneficiaryId:
          context.selectedBeneficiaries.length > 0
            ? context.selectedBeneficiaries?.map(sb => sb.id)
            : undefined,
      },
    },
    { enabled: context.selectedBeneficiaries.length > 0 },
  )

  const productTypesQuery = useGetList('productTypes', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const artworksQuery = useGetList(
    'artworks',
    {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {
        productId:
          context.selectedArtworks.length > 0
            ? context.selectedArtworks?.map(sb => sb.id)
            : undefined,
      },
    },
    {
      enabled:
        context.selectedProducts.length > 0 ||
        (context.selectedBeneficiaries.length > 0 && context.selectedProducts.length === 0),
    },
  )

  const youtubeChannelsQuery = useGetList('youtubeChannels', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const revenueTypes = useMemo(
    () =>
      AnalyticsHelper.REVENUE_TYPE.map(rt => ({
        id: rt,
        name: translate(`pages.analytics.revenueTypes.${rt}`),
      })).sort((a, b) => a.name.localeCompare(b.name)),
    [translate],
  )

  const analyticsQueryKey = useMemo<{
    presetId?: string
    settings?: AnalyticsSettings
  }>(() => {
    if (context.currentPreset) {
      return { presetId: context.currentPreset?.id }
    }

    return {
      settings: {
        startDate: context.startDate.toDate(),
        endDate: context.endDate.toDate(),
        groupBy: context.groupBy ? [context.groupBy] : [],
        artworkIds: context.selectedArtworks.map(sa => sa.id),
        CMSs: context.selectedCMSs.map(cms => cms.id),
        beneficiaryIds: context.selectedBeneficiaries.map(sb => sb.id),
        productIds: context.selectedProducts.map(sp => sp.id),
        productTypeIds: context.selectedProductTypes.map(sp => sp.id),
        revenueTypes: context.selectedRevenueTypes.map(rt => rt.id),
        youtubeAssetChannelIds: context.selectedYoutubeChannels.map(rt => rt.id),
      },
    }
  }, [context])

  const stableStringifiedAnalyticsQueryKey = useMemo(
    () => stableStringify(analyticsQueryKey),
    [analyticsQueryKey],
  )
  useEffect(() => {
    // Reset the selected series ids when the analytics query key changes
    setSelectedSeriesIdsByGroupBy({})
  }, [stableStringifiedAnalyticsQueryKey])

  const analyticsQuery = useQuery(
    ['analytics', analyticsQueryKey],
    async () => {
      if (analyticsQueryKey.presetId) {
        return (
          await API.get<AnalyticsDataResponse>(
            `/api/dataviz/presets/${analyticsQueryKey.presetId}/data`,
          )
        ).data
      }

      return (
        await API.post<AnalyticsDataDTO, AnalyticsDataResponse>('/api/dataviz/data', {
          settings: analyticsQueryKey.settings,
        })
      ).data
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60,
    },
  )

  const exportCSV = useCallback(
    async ({ singleMetric }: { singleMetric?: string } = {}) => {
      if (!analyticsQuery.data) {
        return
      }
      let promise: Promise<AxiosResponse<AnalyticsDataResponse, any>>
      if (analyticsQueryKey.presetId) {
        promise = API.get<AnalyticsDataResponse>(
          `/api/dataviz/presets/${analyticsQueryKey.presetId}/data.csv`,
          {
            params: { singleMetric },
          },
        )
      } else {
        promise = API.post<AnalyticsDataDTO, AnalyticsDataResponse>(
          '/api/dataviz/data.csv',
          {
            settings: analyticsQueryKey.settings,
          },
          { params: { singleMetric } },
        )
      }
      try {
        const data = await promise
        await FileHelper.handleDownloadFileResponse(data, 'dataviz.csv')
      } catch (e) {
        notify('pages.analytics.toast_exportToCsvError')
      }
    },
    [analyticsQuery.data, analyticsQueryKey.presetId, analyticsQueryKey.settings, notify],
  )

  const selectedGroupKeys = useMemo(() => {
    if (!context.groupBy) {
      return undefined
    }

    return context.selectedSeriesIdsByGroupBy[context.groupBy]
  }, [context.groupBy, context.selectedSeriesIdsByGroupBy])

  return (
    <>
      <AnalyticsActions revenueTypes={revenueTypes} />
      <Card>
        <CardContent className={styles.container}>
          <Grid container direction="column" spacing={4}>
            <AnalyticsFilter
              beneficiaries={beneficiariesQuery?.data ?? []}
              products={productsQuery?.data ?? []}
              productTypes={productTypesQuery?.data ?? []}
              artworks={artworksQuery?.data ?? []}
              revenueTypes={revenueTypes}
              youtubeChannels={youtubeChannelsQuery?.data ?? []}
            />
            {!analyticsQuery.isLoading ? (
              context.groupBy === 'countryCode' ? (
                <Grid item lg={12}>
                  <AnalyticsMetricSelector
                    metric={context.selectedMapMetric}
                    onChange={setSelectedMapMetric}
                  />
                  <MapChart data={analyticsQuery.data} metric={context.selectedMapMetric} />
                </Grid>
              ) : (
                <Grid item lg={12}>
                  <AnalyticsMetricSelector
                    metric={context.selectedChartMetrics[0]}
                    onChange={m =>
                      setSelectedChartMetrics(mis => {
                        mis[0] = m
                      })
                    }
                  />
                  <LXChart
                    data={analyticsQuery.data}
                    metric={context.selectedChartMetrics[0]}
                    selectedGroupKeys={selectedGroupKeys}
                    exportCSVSingleMetric={exportCSV}
                  />
                  <AnalyticsMetricSelector
                    metric={context.selectedChartMetrics[1]}
                    onChange={m =>
                      setSelectedChartMetrics(mis => {
                        mis[1] = m
                      })
                    }
                  />
                  <LXChart
                    data={analyticsQuery.data}
                    metric={context.selectedChartMetrics[1]}
                    selectedGroupKeys={selectedGroupKeys}
                    exportCSVSingleMetric={exportCSV}
                  />
                </Grid>
              )
            ) : (
              <Grid container lg={12} justifyContent="center" className={styles.loader}>
                <Grid item>
                  <CircularProgress size="4rem" />
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <AnalyticsTable
          groupBy={context.groupBy}
          data={analyticsQuery.data}
          metrics={
            context.groupBy === 'countryCode'
              ? [context.selectedMapMetric]
              : context.selectedChartMetrics
          }
          exportCSV={exportCSV}
        />
      </Card>
    </>
  )
}

export const Analytics = (): JSX.Element => {
  return (
    <AnalyticsProvider>
      <ContextualisedAnalytics />
    </AnalyticsProvider>
  )
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Divider, Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  Create,
  CreateProps,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  required,
  useRedirect,
  useTranslate,
} from 'react-admin'

import { LXCreatePeriodicRates } from './../../components/LXCreatePeriodicRates'
import { LXField } from './../../components/LXField'
import genericStyles from './../../generics.module.scss'
import { API } from './../../helpers/Api.helper'
import { ListHelper } from './../../helpers/List.helper'
import { Rate } from './../../types/Rates'

const ProductCreateToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton disabled={false} />
  </Toolbar>
)

export const ProductCreate: React.FC<ProductCreateProps & CreateProps> = props => {
  const { onCreate, initialValues } = props
  const translate = useTranslate()
  const redirect = useRedirect()
  const [rates, setRates] = useState<Rate[]>([])
  const ratesRef = useRef<Rate[]>(rates)

  useEffect(() => {
    ratesRef.current = rates
  }, [rates])

  const onSuccess = useCallback(
    (rateableId: string) => {
      // Save Rates
      Promise.all(
        ratesRef.current.map(({ rate, startDate, endDate }) => {
          const formattedRate = (rate >= 100 ? 100 : rate) / 100

          return API.post('/api/periodicRates', {
            rateable: 'product',
            rateableId,
            startDate: startDate.toDate().toISOString(),
            endDate: endDate?.toDate().toISOString(),
            rate: formattedRate,
          })
        }),
      ).then(() => {
        if (onCreate) {
          onCreate(rateableId)
        } else {
          redirect('show', '/products', rateableId)
        }
      })
    },
    [onCreate, redirect],
  )

  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: (data: { id: string }) => {
          onSuccess(data.id)
        },
      }}
      redirect="false"
    >
      <SimpleForm defaultValues={initialValues} toolbar={<ProductCreateToolbar />}>
        <Typography variant="h6" className={genericStyles.typo}>
          {translate('pages.products._tabs.1')}
        </Typography>
        <ProductCreateInformations />
        <Divider className={genericStyles.divider} />
        <Typography variant="h6" className={genericStyles.typo}>
          {translate('pages.products._tabs.3')}
        </Typography>
        <LXCreatePeriodicRates onRatesUpdate={setRates} hasDefaultRates={false} />
      </SimpleForm>
    </Create>
  )
}

const ProductCreateInformations = () => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" columnSpacing={6}>
      <LXField size={12}>
        <TextInput
          source="name"
          label={translate('_generics.name')}
          validate={[required()]}
          fullWidth
        />
      </LXField>
      <LXField size={6}>
        <ReferenceInput
          source="productTypeId"
          reference="productTypes"
          validate={[required()]}
          fullWidth
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('pages.products.productType')} />
        </ReferenceInput>
      </LXField>
      <LXField size={6}>
        <ReferenceInput
          source="beneficiaryId"
          reference="beneficiaries"
          validate={[required()]}
          fullWidth
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.beneficiary')} />
        </ReferenceInput>
      </LXField>
    </Grid>
  )
}

type ProductCreateProps = {
  onCreate?: (rateableId?: string) => void
  initialValues?: object
}

import React from 'react'
import { useCallback, useState } from 'react'

import CheckIcon from '@mui/icons-material/CheckCircle'
import { CircularProgress, LinearProgress } from '@mui/material'
import { useDataProvider, useTranslate } from 'react-admin'

import styles from './YTImportModal.module.scss'

export const ImportStatus = ({
  youtubeRawImport,
  onComplete,
}: {
  youtubeRawImport: { id: string; name: string }
  onComplete: (opts: { youtubeRawImportId: string; error?: any }) => void
}): JSX.Element => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const [progress, setProgress] = useState(0)
  const [progressMessage, setProgressMessage] = useState(
    translate('pages.youtubeRawImports.progress_yt.step1'),
  )

  const refreshImportStatus = useCallback(async () => {
    let data = { status: '-01_unknown' } as Record<string, any>
    try {
      data = (
        await dataProvider.getOne('youtubeRawImports', {
          id: youtubeRawImport.id,
        })
      ).data
    } catch (err) {
      // sometimes the serveur is unreachable, we just wait a bit and retry
    }
    switch (data.status) {
      case '00_uploaded':
        setProgress(10)
        setProgressMessage(translate('pages.youtubeRawImports.progress_yt.steps.reading'))
        break
      case '05_parsed':
        setProgress(20)
        setProgressMessage(
          translate('pages.youtubeRawImports.progress_yt.steps.refreshing_youtube_channels'),
        )
        break
      case '10_refreshedYoutubeChannels':
        setProgress(40)
        setProgressMessage(
          translate('pages.youtubeRawImports.progress_yt.steps.matching_existing_assets'),
        )
        break
      case '20_matchedAgainstArtworks':
        setProgress(60)
        setProgressMessage(translate('pages.youtubeRawImports.progress_yt.steps.calling_yt_api'))
        break
      case '30_filledAssetTitlesFromAPI':
        setProgress(80)
        break
      case '500_error':
        setProgress(100)
        setProgressMessage(translate('pages.youtubeRawImports.progress_yt.steps.error'))
        onComplete({
          youtubeRawImportId: youtubeRawImport.id,
          error: data.error ?? 'Unknown error',
        })
        break
      case '100_ready':
      case '120_corrected':
        setProgress(100)
        setProgressMessage(translate('pages.youtubeRawImports.progress_yt.steps.success'))
        await new Promise(resolve => setTimeout(resolve, 1500))
        onComplete({ youtubeRawImportId: youtubeRawImport.id })

        return
      default:
        break
    }

    return new Promise<void>((resolve, reject) => {
      setTimeout(async () => {
        try {
          await refreshImportStatus()
          resolve()
        } catch (err) {
          reject(err)
        }
      }, 3000)
    })
  }, [dataProvider, translate, youtubeRawImport.id, onComplete])

  React.useEffect(() => {
    refreshImportStatus()
  }, [refreshImportStatus])

  return (
    <div className={styles.message}>
      <LinearProgress
        variant="determinate"
        color={progress >= 100 ? 'primary' : 'secondary'}
        value={progress}
        style={{ marginTop: '1rem' }}
      />
      <h5>
        {progress >= 100 ? <CheckIcon fontSize="inherit" /> : <CircularProgress size="1rem" />}
        <span className={styles.messageText}>
          {youtubeRawImport.name !== '' ? `${youtubeRawImport.name} | ` : ''}
          {progressMessage}
        </span>
      </h5>
    </div>
  )
}

import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { Refresh } from '@mui/icons-material'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { FormControlLabel, Grid, Link, Switch, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ColDef, ICellRendererParams, IGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import {
  Button,
  Link as RALink,
  RaRecord,
  SortPayload,
  useGetList,
  useGetOne,
  useNotify,
  useTranslate,
} from 'react-admin'

import { LXPageSize } from '../../components/LXPageSize/LXPageSize'
import { YoutubeChannelAutocomplete } from '../../components/YoutubeChannelAutocomplete/YoutubeChannelAutocomplete'
import { API } from '../../helpers/Api.helper'
import { DEFAULT_PAGE_SIZE, YOUTUBE_OWNERS_IDS } from '../../helpers/constants'
import { dataProvider } from '../../providers/dataProvider'
import { YoutubeRawImportRow } from '../../types/YoutubeRawImportRow'
import { AgGridLoader, useAgGridLoader } from './AgGrid/aggridLoader'

export type ImportsAmendAssetTitleProps = {
  youtubeRawImportId: string
  updateUntreatedResults?: () => void
}

export const ImportsAmendAssetTitle = ({
  youtubeRawImportId,
  updateUntreatedResults,
}: ImportsAmendAssetTitleProps): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()

  const gridRef = useRef<AgGridReact>(null)
  const updateUntreatedResultsRef = React.useRef(updateUntreatedResults)
  updateUntreatedResultsRef.current = updateUntreatedResults

  const youtubeRawImportQuery = useGetOne('youtubeRawImports', {
    id: youtubeRawImportId,
  })
  const youtubeChannelsQuery = useGetList('youtubeChannels', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const [selectedYoutubeChannel, setSelectedYoutubeChannel] = React.useState<RaRecord | null>(null)
  const [showWithoutChannels, setShowWithoutChannels] = React.useState(false)
  const [showOnlyIncomplete, setShowOnlyIncomplete] = React.useState(false)

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged()
  }, [showOnlyIncomplete, showWithoutChannels, selectedYoutubeChannel])

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'assetId',
        headerName: 'Asset ID',
        suppressMenu: true,
        resizable: true,
        sortable: true,
        width: 155,
      },
      {
        field: 'internalYoutubeChannel.name',
        headerName: translate('_generics.youtubeChannel'),
        suppressMenu: true,
        resizable: true,
        width: 200,
        valueGetter: params => params.data?.['internalYoutubeChannel.name'],
        cellRenderer: (params: ICellRendererParams<YoutubeRawImportRow>) => {
          if (!params.data) {
            return null
          }
          if (params.data?.originalAssetChannelId) {
            return (
              <RALink
                to={`/youtubeChannels/${params.data.originalAssetChannelId}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </RALink>
            )
          }

          return (
            <YoutubeChannelAutocomplete
              {...params}
              allYoutubeChannels={youtubeChannelsQuery?.data ?? []}
            />
          )
        },
      },
      {
        field: 'linkToStudio',
        headerName: '',
        resizable: true,
        width: 255,
        cellStyle: {
          textAlign: 'center',
          paddingLeft: 0,
          paddingRight: 0,
        } as any,
        cellRenderer: (params: any) => {
          const source = youtubeRawImportQuery.data?.source
          if (!source) {
            return null
          }
          const ownerId = YOUTUBE_OWNERS_IDS[source]
          if (!ownerId) {
            return null
          }
          const assetId = params.data?.assetId
          if (!assetId) {
            return null
          }
          const href = `https://studio.youtube.com/asset/${assetId}/claimed_videos?o=${ownerId}&filter=%5B%7B%22isPinned%22%3Atrue%2C%22name%22%3A%22CLAIM_SOURCE%22%2C%22value%22%3A%5B%22CLAIM_SOURCE_PARTNER_UPLOADED%22%5D%7D%5D&sort=%7B%22columnType%22%3A%22latest%22%2C%22sortOrder%22%3A%22DESCENDING%22%7D`

          return (
            <Link href={href} target="_blank">
              <Button label={translate('pages.youtubeClaims.studioClaimsUrl')}>
                <YouTubeIcon />
              </Button>
            </Link>
          )
        },
      },
      {
        field: 'assetTitleEdited',
        headerName: translate('pages.importsAmendment.assetTitle'),
        suppressMenu: true,
        editable: true,
        singleClickEdit: true,
        resizable: true,
        sortable: true,
        flex: 1,
        cellStyle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          paddingTop: 15,
          paddingBottom: 15,
        },
        wrapText: true,
        autoHeight: true,
        onCellValueChanged: async params => {
          try {
            await API.put(`api/youtubeRawImportRows/${params.data.id}`, {
              assetTitleEdited: params.newValue,
            })
            updateUntreatedResultsRef.current?.()
            notify('pages.importsAmendment.toast_editTitleSuccess', {
              type: 'success',
            })
          } catch (e) {
            params.node?.setData({
              ...params.data,
              assetTitleEdited: params.oldValue,
            })
            notify('pages.importsAmendment.toast_editTitleError', {
              type: 'error',
            })
          }
        },
      },
    ],
    [translate, youtubeChannelsQuery?.data, youtubeRawImportQuery.data?.source, notify],
  )

  const { startLoading, stopLoading } = useAgGridLoader(gridRef)
  const getRows = React.useCallback(
    async (params: IGetRowsParams) => {
      const perPage = gridRef.current?.api.paginationGetPageSize() ?? 100
      startLoading(`${params.startRow}`)
      const data = await dataProvider.getList('youtubeRawImportRows', {
        pagination: { page: params.startRow / perPage + 1, perPage },
        sort:
          params.sortModel.length > 0
            ? ({
                field: params.sortModel[0].colId,
                order: params.sortModel[0].sort.toUpperCase(),
              } as SortPayload)
            : ({ field: 'assetId', order: 'ASC' } as SortPayload),
        filter: {
          youtubeRawImportId,
          ...(showWithoutChannels ? { assetChannelId: true } : {}),
          ...(selectedYoutubeChannel ? { assetChannelId: selectedYoutubeChannel.id } : {}),
          ...(showOnlyIncomplete ? { assetTitleEdited: true } : {}),
        },
      })
      params.successCallback(data.data, data.total)
      stopLoading(`${params.startRow}`)
    },
    [
      youtubeRawImportId,
      selectedYoutubeChannel,
      showWithoutChannels,
      showOnlyIncomplete,
      startLoading,
      stopLoading,
    ],
  )

  const datasource = React.useMemo(() => {
    return { getRows }
  }, [getRows])

  const onYoutubeChannelChange = useCallback(
    (value: RaRecord | { id: ''; name: string } | null) => {
      setShowWithoutChannels(value?.id === '')
      setSelectedYoutubeChannel(value)
    },
    [],
  )

  return (
    <>
      <Grid item container lg={12} justifyContent="space-between" spacing={2} p="1rem">
        <Grid item lg={3}>
          <Autocomplete
            id="import-select-yt-channel"
            options={[
              ...[{ id: '', name: translate('_generics.noChannel') }],
              ...(youtubeChannelsQuery?.data ?? []),
            ]}
            value={selectedYoutubeChannel}
            accessKey="id"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                key={params.id}
                placeholder={translate('_generics.youtubeChannel')}
                variant="standard"
              />
            )}
            noOptionsText={translate('_generics.noResult')}
            onChange={(_, value) => onYoutubeChannelChange(value)}
          />
        </Grid>
        <Grid item justifyContent="flex-end">
          <FormControlLabel
            control={
              <Switch
                value={showOnlyIncomplete}
                onChange={(e, checked) => setShowOnlyIncomplete(checked)}
              />
            }
            label={translate('pages.importsAmendment.showOnlyMissing')}
          />
          <Button
            variant="outlined"
            onClick={() => {
              gridRef.current?.api?.refreshInfiniteCache()
            }}
            label={translate('_generics.refresh')}
          >
            <Refresh />
          </Button>
        </Grid>
      </Grid>
      <div className="ag-theme-material" style={{ width: '100%', height: '80vh' }}>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          ensureDomOrder
          rowModelType="infinite"
          datasource={datasource}
          pagination={true}
          paginationPageSize={DEFAULT_PAGE_SIZE}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          stopEditingWhenCellsLoseFocus
          loadingOverlayComponent={AgGridLoader}
        />
        {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
        <LXPageSize onPageSizeChange={size => gridRef.current?.api!.paginationSetPageSize(size)} />
      </div>
    </>
  )
}

const defaultColDef = {
  cellStyle: { paddingLeft: 8, paddingRight: 8 },
}

import React from 'react'

import EuroIcon from '@mui/icons-material/EuroSymbol'
import { Grid } from '@mui/material'
import {
  Button,
  Datagrid,
  DateField,
  DeleteButton,
  FunctionField,
  Labeled,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useRefresh,
  useTranslate,
} from 'react-admin'

import { useParams } from 'react-router-dom'
import { LXField } from '../../components/LXField'
import { LXNumberField } from '../../components/LXNumberField'
import { LXStatementsDownloads } from '../../components/LXStatementsActions'
import { CreateStatementAdjustmentRowModal } from './CreateStatementAdjustmentRowModal'

export const StatementShow: React.FC = props => {
  const refresh = useRefresh()
  const translate = useTranslate()
  const [isCreateModalOpened, setIsCreateModalOpened] = React.useState(false)
  const params = useParams()
  const statementId = params?.id as string | undefined

  return (
    <>
      <Show>
        <SimpleShowLayout>
          <Grid container direction="column" spacing={2}>
            <Grid
              lg={12}
              container
              item
              alignItems="flex-end"
              justifyContent="flex-end"
              alignContent="flex-end"
            >
              <FunctionField
                render={(record: RaRecord | undefined) => (
                  <>
                    <LXStatementsDownloads record={record} />
                  </>
                )}
              />
            </Grid>
            <Grid container item direction="row" lg={12} spacing={2}>
              <LXField
                source="name"
                label={translate('_generics.beneficiary')}
                elementType={TextField}
                wrappingFunction={el => (
                  <ReferenceField source="beneficiaryId" reference="beneficiaries" link="show">
                    {el}
                  </ReferenceField>
                )}
                labeled
              />
              <Grid item lg={3}>
                <Labeled
                  label={`${translate('_generics.month._title')} / ${translate('_generics.year')}`}
                >
                  <FunctionField
                    source="period"
                    render={(record: any) => (
                      <span>{` ${record.month.toString().padStart(2, '0')} / ${record.year}`}</span>
                    )}
                  />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="videosViews"
                  format="large"
                  label={translate('pages.statements.videosViews')}
                />
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="generatedRevenue"
                  format="euros"
                  label={translate('pages.statements.generatedRevenue')}
                />
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="youtubeGeneratedRevenue"
                  format="euros"
                  label={translate('pages.statements.youtubeGeneratedRevenue')}
                />
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="ugcGeneratedRevenue"
                  format="euros"
                  label={translate('pages.statements.ugcGeneratedRevenue')}
                />
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="appliedRate"
                  format="percentRate"
                  label={translate('pages.statements.appliedRate')}
                />
              </Grid>
              <Grid item lg={3}>
                <LXNumberField
                  source="netRevenue"
                  format="euros"
                  label={translate('pages.statements.netRevenue')}
                />
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('_generics.createdAt')}>
                  <DateField source="createdAt" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <TextField source="commentGeneric" />
              </Grid>
              <TextField source="commentPerfSummary" />
              <TextField source="commentPerfByProductType" />
              <TextField source="commentPerfByProduct" />
            </Grid>
            <Grid item lg={12} mt={4}>
              <TabbedShowLayout>
                <Tab label={translate('pages.statements.statementAdjustmentRows')}>
                  <ReferenceManyField
                    reference="statementAdjustmentRows"
                    target="statementId"
                    source="id"
                    sort={{ field: 'amountInEuros', order: 'DESC' }}
                    perPage={1000}
                    label=""
                  >
                    <Datagrid bulkActionButtons={false}>
                      <TextField source="label" />
                      <LXNumberField
                        source="amountInEuros"
                        format="euros"
                        label={translate('pages.statements.adjustmentRow.amountInEuros')}
                        hideLabel
                      />
                      <LXNumberField
                        source="beneficiaryRevenueInEuros"
                        format="euros"
                        label={translate('pages.statements.beneficiaryRevenueInEuros')}
                        hideLabel
                      />
                      <DeleteButton redirect={false} mutationMode="pessimistic" />
                    </Datagrid>
                  </ReferenceManyField>
                  <Grid item lg={12} justifyContent="center">
                    <Button
                      label={translate('pages.statements.addAdjustmentRow')}
                      onClick={() => setIsCreateModalOpened(true)}
                    >
                      <EuroIcon />
                    </Button>
                  </Grid>
                </Tab>
              </TabbedShowLayout>
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </Show>
      {statementId && (
        <CreateStatementAdjustmentRowModal
          isOpen={isCreateModalOpened}
          statementId={statementId}
          onClose={() => {
            refresh()
            setIsCreateModalOpened(false)
          }}
        />
      )}
    </>
  )
}

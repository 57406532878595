import { StatementFormat } from '../types/Statement'

export enum STATEMENT_PERIODS {
  YEAR = 'YEAR',
  S1 = 'S1',
  S2 = 'S2',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

const YEAR_MONTHS_NUMBER = 12
const SEMESTER_MONTHS_NUMBER = 6
const QUARTER_MONTHS_NUMBER = 3

export const StatementsHelper = {
  periods: {
    [STATEMENT_PERIODS.YEAR]: {
      numberOfMonths: YEAR_MONTHS_NUMBER,
      startMonth: 1,
      periodText: '_generics.year',
    },
    [STATEMENT_PERIODS.S1]: {
      numberOfMonths: SEMESTER_MONTHS_NUMBER,
      startMonth: 1,
      periodText: '_generics.semester',
    },
    [STATEMENT_PERIODS.S2]: {
      numberOfMonths: SEMESTER_MONTHS_NUMBER,
      startMonth: 7,
      periodText: '_generics.semester',
    },
    [STATEMENT_PERIODS.Q1]: {
      numberOfMonths: QUARTER_MONTHS_NUMBER,
      startMonth: 1,
      periodText: '_generics.quarter',
    },
    [STATEMENT_PERIODS.Q2]: {
      numberOfMonths: QUARTER_MONTHS_NUMBER,
      startMonth: 4,
      periodText: '_generics.quarter',
    },
    [STATEMENT_PERIODS.Q3]: {
      numberOfMonths: QUARTER_MONTHS_NUMBER,
      startMonth: 7,
      periodText: '_generics.quarter',
    },
    [STATEMENT_PERIODS.Q4]: {
      numberOfMonths: QUARTER_MONTHS_NUMBER,
      startMonth: 10,
      periodText: '_generics.quarter',
    },
  },
  STATEMENT_FORMATS: <StatementFormat[]>['pdf', 'csv', 'csv-basic', 'artwork-channel-csv'],
}

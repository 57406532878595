import React from 'react'

import { Grid, GridSize } from '@mui/material'
import { Labeled } from 'react-admin'

export const LXField = (props: LXFieldProps): JSX.Element => {
  const { source, label, wrappingFunction, children } = props
  const fieldElement = children ? (
    <>{children}</>
  ) : (
    <props.elementType source={source} label={label} fullWidth />
  )
  const wrappedFieldElement = wrappingFunction ? wrappingFunction(fieldElement) : fieldElement

  return (
    <Grid item lg={props.size ?? 3}>
      {props.labeled ? <Labeled {...props}>{wrappedFieldElement}</Labeled> : wrappedFieldElement}
    </Grid>
  )
}

type LXFieldProps = {
  source?: string
  children?: JSX.Element | string
  labeled?: boolean
  label?: string
  size?: GridSize
  elementType?: any
  wrappingFunction?: (el: JSX.Element | string) => JSX.Element
}

import React, { useCallback, useMemo, useState } from 'react'

import { TextField } from '@mui/material'
import { Autocomplete, AutocompleteRenderInputParams } from '@mui/material'
import { useNotify, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { YoutubeChannel } from '../../types/YoutubeChannel'

export const YoutubeChannelAutocomplete = React.memo(
  (params: any & { allYoutubeChannels: YoutubeChannel[] }) => {
    const translate = useTranslate()
    const notify = useNotify()

    const youtubeChannel = useMemo(
      () =>
        params.allYoutubeChannels.find((yc: any) => yc.id === params.data?.assetChannelId) || null,
      [params.allYoutubeChannels, params.data?.assetChannelId],
    )

    const [selectedYoutubeChannel, setSelectedYoutubeChannel] = useState<YoutubeChannel | null>(
      youtubeChannel,
    )

    React.useEffect(() => {
      setSelectedYoutubeChannel(youtubeChannel)
    }, [youtubeChannel])

    const onYoutubeChannelUpdate = useCallback(
      async (_: unknown, value: YoutubeChannel | null) => {
        setSelectedYoutubeChannel(value)
        try {
          await API.put(`api/youtubeRawImportRows/${params.data?.id}`, {
            assetChannelId: value?.id || null,
            productId: null,
            artworkId: null,
          })
          params.node.setData({
            ...params.data,
            assetChannelId: value?.id,
          })
        } catch (e) {
          setSelectedYoutubeChannel(youtubeChannel)
          notify('pages.importsAmendment.toast_editYoutubeChannelError', {
            type: 'error',
          })
        }
      },
      [youtubeChannel, params.data?.id, params.node],
    )

    return useMemo(
      () => (
        <Autocomplete
          id={`youtubeChannelAutocomplete-${params.data?.id}`}
          options={params.allYoutubeChannels as YoutubeChannel[]}
          accessKey="id"
          value={selectedYoutubeChannel}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.name}
          renderInput={(p: AutocompleteRenderInputParams) => (
            <TextField {...p} key={p.id} style={{ marginTop: '0.5rem' }} variant="standard" />
          )}
          noOptionsText={translate('_generics.noResult')}
          onChange={onYoutubeChannelUpdate}
        />
      ),
      [onYoutubeChannelUpdate, params, selectedYoutubeChannel, translate],
    )
  },
)

import React, { useState } from 'react'

import { Theaters } from '@mui/icons-material'
import { CircularProgress, Grid } from '@mui/material'
import { Button, Title, useNotify, useRefresh, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'

export const ImportsAmendArtwork = ({
  importId,
  importTitle,
  onChange,
}: ImportsAmendArtworkProps): JSX.Element => {
  const notify = useNotify()
  const refresh = useRefresh()
  const translate = useTranslate()
  const [isLoading, setIsLoading] = useState(false)

  const onAllArtworksCreate = async () => {
    setIsLoading(true)
    try {
      await API.post(`/api/youtubeRawImports/${importId}/artworksCreation`, {})

      onChange()
      setIsLoading(false)
      notify('pages.importsAmendment.allArtworksCreationSuccess', {
        type: 'success',
      })
      refresh()
    } catch (e: any) {
      setIsLoading(false)
      notify(
        `${translate('pages.importsAmendment.allArtworksCreationError')} - ${
          e.response.data?.message || e.response.data
        }`,
        { type: 'error' },
      )
    }
  }

  return (
    <>
      <Title title={`${translate('pages.importsAmendment._title')} / ${importTitle}`} />
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="center"
        style={{ marginTop: '1rem' }}
      >
        <Grid item>
          <Button variant="contained" onClick={onAllArtworksCreate} disabled={isLoading}>
            <>
              {isLoading ? (
                <CircularProgress size="1rem" color="inherit" style={{ marginRight: '1rem' }} />
              ) : (
                <Theaters style={{ marginRight: '1rem' }} />
              )}
              {isLoading
                ? translate('pages.importsAmendment.creationInProgress')
                : translate('pages.importsAmendment.createAllArtworks')}
            </>
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

type ImportsAmendArtworkProps = {
  importId: string
  importTitle: string
  onChange: () => void
}

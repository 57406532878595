import React, { useEffect } from 'react'

import { Delete } from '@mui/icons-material'
import { Grid, Input } from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Button, useTranslate } from 'react-admin'
import { useImmer } from 'use-immer'

import { BeneficiaryHelper } from './../helpers/Beneficiary.helper'
import { Rate } from './../types/Rates'
import { LXField } from './LXField'
import { DatePickerSX } from './LXSelectDate'

export const LXCreatePeriodicRates = (props: LXCreatePeriodicRatesProps): JSX.Element => {
  const translate = useTranslate()
  const { onRatesUpdate, hasDefaultRates = true } = props
  const [rates, setRates] = useImmer(hasDefaultRates ? [BeneficiaryHelper.defaultRate()] : [])

  const addDefaultRate = () => {
    setRates(rateItems => [...rateItems, ...[BeneficiaryHelper.defaultRate()]])
  }

  const deleteRate = (id: string) => {
    setRates(rateItems => rateItems.filter(ri => ri.id !== id))
  }

  useEffect(() => {
    onRatesUpdate(rates)
  }, [onRatesUpdate, rates])

  return (
    <Grid container direction="row">
      <Grid container item direction="column" spacing={2} lg={8}>
        {rates.map((rateItem, index) => {
          const { id, rate, startDate, endDate } = rateItem

          return (
            <Grid container item direction="row" spacing={2} key={id}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <LXField label={`${translate('pages.beneficiaries.rate')} ${index + 1}`} labeled>
                  <Input
                    id="input-rate"
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    value={rate}
                    onChange={e => {
                      setRates(rateItems => {
                        const rItem = rateItems.find(r => r.id === id)
                        // @ts-ignore rItem is always defined
                        rItem.rate = parseFloat(e.target.value)
                      })
                    }}
                  />
                </LXField>
                <LXField label={translate('_generics.from')} size={4} labeled>
                  <MobileDatePicker
                    value={startDate}
                    onAccept={date => {
                      setRates(rateItems => {
                        const rItem = rateItems.find(r => r.id === id)
                        // @ts-ignore rItem is always defined
                        rItem.startDate = date
                      })
                    }}
                    views={['month', 'year']}
                    slotProps={{
                      toolbar: { hidden: true },
                    }}
                    sx={DatePickerSX}
                  />
                </LXField>
                <LXField label={translate('_generics.to')} size={4} labeled>
                  <MobileDatePicker
                    value={endDate}
                    onAccept={date => {
                      setRates(rateItems => {
                        const rItem = rateItems.find(r => r.id === id)
                        // @ts-ignore rItem is always defined
                        rItem.endDate = date
                      })
                    }}
                    views={['month', 'year']}
                    slotProps={{
                      toolbar: { hidden: true },
                    }}
                    sx={DatePickerSX}
                  />
                </LXField>
              </LocalizationProvider>
              <Grid container item lg={1} style={{ alignItems: 'center' }}>
                <Delete
                  color="error"
                  fontSize="small"
                  onClick={() => deleteRate(id)}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid item lg={4} style={{ textAlign: 'right', marginTop: '1rem' }}>
        <Button
          variant="outlined"
          onClick={() => {
            addDefaultRate()
          }}
          label={translate('pages.beneficiaries.addRate')}
        />
      </Grid>
    </Grid>
  )
}

type LXCreatePeriodicRatesProps = {
  onRatesUpdate: (rates: Rate[]) => void
  hasDefaultRates?: boolean
}

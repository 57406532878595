import dayjs, { ConfigType } from 'dayjs'

const DEFAULT_FORMAT = 'DD-MM-YYYY'
export const LONG_FORMAT = 'DD-MM-YYYY HH:mm:ss'

export const DateHelper = {
  format: (date: dayjs.Dayjs, format: string = DEFAULT_FORMAT): string => {
    return dayjs(date).format(format)
  },
  toDayjs: (date: ConfigType): dayjs.Dayjs => {
    return dayjs(date)
  },
  january2021Date: (): dayjs.Dayjs => dayjs('2021-12-01T23:00:00.000Z'),
  january2022Date: (): dayjs.Dayjs => dayjs('2022-12-01T23:00:00.000Z'),
  currentDate: (): dayjs.Dayjs => dayjs(),
  currentMonth: (): number => parseInt(dayjs().format('M')),
  currentYear: (): number => parseInt(dayjs().format('YYYY')),
  compare: (d1: dayjs.ConfigType, d2: dayjs.ConfigType, type: 'before' | 'after'): boolean => {
    if (type === 'before') {
      return dayjs(d1).isBefore(d2)
    }

    return dayjs(d1).isAfter(d2)
  },
}

import React, { useCallback, useEffect, useState } from 'react'

import CsvIcon from '@mui/icons-material/ListAlt'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import dayjs from 'dayjs'
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin'
import { useImmer } from 'use-immer'

import { BeneficiaryHelper } from '../../helpers/Beneficiary.helper'
import { STATEMENT_PERIODS, StatementsHelper } from '../../helpers/Statements.helper'
import { LXDropdown } from './../../components/LXDropdown'
import genericStyles from './../../generics.module.scss'
import { Statement } from './../../types/Statement'
import styles from './StatementDownloadsModal.module.scss'

export interface StatementDownloadsModalProps {
  beneficiaryId: string
  beneficiaryName: string
  isOpen?: boolean
  onClose: (created: boolean) => void
}

type StatementDates = Pick<Statement, 'month' | 'year'>

export const StatementDownloadsModal: React.FC<StatementDownloadsModalProps> = ({
  beneficiaryId,
  beneficiaryName,
  isOpen = false,
  onClose,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [error, setError] = useState<string>()
  const [firstStatement, setFirstStatement] = useState<StatementDates>()
  const [lastStatement, setLastStatement] = useState<StatementDates>()
  const [isDropdownProcessing, setIsDropdownProcessing] = useImmer<{
    [id: string]: boolean
  }>({})

  // Get first and last statements
  useEffect(() => {
    const defaultOptions = {
      pagination: { page: 1, perPage: 1 },
      filter: { beneficiaryId },
    }

    // Get first and last statement
    Promise.all([
      dataProvider.getList<Statement>('statements', {
        ...defaultOptions,
        ...{ sort: { field: 'period', order: 'ASC' } },
      }),
      dataProvider.getList<Statement>('statements', {
        ...defaultOptions,
        ...{ sort: { field: 'period', order: 'DESC' } },
      }),
    ]).then(res => {
      const first = res[0]?.data?.[0] || {}
      const last = res[1]?.data?.[0] || {}

      setFirstStatement({
        month: first.month,
        year: first.year,
      })

      setLastStatement({
        month: last.month,
        year: last.year,
      })
    })
  }, [dataProvider, beneficiaryId])

  const closeModal = useCallback(
    (isCreated: boolean) => {
      onClose(isCreated)
      setError(undefined)
    },
    [onClose, setError],
  )

  const handleCsvDownload = useCallback(
    async (year: number, period: STATEMENT_PERIODS, dropdownId: string, isBasic = false) => {
      const { numberOfMonths, startMonth } = StatementsHelper.periods[period]
      setIsDropdownProcessing(data => {
        data[dropdownId] = true
      })
      try {
        await BeneficiaryHelper.downloadSummedStatementCsv({
          beneficiaryId,
          beneficiaryName,
          isBasic,
          startMonth,
          startYear: year,
          numberOfMonths,
          period,
        })
      } catch (e: any) {
        notify(translate('_generics.errorOccured'), { type: 'error' })
        console.error('Error - create statement', e)
        setError(
          `${translate('_generics.errorOccured')} - ${
            e.response?.data.content?.fieldError ?? e.response?.data.content
          }`,
        )
      } finally {
        setIsDropdownProcessing(data => {
          data[dropdownId] = false
        })
      }
    },
    [beneficiaryId, beneficiaryName, setIsDropdownProcessing, translate, notify],
  )

  const handleMonthByMonthCsvDownload = useCallback(
    async (year: number, period: STATEMENT_PERIODS, dropdownId: string, isBasic = false) => {
      const { numberOfMonths, startMonth } = StatementsHelper.periods[period]

      setIsDropdownProcessing(data => {
        data[dropdownId] = true
      })
      try {
        await BeneficiaryHelper.downloadMonthlyStatementCsv({
          beneficiaryId,
          beneficiaryName,
          isBasic,
          startMonth,
          startYear: year,
          numberOfMonths,
          period,
        })
      } catch (e: any) {
        notify(translate('_generics.errorOccured'), { type: 'error' })
        console.error('Error - create statement', e)
        setError(
          `${translate('_generics.errorOccured')} - ${
            e.response?.data.content?.fieldError ?? e.response?.data.content
          }`,
        )
      } finally {
        setIsDropdownProcessing(data => {
          data[dropdownId] = false
        })
      }
    },
    [beneficiaryId, beneficiaryName, setIsDropdownProcessing, translate, notify],
  )

  const handlePDFDownload = useCallback(
    async (year: number, period: STATEMENT_PERIODS, dropdownId: string) => {
      const { numberOfMonths, startMonth } = StatementsHelper.periods[period]
      setIsDropdownProcessing(data => {
        data[dropdownId] = true
      })
      try {
        await BeneficiaryHelper.downloadSummedStatementPdf({
          beneficiaryId,
          beneficiaryName,
          startMonth,
          startYear: year,
          numberOfMonths,
          period,
        })
      } catch (e: any) {
        try {
          //
          const msg = JSON.parse(await e?.response?.data?.text())
          notify(msg?.message ?? '', { type: 'error' })
        } catch (_) {
          notify(`${translate('_generics.errorOccured')}\n${e?.response?.data?.message ?? ''}`, {
            type: 'error',
          })
        }
        setError(
          `${translate('_generics.errorOccured')} - ${
            e.response.data.content?.fieldError ?? e.response.data.content
          }`,
        )
      } finally {
        setIsDropdownProcessing(data => {
          data[dropdownId] = false
        })
      }
    },
    [beneficiaryId, beneficiaryName, setIsDropdownProcessing, translate, notify],
  )

  const getSingleRow = (period: STATEMENT_PERIODS, year: number) => {
    const { periodText } = StatementsHelper.periods[period]
    const dropdownCSVId = `${year}-${period}-csv`
    const dropdownMonthlyCSVId = `${year}-${period}-monthly-csv`
    const dropdownPDFId = `${year}-${period}-pdf`

    return (
      <TableRow className={period === STATEMENT_PERIODS.YEAR ? styles.highlightedRow : ''}>
        <TableCell>{year}</TableCell>
        <TableCell>
          {translate(periodText)}
          {period !== STATEMENT_PERIODS.YEAR ? ` ${period}` : ''}
        </TableCell>
        <TableCell>
          <LXDropdown
            id="csv"
            disabled={isDropdownProcessing[dropdownCSVId]}
            isProcessing={isDropdownProcessing[dropdownCSVId]}
            icon={<CsvIcon />}
            label={translate('pages.statements.csv')}
          >
            <MenuItem onClick={() => handleCsvDownload(year, period, dropdownCSVId)}>
              {translate('_generics.standard')}
            </MenuItem>
            <MenuItem onClick={() => handleCsvDownload(year, period, dropdownCSVId, true)}>
              {translate('_generics.basic')}
            </MenuItem>
          </LXDropdown>
          <br />
          <LXDropdown
            id="monthly-csv"
            disabled={isDropdownProcessing[dropdownCSVId]}
            isProcessing={isDropdownProcessing[dropdownMonthlyCSVId]}
            icon={<CsvIcon />}
            label={`${translate('pages.statements.csv')} (${translate(
              'pages.statements.monthByMonth',
            )})`}
          >
            <MenuItem
              onClick={() => handleMonthByMonthCsvDownload(year, period, dropdownMonthlyCSVId)}
            >
              {translate('_generics.standard')}
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleMonthByMonthCsvDownload(year, period, dropdownMonthlyCSVId, true)
              }
            >
              {translate('_generics.basic')}
            </MenuItem>
          </LXDropdown>
          <br />
          <Button
            label={translate('pages.statements.pdf')}
            onClick={() => handlePDFDownload(year, period, dropdownPDFId)}
            disabled={isDropdownProcessing[dropdownPDFId]}
          >
            {isDropdownProcessing[dropdownPDFId] ? (
              <CircularProgress size="1rem" />
            ) : (
              <PictureAsPdfIcon />
            )}
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  const generateRows = () => {
    const { YEAR, S1, S2, Q1, Q2, Q3, Q4 } = STATEMENT_PERIODS
    const rows: JSX.Element[] = []

    if (firstStatement && lastStatement) {
      const years = []
      const currentYear = dayjs().year()
      for (let i = firstStatement.year; i <= currentYear; i++) {
        years.push(i)
      }

      // biome-ignore lint/complexity/noForEach: <explanation>
      years.reverse().forEach((year, index) => {
        // YEAR
        rows.push(getSingleRow(YEAR, year))

        // If it is first or last year, we want to check the month to display the right semesters and quarters
        if (index === years.length - 1) {
          switch (true) {
            case firstStatement.month <= 3:
              rows.push(
                getSingleRow(S1, year),
                getSingleRow(S2, year),
                getSingleRow(Q1, year),
                getSingleRow(Q2, year),
                getSingleRow(Q3, year),
                getSingleRow(Q4, year),
              )
              break
            case firstStatement.month > 3 && firstStatement.month <= 6:
              rows.push(
                getSingleRow(S1, year),
                getSingleRow(S2, year),
                getSingleRow(Q2, year),
                getSingleRow(Q3, year),
                getSingleRow(Q4, year),
              )
              break
            case firstStatement.month > 6 && firstStatement.month <= 9:
              rows.push(getSingleRow(S2, year), getSingleRow(Q3, year), getSingleRow(Q4, year))
              break
            case firstStatement.month > 9 && firstStatement.month <= 12:
              rows.push(getSingleRow(S2, year), getSingleRow(Q4, year))
              break
            default:
              break
          }
        } else if (index === 0) {
          switch (true) {
            case lastStatement.month <= 3:
              rows.push(getSingleRow(S1, year), getSingleRow(Q1, year))
              break
            case lastStatement.month > 3 && lastStatement.month <= 6:
              rows.push(getSingleRow(S1, year), getSingleRow(Q1, year), getSingleRow(Q2, year))
              break
            case lastStatement.month > 6 && lastStatement.month <= 9:
              rows.push(
                getSingleRow(S1, year),
                getSingleRow(S2, year),
                getSingleRow(Q1, year),
                getSingleRow(Q2, year),
                getSingleRow(Q3, year),
              )
              break
            case lastStatement.month > 9 && lastStatement.month <= 12:
              rows.push(
                getSingleRow(S1, year),
                getSingleRow(S2, year),
                getSingleRow(Q1, year),
                getSingleRow(Q2, year),
                getSingleRow(Q3, year),
                getSingleRow(Q4, year),
              )
              break
            default:
              break
          }
        } else {
          // SEMESTERS
          rows.push(getSingleRow(S1, year), getSingleRow(S2, year))

          // QUARTERS
          rows.push(
            getSingleRow(Q1, year),
            getSingleRow(Q2, year),
            getSingleRow(Q3, year),
            getSingleRow(Q4, year),
          )
        }
      })
    } else {
      rows.push(
        <TableRow>
          <TableCell>{translate('pages.statements.noStatementCreated')}</TableCell>
        </TableRow>,
      )
    }

    return rows
  }

  return (
    <Dialog
      aria-labelledby="statements-downloads-modal"
      open={isOpen}
      onClose={() => closeModal(false)}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle id="statements-downloads-modal">
        {translate('pages.statements.periodicStatementDownloads')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={4}>
          <Table>
            <TableHead>
              <TableRow className={styles.highlightedRow}>
                <TableCell>
                  <b>{translate('_generics.year')}</b>
                </TableCell>
                <TableCell>
                  <b>{translate('_generics.period')}</b>
                </TableCell>
                <TableCell>
                  <b>{translate('_generics.actions')}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            {isOpen && generateRows()}
          </Table>
        </Grid>
        {error && (
          <p className={genericStyles.error}>
            <i>{translate('_generics.errorOccured')}</i>:&nbsp;
            <strong>{error}</strong>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModal(false)} label={translate('_generics.close')} />
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'

import { Image } from '@mui/icons-material'
import { Grid, GridSize } from '@mui/material'
import { ImageFieldProps, RaRecord, useRecordContext } from 'react-admin'

import styles from './LXImage.module.scss'

export const LXImage = (props: LXImageProps & ImageFieldProps): JSX.Element => {
  const { source, gridSize } = props
  const record = useRecordContext() || props.record
  const imageURL = record?.[source as string]

  return (
    <Grid item lg={gridSize || 2} className={styles.image}>
      {imageURL ? (
        <img src={imageURL} srcSet={imageURL} alt="LX logo" loading="lazy" />
      ) : (
        <Image className={styles.imageSVG} />
      )}
    </Grid>
  )
}

type LXImageProps = {
  source: string
  gridSize?: GridSize
  record?: RaRecord
}

import {
  Assignment,
  BarChart,
  Book,
  Group,
  PrecisionManufacturing,
  Receipt,
  Theaters,
  YouTube,
} from '@mui/icons-material'

export const MenuHelper = {
  icons: {
    beneficiaries: Group,
    youtubeChannels: YouTube,
    products: Book,
    artworks: Theaters,
    youtubeRawImports: Assignment,
    statements: Receipt,
    analytics: BarChart,
    automatedTreatments: PrecisionManufacturing,
  },
}

import React from 'react'

import { Info } from '@mui/icons-material'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Grid, Link, Tooltip } from '@mui/material'
import {
  BooleanField,
  Button,
  FunctionField,
  ImageField,
  Labeled,
  RaRecord,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
} from 'react-admin'

import { LXField } from '../../components/LXField'
import { LXNumberField } from '../../components/LXNumberField'
import { L10nHelper } from '../../helpers/L10n.helper'
import styles from './YoutubeChannel.module.scss'

export const YoutubechannelShow: React.FC = props => {
  const translate = useTranslate()
  const locales = L10nHelper.getYoutubeLocales()

  return (
    <Show>
      <SimpleShowLayout>
        <Grid container direction="row" spacing={2}>
          <Grid item lg={2}>
            <ImageField source="thumbnailUrl" label={''} />
            <FunctionField
              addLabel={false}
              render={(subRecord: RaRecord | undefined) => (
                <Link href={`https://www.youtube.com/channel/${subRecord?.id}`} target="_blank">
                  <Button label={translate('pages.youtubeChannels.linkToYouTube')}>
                    <YouTubeIcon />
                  </Button>
                </Link>
              )}
            />
          </Grid>
          <Grid item direction="column" lg={10} spacing={2}>
            <Grid container item direction="row" lg={10} spacing={2}>
              <LXField
                source="name"
                label={translate('_generics.name')}
                elementType={TextField}
                labeled
              />
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.subscribersCount')}>
                  <LXNumberField source="subscribersCount" format="large" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.videosCount')}>
                  <LXNumberField source="videosCount" format="large" />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.viewsCount')}>
                  <LXNumberField source="viewsCount" format="large" />
                </Labeled>
              </Grid>
              <LXField
                source="cms"
                label={translate('pages.youtubeChannels.cms')}
                elementType={TextField}
                labeled
              />
              <LXField
                source="id"
                label={translate('_generics.youtubeChannelId')}
                elementType={TextField}
                size={6}
                labeled
              />
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <LXField
                source="isOwnedByLogicomix"
                label={translate('pages.youtubeChannels.isOwnedByLogicomix')}
                elementType={BooleanField}
                size={12}
                labeled
              />
              <LXField
                source="name"
                label={translate('pages.youtubeChannels.defaultBeneficiary')}
                elementType={TextField}
                size={6}
                wrappingFunction={el => (
                  <ReferenceField
                    source="defaultBeneficiaryId"
                    reference="beneficiaries"
                    link="show"
                  >
                    {el}
                  </ReferenceField>
                )}
                labeled
              />
              <LXField
                source="name"
                label={translate('pages.youtubeChannels.defaultProduct')}
                elementType={TextField}
                size={6}
                wrappingFunction={el => (
                  <ReferenceField source="defaultProductId" reference="products" link="show">
                    {el}
                  </ReferenceField>
                )}
                labeled
              />
              <LXField
                source="isAutoMonetizationEnabled"
                label={translate('pages.youtubeChannels.isAutoMonetizationEnabled')}
                elementType={BooleanField}
                size={6}
                labeled
                wrappingFunction={el => (
                  <Grid container item>
                    <Grid item lg={10}>
                      {el}
                    </Grid>
                    <Grid item lg={2} style={{ position: 'relative' }}>
                      <Tooltip
                        title={
                          <span
                            dangerouslySetInnerHTML={{
                              __html: translate('pages.youtubeChannels.autoAdsPatternTooltip'),
                            }}
                          />
                        }
                        placement="right"
                      >
                        <Info className={styles.infoShow} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              />
              <LXField
                source="autoMonetizationPolicyId"
                label={translate('pages.youtubeChannels.autoMonetizationPolicyId')}
                elementType={TextField}
                size={6}
                labeled
              />
              {
                // TODO: Enable when available on API side
                // <LXField source="isAutoInsertSubscribeEndScreenEnabled" label={translate('pages.youtubeChannels.isAutoInsertSubscribeEndScreenEnabled')} elementType={BooleanField} size={6} labeled />
              }
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <LXField
                source="isAutoTagUpdateEnabled"
                label={translate('pages.youtubeChannels.isAutoTagUpdateEnabled')}
                elementType={BooleanField}
                size={6}
                labeled
              />
              <YoutubeChannelShowTags />
            </Grid>
            <hr style={{ width: '100%' }} />
            <Grid container item direction="row" lg={10} spacing={2}>
              <LXField
                source="isAutoSubtitlesTranslationEnabled"
                label={translate('pages.youtubeChannels.isAutoSubtitlesTranslationEnabled')}
                elementType={BooleanField}
                size={6}
                labeled
              />
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.subtitleMainLanguage')}>
                  <FunctionField
                    addLabel={false}
                    render={(subRecord: RaRecord | undefined) =>
                      locales.find(l => l.id === subRecord?.subtitleMainLanguage)?.name ??
                      subRecord?.subtitleMainLanguage
                    }
                  />
                </Labeled>
              </Grid>
              <Grid item lg={3}>
                <Labeled label={translate('pages.youtubeChannels.additionalSubtitlesLanguages')}>
                  <FunctionField
                    addLabel={false}
                    render={(subRecord: RaRecord | undefined) =>
                      subRecord?.subtitlesLanguages
                        .map((sl: string) => locales.find(l => l.id === sl)?.name ?? sl)
                        .join(' - ')
                    }
                  />
                </Labeled>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  )
}

const YoutubeChannelShowTags = () => {
  const record = useRecordContext()
  const translate = useTranslate()

  return (
    record?.isAutoTagUpdateEnabled && (
      <>
        <LXField
          source="autoTags"
          label={translate('pages.youtubeChannels.autoTags')}
          elementType={TextField}
          size={6}
          labeled
        />
        <LXField
          source="autoDefaultVideoLanguage"
          label={translate('pages.youtubeChannels.autoDefaultVideoLanguage')}
          elementType={TextField}
          labeled
        />
      </>
    )
  )
}

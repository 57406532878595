import React, { ReactNode, useState } from 'react'

import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
  createTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useSidebarState } from 'react-admin'

import { LOCAL_STORAGE_KEYS, LocalStorage } from './../helpers/LocalStorage.helper'

const theme = createTheme()
const useStyles = makeStyles(() => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(4),
    },
  },
  sidebarIsClosed: {
    '& a': {
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      paddingLeft: theme.spacing(2),
    },
  },
}))

export const SubMenu = (props: SubMenuProps): JSX.Element => {
  const { name, icon, children, dense } = props
  const classes = useStyles()
  const [isSidebarOpen] = useSidebarState()

  const [isOpen, setIsOpen] = useState(
    Boolean(LocalStorage.getItem(LOCAL_STORAGE_KEYS.MENU_STATE)) || false,
  )

  const header = (
    <MenuItem
      dense={dense}
      onClick={() => {
        setIsOpen(!isOpen)
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.MENU_STATE, +isOpen)
      }}
    >
      <ListItemIcon className={classes.icon}>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  )

  return (
    <>
      {isSidebarOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={isSidebarOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

type SubMenuProps = {
  name: string
  dense: boolean
  icon: JSX.Element
  children: ReactNode
}

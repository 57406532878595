import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import { Button, LinearProgress, useListContext, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { FileHelper } from '../../helpers/File.helper'
import { StatementsHelper } from '../../helpers/Statements.helper'
import { Statement, StatementRequestDTO } from '../../types/Statement'
import genericStyles from './../../generics.module.scss'

export interface StatementsListDownloadModalProps {
  isOpen?: boolean
  onClose: () => void
}

export const StatementsListDownloadModal: React.FC<StatementsListDownloadModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const translate = useTranslate()
  const [error, setError] = useState<string>()
  const [isRunning, setIsRunning] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentJobId, setCurrentJobId] = useState<number>()
  const { data } = useListContext<Statement>()
  const [selectedFormats, setSelectedFormats] = useState({
    pdf: false,
    csv_standard: false,
    csv_basic: false,
    csv_artworkChannel: false,
  })

  const downloadListStatements = useCallback(async () => {
    try {
      setIsRunning(true)
      const requests: StatementRequestDTO[] = []
      // biome-ignore lint/complexity/noForEach: <explanation>
      data.forEach(item => {
        // biome-ignore lint/complexity/noForEach: <explanation>
        StatementsHelper.STATEMENT_FORMATS.filter(t => {
          if (t === 'pdf') {
            return selectedFormats.pdf
          }
          if (t === 'csv') {
            return selectedFormats.csv_standard
          }
          if (t === 'csv-basic') {
            return selectedFormats.csv_basic
          }
          if (t === 'artwork-channel-csv') {
            return selectedFormats.csv_artworkChannel
          }

          return false
        }).forEach(t => {
          requests.push({
            statementId: item.id,
            format: t,
          })
        })
      })

      const downloadQuery = await API.post('/api/statements/zip.zip', {
        requests,
      })
      setCurrentJobId(downloadQuery.data.job.id)
    } catch (e) {
      console.error(e)
    }
  }, [data, selectedFormats])

  useEffect(() => {
    if (isOpen) {
      setSelectedFormats({
        pdf: false,
        csv_standard: false,
        csv_basic: false,
        csv_artworkChannel: false,
      })
      setCurrentJobId(undefined)
      setIsRunning(false)
      setIsFinished(false)
      setProgress(0)
    }
  }, [isOpen])

  const timerRef = React.useRef<any>()
  useEffect(() => {
    if (currentJobId && !isFinished) {
      const schedulUpdateDownloadStatus = async (jobId: number) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(async () => {
          try {
            const zipStatus = await API.get<any>(`/api/statements/zipStatus/${jobId}`)
            setProgress(zipStatus.data.job.progress)
            if (zipStatus.data.job.progress >= 100) {
              setIsFinished(true)
              const url = zipStatus.data.job.data.signedUrl
              FileHelper.downloadUrl(url)
            } else if (zipStatus.data.job.failedReason) {
              setIsFinished(true)
              setError(zipStatus.data.job.failedReason)
            } else {
              schedulUpdateDownloadStatus(jobId)
            }
          } catch (e) {
            schedulUpdateDownloadStatus(jobId)
          }
        }, 400)
      }
      schedulUpdateDownloadStatus(currentJobId)
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [currentJobId, isFinished])

  const closeModal = useCallback(() => {
    onClose()
    setError(undefined)
  }, [onClose, setError])

  return (
    <Dialog aria-labelledby="statements-list-modal" open={isOpen} onClose={() => closeModal()}>
      <DialogTitle id="statements-list-modal">
        {translate('pages.statements.downloadListStatements')}
      </DialogTitle>
      <DialogContent dividers>
        {isRunning ? (
          <>
            <Typography variant="body1">
              {translate('pages.statements.downloadingStatements_description')}
            </Typography>
            <Grid alignItems="center">
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body1" color="textSecondary">
                  {`${Math.round(progress)}% `}
                  {progress === 0 && translate('pages.statements.downloadingStatements_queued')}
                  {progress > 0 &&
                    progress < 100 &&
                    translate('pages.statements.downloadingStatements_generating')}
                  {progress >= 100 && translate('pages.statements.downloadingStatements_success')}
                </Typography>
              </Box>
            </Grid>
            {error && (
              <p className={genericStyles.error}>
                <i>{translate('_generics.errorOccured')}</i>:&nbsp;
                <strong>{error}</strong>
              </p>
            )}
          </>
        ) : (
          <>
            <Typography variant="body1">
              {translate('pages.statements.downloadingStatements_intro', {
                count: data ? Object.keys(data).length : 0,
              })}
            </Typography>
            <Typography variant="body1">
              <FormGroup>
                {Object.entries(selectedFormats).map(([key, value]) => (
                  <FormControlLabel
                    key={`checkbox-${key}`}
                    control={
                      <Checkbox
                        checked={value}
                        size="small"
                        onChange={e =>
                          setSelectedFormats(draft => ({
                            ...draft,
                            [key]: e.target.checked,
                          }))
                        }
                      />
                    }
                    label={
                      key === 'pdf'
                        ? translate('pages.statements.pdf')
                        : `${translate('pages.statements.csv')} ${translate(
                            `_generics.${key.replace('csv_', '')}`,
                          )}`
                    }
                  />
                ))}
              </FormGroup>
            </Typography>
            <hr />
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadListStatements()}
              label={translate('pages.statements.downloadingStatements_generate')}
              disabled={isRunning && !isFinished}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => closeModal()}
          label={translate('_generics.close')}
          disabled={isRunning && !isFinished}
        />
      </DialogActions>
    </Dialog>
  )
}

import React, { useMemo, useRef, useState } from 'react'

import { NoteAdd, Refresh, Star } from '@mui/icons-material'
import SearchIcon from '@mui/icons-material/Search'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ColDef, ICellRendererParams, IGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import pLimit from 'promise-limit'
import {
  Button,
  Link as RALink,
  RaRecord,
  SortPayload,
  useGetList,
  useNotify,
  useTranslate,
} from 'react-admin'

import { AutoAssignProductsModal } from '../../components/AutoAssignProductsModal/AutoAssignProductsModal'
import { LXPageSize } from '../../components/LXPageSize/LXPageSize'
import { API } from '../../helpers/Api.helper'
import { DEFAULT_PAGE_SIZE } from '../../helpers/constants'
import { dataProvider } from '../../providers/dataProvider'
import { Product } from '../../types/Product'
import { YoutubeRawImportRow } from '../../types/YoutubeRawImportRow'
import { useAgGridBulkSelect } from './AgGrid/aggridBulkSelect'
import { AgGridLoader, useAgGridLoader } from './AgGrid/aggridLoader'
import styles from './ImportAmendmentsStep.module.scss'
import { ImportCreateProductModal } from './ImportCreateProductModal'

export type ImportsAmendProductProps = {
  youtubeRawImportId: string
  updateUntreatedResults?: () => void
}

const ProductAutoComplete = React.memo(
  ({
    id,
    beneficiaryId,
    value: internalValue,
    onChange,
  }: {
    id?: string
    beneficiaryId?: string
    value?: any
    onChange: (event: any, value: any) => void
  }) => {
    const translate = useTranslate()

    const [inputValue, setInputValue] = React.useState('')
    const [isFocused, setIsFocused] = React.useState(false)

    const onInputChange = React.useCallback((_: unknown, value: string) => {
      setInputValue(value)
    }, [])

    const products = useGetList(
      'products',
      {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
        filter: {
          q:
            inputValue.trim() !== (internalValue?.name ?? '').trim()
              ? inputValue.trim()
              : undefined,
          beneficiaryId,
        },
      },
      { enabled: isFocused },
    )

    return useMemo(
      () => (
        <Autocomplete
          id={`product-edit-${id}`}
          options={products?.data ?? []}
          value={internalValue}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          accessKey="id"
          getOptionLabel={option => option?.name}
          renderInput={_params => <TextField {..._params} key={_params.id} variant="standard" />}
          noOptionsText={translate('_generics.noResult')}
          onChange={onChange}
          onInputChange={onInputChange}
          loading={products.isLoading}
          filterOptions={x => x}
          selectOnFocus
          openOnFocus
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      ),
      [id, products.data, internalValue, translate, onChange, onInputChange, products.isLoading],
    )
  },
)

const UpdateProductCellRenderer = React.memo((params: any & { allProducts: Product[] }) => {
  const notify = useNotify()

  const beneficiaryId = params.data?.beneficiaryId
  const productName = params.data?.product?.name ?? params.data?.['product.name']
  const productId = params.data?.productId

  const [internalValue, setInternalValue] = React.useState(() =>
    productId ? { id: productId, name: productName } : null,
  )

  React.useEffect(() => {
    setInternalValue(productId ? { id: productId, name: productName } : null)
  }, [productId, productName])

  const onChange = React.useCallback(
    async (_: unknown, value: any) => {
      setInternalValue(value)
      try {
        await API.put(`api/youtubeRawImportRows/${params.data.id}`, {
          productId: value?.id,
        })
        params.node?.setData({
          ...params.data,
          productId: value?.id,
          product: value,
          'product.name': value?.name,
        })
        params.onDidUpdate?.()
      } catch (e) {
        setInternalValue(productId ? { id: productId, name: productName } : null)
        notify('pages.importsAmendment.toast_editProductError', {
          type: 'error',
        })
      }
    },
    [productId, productName, params.data?.id, params.node],
  )

  return (
    <ProductAutoComplete
      id={params.data?.id}
      beneficiaryId={beneficiaryId}
      onChange={onChange}
      value={internalValue}
    />
  )
})

export const ImportsAmendProduct = ({
  youtubeRawImportId,
  updateUntreatedResults,
}: ImportsAmendProductProps): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAutoAssignModalOpen, setIsAutoAssignModalOpen] = useState(false)

  const gridRef = useRef<AgGridReact>(null)
  const updateUntreatedResultsRef = React.useRef(updateUntreatedResults)
  updateUntreatedResultsRef.current = updateUntreatedResults

  const youtubeChannels = useGetList('youtubeChannels', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })
  const beneficiaries = useGetList('beneficiaries', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const [q, setQ] = React.useState('')
  const [selectedYoutubeChannel, setSelectedYoutubeChannel] = React.useState<RaRecord | null>(null)
  const [selectedBeneficiary, setSelectedBeneficiary] = React.useState<RaRecord | null>(null)
  const [showOnlyIncomplete, setShowOnlyIncomplete] = React.useState(false)
  const [bulkProduct, setBulkProduct] = React.useState<RaRecord | null>(null)

  const firstSelectionBeneficiaryIdRef = React.useRef<string | null>(null)

  const allowNodeToBeSelected = React.useCallback((node: any) => {
    return (
      !firstSelectionBeneficiaryIdRef.current ||
      node.data?.beneficiaryId === firstSelectionBeneficiaryIdRef.current
    )
  }, [])

  const {
    currentPageHasEverythingSelected,
    onCheckAllChanged,
    onSelectionChanged,
    totalSelectionCount,
    selection,
    onPaginationChanged,
  } = useAgGridBulkSelect(gridRef, { allowNodeToBeSelected })

  React.useEffect(() => {
    onSelectionChanged()
  }, [q, selectedYoutubeChannel, selectedBeneficiary, showOnlyIncomplete, onSelectionChanged])

  const firstSelectionBeneficiaryId = React.useMemo(() => {
    return selection[0]?.data?.beneficiaryId
  }, [selection])
  firstSelectionBeneficiaryIdRef.current = firstSelectionBeneficiaryId

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'assetId',
        headerName: 'Asset ID',
        resizable: true,
        checkboxSelection: params => {
          return (
            !firstSelectionBeneficiaryId ||
            params.data?.beneficiaryId === firstSelectionBeneficiaryId
          )
        },
        headerComponent: () => {
          return (
            <>
              <Checkbox checked={currentPageHasEverythingSelected} onChange={onCheckAllChanged} />
              <div>Asset ID</div>
            </>
          )
        },
      },
      {
        field: 'assetChannelId',
        headerName: translate('_generics.youtubeChannel'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        width: 200,
        valueGetter: params =>
          params.data?.['internalYoutubeChannel.name'] ?? params.data?.internalYoutubeChannel?.name,
        cellRenderer: (params: ICellRendererParams<YoutubeRawImportRow>) => {
          const channelId =
            params.data?.['internalYoutubeChannel.id'] ?? params.data?.internalYoutubeChannel?.id
          if (!channelId) {
            return null
          }

          return (
            <RALink to={`/youtubeChannels/${channelId}`} target="_blank" rel="noreferrer">
              {params.value}
            </RALink>
          )
        },
      },
      {
        field: 'assetTitleEdited',
        headerName: translate('pages.importsAmendment.assetTitle'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: 'beneficiaryId',
        headerName: translate('_generics.beneficiary'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        width: 250,
        valueGetter: params => params.data?.['beneficiary.name'] ?? params.data?.beneficiary?.name,
        cellRenderer: (params: ICellRendererParams<YoutubeRawImportRow>) => {
          const beneficiaryId = params.data?.['beneficiary.id'] ?? params.data?.beneficiary?.id

          return (
            <RALink to={`/beneficiaries/${beneficiaryId}`} target="_blank" rel="noreferrer">
              {params.value}
            </RALink>
          )
        },
      },
      {
        field: 'product',
        headerName: translate('_generics.product'),
        suppressMenu: true,
        resizable: true,
        width: 250,
        equals: (a, b) => a?.productId === b?.productId,
        cellStyle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          paddingTop: 6,
          paddingBottom: 6,
        },
        cellRenderer: (params: ICellRendererParams<YoutubeRawImportRow>) => {
          return (
            <UpdateProductCellRenderer
              {...params}
              onDidUpdate={updateUntreatedResultsRef.current}
            />
          )
        },
      },
    ],
    [translate, currentPageHasEverythingSelected, onCheckAllChanged, firstSelectionBeneficiaryId],
  )

  const { startLoading, stopLoading } = useAgGridLoader(gridRef)
  const getRows = React.useCallback(
    async (params: IGetRowsParams) => {
      const perPage = gridRef.current?.api.paginationGetPageSize() ?? 100
      startLoading(`${params.startRow}`)
      const data = await dataProvider.getList('youtubeRawImportRows', {
        pagination: { page: params.startRow / perPage + 1, perPage },
        sort:
          params.sortModel.length > 0
            ? ({
                field: params.sortModel[0].colId,
                order: params.sortModel[0].sort.toUpperCase(),
              } as SortPayload)
            : ({ field: 'assetId', order: 'ASC' } as SortPayload),
        filter: {
          youtubeRawImportId,
          q: q.trim().length > 0 ? q : undefined,
          ...(selectedYoutubeChannel ? { assetChannelId: selectedYoutubeChannel.id } : {}),
          ...(selectedBeneficiary ? { beneficiaryId: selectedBeneficiary.id } : {}),
          ...(showOnlyIncomplete ? { productId: true } : {}),
        },
      })
      params.successCallback(data.data, data.total)
      stopLoading(`${params.startRow}`)
    },
    [
      q,
      selectedBeneficiary,
      selectedYoutubeChannel,
      showOnlyIncomplete,
      youtubeRawImportId,
      startLoading,
      stopLoading,
    ],
  )

  const datasource = React.useMemo(() => {
    return { getRows }
  }, [getRows])

  const onAutoAssignProductsModalChange = React.useCallback(() => {
    gridRef.current?.api?.refreshInfiniteCache()
  }, [])

  const onBulkUpdate = React.useCallback(() => {
    const limit = pLimit(10)
    const productId = bulkProduct?.id
    if (!productId) {
      return
    }
    Promise.all(
      selection.map(node =>
        limit(async () => {
          await API.put(`api/youtubeRawImportRows/${node.data.id}`, {
            productId,
          })
          node?.setData({
            ...node.data,
            productId,
            product: bulkProduct,
            'product.name': bulkProduct?.name,
          })
          node.onDidUpdate?.()
        }),
      ),
    )
      .then(() => {
        updateUntreatedResultsRef.current?.()
        gridRef.current?.api.deselectAll()
        notify('pages.importsAmendment.toast_editBulkProductSuccess', {
          type: 'success',
        })
      })
      .catch(() => {
        notify('pages.importsAmendment.toast_editProductError', {
          type: 'error',
        })
      })
  }, [bulkProduct, notify, selection])

  return (
    <>
      <Grid container direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            setIsAutoAssignModalOpen(true)
          }}
          label={translate('pages.importsAmendment.autoAssignProductsModal.title')}
          className={styles.button}
        >
          <Star />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsModalOpen(true)
          }}
          label={translate('pages.products.addProduct')}
          className={styles.button}
        >
          <NoteAdd />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            gridRef.current?.api?.refreshInfiniteCache()
          }}
          label={translate('_generics.refresh')}
          className={styles.button}
        >
          <Refresh />
        </Button>
      </Grid>
      <Grid
        item
        container
        lg={12}
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={2}
        style={{
          paddingLeft: 12,
          paddingTop: 12,
          paddingBottom: 12,
          marginTop: 12,
        }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-start"
          lg={7}
          spacing={2}
        >
          <Grid item lg={4}>
            <TextField
              hiddenLabel
              label=""
              fullWidth
              value={q}
              onChange={e => setQ(e.target.value)}
              placeholder={translate('ra.action.search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              id="filter-yt-channels"
              options={youtubeChannels?.data ?? []}
              value={selectedYoutubeChannel}
              accessKey="id"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  key={params.id}
                  placeholder={translate('_generics.youtubeChannel')}
                  variant="standard"
                />
              )}
              noOptionsText={translate('_generics.noResult')}
              onChange={(_, value) => setSelectedYoutubeChannel(value)}
            />
          </Grid>
          <Grid item lg={4}>
            <Autocomplete
              id="filter-beneficiaries"
              options={beneficiaries?.data ?? []}
              value={selectedBeneficiary}
              accessKey="id"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  key={params.id}
                  placeholder={translate('_generics.beneficiary')}
                  variant="standard"
                />
              )}
              noOptionsText={translate('_generics.noResult')}
              onChange={(_, value) => setSelectedBeneficiary(value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={showOnlyIncomplete}
                onChange={(e, checked) => setShowOnlyIncomplete(checked)}
              />
            }
            label={translate('pages.importsAmendment.showOnlyMissing')}
          />
        </Grid>
      </Grid>
      {totalSelectionCount > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          lg={12}
          style={{ backgroundColor: '#e9ecf7', padding: '12px' }}
        >
          <Grid item lg={4} style={{ alignSelf: 'center' }}>
            <Typography variant="body2">
              {totalSelectionCount}{' '}
              {translate('_generics.selected', {
                smart_count: totalSelectionCount,
              })}
            </Typography>
          </Grid>
          <Grid item container lg={8} spacing={2} justifyContent="flex-end">
            <Grid item lg={6}>
              <ProductAutoComplete
                id={'bulk'}
                beneficiaryId={firstSelectionBeneficiaryId}
                value={bulkProduct}
                onChange={(_, value) => {
                  setBulkProduct(value)
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                label={translate('_generics.update')}
                onClick={onBulkUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <div className="ag-theme-material" style={{ width: '100%', height: '80vh' }}>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          ensureDomOrder
          rowModelType="infinite"
          datasource={datasource}
          pagination={true}
          paginationPageSize={DEFAULT_PAGE_SIZE}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          onPaginationChanged={onPaginationChanged}
          loadingOverlayComponent={AgGridLoader}
        />
        {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
        <LXPageSize onPageSizeChange={size => gridRef.current?.api!.paginationSetPageSize(size)} />
      </div>
      <ImportCreateProductModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <AutoAssignProductsModal
        isModalOpen={isAutoAssignModalOpen}
        setIsModalOpen={setIsAutoAssignModalOpen}
        importId={youtubeRawImportId}
        onChange={onAutoAssignProductsModalChange}
      />
    </>
  )
}

const defaultColDef = {
  cellStyle: { paddingLeft: 8, paddingRight: 8 },
}

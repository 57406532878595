import React from 'react'

import PostIcon from '@mui/icons-material/Book'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Admin, CustomRoutes, EditGuesser, ListGuesser, Resource, useTranslate } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import './App.scss'

import { LXLayout as Layout } from './layout/Layout'
import { Analytics } from './pages/Analytics/Analytics'
import { artworks } from './pages/Artworks'
import { beneficiaries } from './pages/Beneficiaries'
import { ImportsAmendments } from './pages/Imports/ImportsAmendments'
import { ImportsShortsAmendments } from './pages/Imports/ImportsShortsAmendments'
import { NotFound } from './pages/NotFound'
import { products } from './pages/Products'
import { statements } from './pages/Statements'
import { youtubeChannels } from './pages/YoutubeChannels'
import { youtubeRawImportRows } from './pages/YoutubeRawImportRows'
import { youtubeRawImports } from './pages/YoutubeRawImports'
import { authProvider } from './providers/authProvider'
import { dataProvider } from './providers/dataProvider'
import { i18nProvider } from './providers/i18nProvider'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-material.css'
import { AutomatedTreatments } from './pages/AutomatedTreatments/AutomatedTreatments'

const queryClient = new QueryClient()

export default function App(): JSX.Element {
  const translate = useTranslate()

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Admin
          title="Logicomix Studio"
          layout={Layout}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          catchAll={NotFound}
          disableTelemetry
        >
          <Resource
            name="youtubeRawImports"
            options={{ label: translate('pages.youtubeRawImports._title') }}
            {...youtubeRawImports}
          />
          <Resource
            name="beneficiaries"
            options={{ label: translate('pages.beneficiaries._title') }}
            {...beneficiaries}
          />
          <Resource
            name="products"
            options={{ label: translate('pages.products._title') }}
            {...products}
          />
          <Resource
            name="artworks"
            options={{ label: translate('pages.artworks._title') }}
            {...artworks}
          />
          <Resource name="youtubeRawImportRows" {...youtubeRawImportRows} />
          <Resource
            name="statements"
            options={{ label: translate('pages.statements._title') }}
            {...statements}
          />
          <Resource
            name="youtubeChannels"
            options={{ label: translate('pages.youtubeChannels._title') }}
            {...youtubeChannels}
          />

          <Resource name="beneficiaryTypes" list={ListGuesser} edit={EditGuesser} />
          <Resource name="productTypes" icon={PostIcon} list={ListGuesser} />
          <Resource name="periodicRates" list={ListGuesser} />
          <Resource name="youtubeClaims" />
          <Resource name="youtubeAssets" />
          <Resource name="youtubeAutoEditVideos" />
          <Resource name="statementAdjustmentRows" />
          <Resource name="datavizPresets" />
          <CustomRoutes>
            <Route caseSensitive path="/analytics" key="analytics" element={<Analytics />} />
          </CustomRoutes>
          <CustomRoutes>
            <Route
              caseSensitive
              path="/automated-treatments"
              key="automated-treatments"
              element={<AutomatedTreatments />}
            >
              <Route
                caseSensitive
                path=":automatedTreatmentTab"
                key="automated-treatments-tabs"
                element={<AutomatedTreatments />}
              />
            </Route>
          </CustomRoutes>
          <CustomRoutes>
            <Route
              caseSensitive
              path="/imports/:importId/amend"
              key="amend"
              element={<ImportsAmendments />}
            />
          </CustomRoutes>
          <CustomRoutes>
            <Route
              caseSensitive
              path="/imports/:importId/amendShorts"
              key="amend-shorts"
              element={<ImportsShortsAmendments />}
            />
          </CustomRoutes>
        </Admin>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

import { BeneficiaryCreate } from './BeneficiaryCreate'
import { BeneficiaryEdit } from './BeneficiaryEdit'
import { BeneficiaryList } from './BeneficiaryList'
import { BeneficiaryShow } from './BeneficiaryShow'

export const beneficiaries = {
  create: BeneficiaryCreate,
  edit: BeneficiaryEdit,
  list: BeneficiaryList,
  show: BeneficiaryShow,
}

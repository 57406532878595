import React from 'react'

import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.scss'
import reportWebVitals from './reportWebVitals'

const environment = process.env.REACT_APP_ENV ?? 'development'

Sentry.init({
  dsn: 'https://1f5f0c70adae43aab124f0dd9178e125@o4504972810977280.ingest.sentry.io/4504972855148544',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment,
  enabled: environment !== 'development',
  enableTracing: environment !== 'development',
})

const container = document.getElementById('root')
// biome-ignore lint/style/noNonNullAssertion: Needed to run the app
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React, { useCallback, useEffect, useState } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from '@mui/material'
import { Button, useRefresh, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'

export const AutomatedTreatmentJobModal = (props: AutomatedTreatmentJobModalProps): JSX.Element => {
  const { jobId, isModalOpen, onClose } = props
  const translate = useTranslate()
  const refresh = useRefresh()

  const [job, setJob] = useState<any>()

  const refreshImportStatus = useCallback(async () => {
    const {
      data: { job },
    } = await API.get<{ job: any }>(`/api/translateSubtitles/jobs/${jobId}`)
    setJob(job)
  }, [jobId])

  useEffect(() => {
    if (job?.progress === 100) {
      refresh()
    }
  }, [job?.progress])

  React.useEffect(() => {
    let i: NodeJS.Timeout
    refreshImportStatus().then(() => {
      i = setTimeout(async () => {
        await refreshImportStatus()
      }, 3000)
    })
    return () => {
      clearTimeout(i)
    }
  }, [refreshImportStatus])

  return (
    <Dialog aria-labelledby="automated-treatment-progress" open={isModalOpen}>
      <DialogTitle id="automated-treatment-progress">
        {translate('pages.automatedTreatments.progressModal.title')}
      </DialogTitle>
      <DialogContent dividers>
        <LinearProgress
          variant={job?.progress === 100 || job?.failedReason ? 'determinate' : 'indeterminate'}
          color={job?.progress !== 100 ? 'secondary' : job?.failedReason ? 'error' : 'success'}
        />
        {job?.failedReason ? (
          <p>
            {translate('pages.automatedTreatments.progressModal.error')}: {job.failedReason}
          </p>
        ) : job?.progress === 100 ? (
          <p>{translate('pages.automatedTreatments.progressModal.success')}</p>
        ) : undefined}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          label={translate('_generics.ok')}
        />
      </DialogActions>
    </Dialog>
  )
}

export type AutomatedTreatmentJobModalProps = {
  isModalOpen: boolean
  onClose: () => void
  jobId: string
}

import { AxiosResponse } from 'axios'

export const FileHelper = {
  convertFileToBase64: (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject

      reader.readAsDataURL(file)
    }),
  handleDownloadFileResponse: (
    response: AxiosResponse,
    defaultFilename: string,
    isHeaderFilenameIgnored = false,
  ): Promise<void> => {
    return new Promise<void>(resolve => {
      const filename = isHeaderFilenameIgnored
        ? defaultFilename
        : response.headers['content-disposition']
            ?.replace('attachment; filename="', '')
            .replace('"', '') ?? defaultFilename
      const mimeType = response.headers['content-type']

      const url = URL.createObjectURL(new Blob([response.data], { type: mimeType }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(() => {
        URL.revokeObjectURL(url)
        document.body.removeChild(link)

        resolve()
      }, 100)
    })
  },
  downloadUrl: (url: string): Promise<void> => {
    return new Promise<void>(resolve => {
      const link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(() => {
        URL.revokeObjectURL(url)
        document.body.removeChild(link)

        resolve()
      }, 100)
    })
  },
}

import { Translate } from 'react-admin'

import { AnalyticsDataValue, AnalyticsGroupBy, Metric, RevenueType } from '../types/Analytics'

export const AnalyticsHelper = {
  GROUP_BY: [
    'noGroupBy',
    'youtubeAssetChannelId',
    'beneficiaryId',
    'cms',
    'productTypeId',
    'productId',
    'artworkId',
    'countryCode',
    'revenueType',
  ] as (AnalyticsGroupBy | 'noGroupBy')[],
  REVENUE_TYPE: [
    'shorts_non_music_subscriptions',
    'shorts_non_music_ads',
    'ecommerce',
    'ad',
    'music_red',
    'nonmusic_red',
  ] as RevenueType['id'][],
  METRICS: [
    'analytics.comments',
    'analytics.dislikes',
    'analytics.likes',
    'analytics.redWatchTimeMinutes',
    'analytics.shares',
    'analytics.views',
    'analytics.watchTimeMinutes',
    'revenues.partnerRevenuePerAsset',
    'revenues.partnerRevenuePerAssetPartnerProvided',
    'revenues.partnerRevenuePerAssetUGC',
    'statements.beneficiaryRevenueInEuros',
    'statements.revenueInEuros',
    'statements.revenuePartnerProvidedInEuros',
    'statements.revenueUGCInEuros',
    'statementAdditionals.beneficiaryRevenueInEuros',
    'statementAdditionals.revenueInEuros',
  ] as Metric[],
  extractValueFromMetric: (v?: AnalyticsDataValue, metric?: Metric): number | undefined => {
    if (!v || !metric) {
      return undefined
    }
    switch (metric) {
      case 'analytics.comments':
      case 'analytics.dislikes':
      case 'analytics.likes':
      case 'analytics.redWatchTimeMinutes':
      case 'analytics.shares':
      case 'analytics.views':
      case 'analytics.watchTimeMinutes':
        return v.analytics?.[metric.replace('analytics.', '') as keyof typeof v.analytics]
      case 'revenues.partnerRevenuePerAsset':
      case 'revenues.partnerRevenuePerAssetPartnerProvided':
      case 'revenues.partnerRevenuePerAssetUGC':
        return v.revenues?.[metric.replace('revenues.', '') as keyof typeof v.revenues]
      case 'statements.beneficiaryRevenueInEuros':
      case 'statements.revenueInEuros':
      case 'statements.revenuePartnerProvidedInEuros':
      case 'statements.revenueUGCInEuros':
        return v.statements?.[metric.replace('statements.', '') as keyof typeof v.statements]
      case 'statementAdditionals.beneficiaryRevenueInEuros':
      case 'statementAdditionals.revenueInEuros':
        return v.statementAdditionals?.[
          metric.replace('statementAdditionals.', '') as keyof typeof v.statementAdditionals
        ]
      default:
        throw new Error(`Unknown metric ${metric}`)
    }
  },
  getUnitFromMetric(metric: Metric, translate: Translate): string {
    if (metric.startsWith('analytics')) {
      return translate(`pages.analytics.units.${metric.replace('analytics.', '')}`)
    }
    if (metric.includes('revenue') || metric.includes('Revenue') || metric.includes('Euros')) {
      return '€'
    }

    return ''
  },
  isMetricCompatibleWithGroupBy(metric: Metric, groupBy?: AnalyticsGroupBy): boolean {
    if (groupBy) {
      if (metric.startsWith('analytics.')) {
        return !['revenueType', 'additionalRevenueSource'].includes(groupBy)
      }
      if (metric.startsWith('revenues.')) {
        return !['additionalRevenueSource'].includes(groupBy)
      }
      if (metric.startsWith('statements.')) {
        return !['countryCode', 'revenueType'].includes(groupBy)
      }
      if (metric.startsWith('statementAdditionals.')) {
        return !['productId', 'productTypeId', 'artworkId', 'countryCode'].includes(groupBy)
      }
      if (metric.startsWith('statementAdjustments.')) {
        // not a typo, statementAdjustments supports only groupBy beneficiaryId
        return groupBy === 'beneficiaryId'
      }
    }

    return true
  },
  colors: [
    '#FFA69E',
    '#7D82B8',
    '#AED9E0',
    '#5E6472',
    '#6A8EAE',
    '#157145',
    '#AA767C',
    '#4C3B4D',
    '#E4572E',
    '#A8C686',
  ],
}

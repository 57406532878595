import React, { useCallback, useMemo, useState } from 'react'

import Landscape from '@mui/icons-material/Landscape'
import Timeline from '@mui/icons-material/Timeline'
import { Grid } from '@mui/material'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useTranslate } from 'react-admin'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { ContentType, TooltipProps } from 'recharts/types/component/Tooltip'

import { LXDownloadButton } from '../../components/LXDownloadButton'
import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { NumberFormatter } from '../../helpers/NumberFormatter.helper'
import { AnalyticsDataResponse, MetricItem } from '../../types/Analytics'
import styles from './Chart.module.scss'

type LXChartProps = {
  data?: AnalyticsDataResponse
  metric: MetricItem
  selectedGroupKeys?: string[]
  exportCSVSingleMetric?: ({ singleMetric }: { singleMetric?: string }) => Promise<void>
}

export const LXChart = ({
  data,
  metric,
  selectedGroupKeys,
  exportCSVSingleMetric,
}: LXChartProps): JSX.Element => {
  const translate = useTranslate()
  const [chartType, setChartType] = useState<'line' | 'area'>('area')

  const series = useMemo(() => {
    return data?.groups
      ?.filter(elt => {
        if (!selectedGroupKeys?.length) {
          return true
        }

        return selectedGroupKeys.includes(elt.groupKey)
      })
      .map((g, idx) => {
        return {
          name: g.label ?? 'unknown',
          key: g.groupKey,
          dataKey: `byGroup.${g.groupKey}.${metric.id}`,
          color: AnalyticsHelper.colors[idx % AnalyticsHelper.colors.length],
          total: AnalyticsHelper.extractValueFromMetric(g.total, metric?.id) ?? 0,
        }
      })
      .sort((a, b) => b.total - a.total)
  }, [data, metric, selectedGroupKeys])

  const [lineChartProps, setLineChartProps] = useState(
    series?.reduce(
      (a, { dataKey }) => {
        a[dataKey] = false

        return a
      },
      {} as Record<string, boolean>,
    ),
  )
  const [hover, setHover] = useState<string | undefined>()

  const CustomTooltip: ContentType<any, string> = useCallback(
    ({ active, payload }: TooltipProps<any, string>) => {
      if (active) {
        return (
          <div className={styles.tooltip}>
            {payload?.map((elt: any) => (
              <div key={elt.name} className={styles.tooltipElement}>
                <b style={{ color: elt.color }}>{elt.name}:</b>{' '}
                {NumberFormatter.formatLargeNumber(elt.value)}{' '}
                {AnalyticsHelper.getUnitFromMetric(metric.id, translate)}
              </div>
            ))}
          </div>
        )
      }

      return null
    },
    [metric, translate],
  )

  const onLegendClick = useCallback(
    (d: any) => {
      setLineChartProps({
        ...lineChartProps,
        [d.dataKey]: !lineChartProps?.[d.dataKey],
      })
    },
    [lineChartProps],
  )

  const onLegendMouseEnter = useCallback(
    (e: { dataKey: string }) => {
      if (!lineChartProps?.[e.dataKey]) {
        setHover(e.dataKey)
      }
    },
    [lineChartProps],
  )

  const onLegendMouseLeave = useCallback(() => {
    setHover(undefined)
  }, [])

  const exportCSV = useCallback(() => {
    return exportCSVSingleMetric?.({ singleMetric: metric.id })
  }, [exportCSVSingleMetric, metric])

  const onToggleChartType = useCallback((ct: 'area' | 'line' | null) => {
    if (ct !== null) {
      setChartType(ct)
    }
  }, [])

  const Chart = useMemo(() => (chartType === 'area' ? AreaChart : LineChart), [chartType])
  const Serie = useMemo(() => (chartType === 'area' ? (Area as any) : (Line as any)), [chartType])

  return (
    <>
      {exportCSVSingleMetric && (
        <Grid container lg={12} justifyContent="flex-end">
          <ToggleButtonGroup
            size="small"
            color="primary"
            exclusive
            value={chartType}
            onChange={(_, ct) => onToggleChartType(ct)}
            style={{ marginRight: '1rem' }}
          >
            <ToggleButton value="area">
              <Landscape />
            </ToggleButton>
            <ToggleButton value="line">
              <Timeline />
            </ToggleButton>
          </ToggleButtonGroup>
          <LXDownloadButton label={translate('pages.analytics.exportToCsv')} download={exportCSV} />
        </Grid>
      )}
      <div className={styles.container} style={{ width: '100%', height: '25rem' }}>
        <ResponsiveContainer>
          <Chart
            width={600}
            height={300}
            data={data?.chartData ?? []}
            className={styles.chart}
            syncId="synced-chart"
          >
            <CartesianGrid stroke="#eee" strokeDasharray="8 8" />
            <XAxis fontSize={13} dataKey="yearMonth" type="category" />
            <YAxis
              fontSize={13}
              tickFormatter={value =>
                `${NumberFormatter.formatLargeNumberWithAbbreviation(
                  value,
                )} ${AnalyticsHelper.getUnitFromMetric(metric?.id, translate)}`
              }
            />
            <Tooltip content={CustomTooltip} />
            <Legend
              iconType="plainline"
              onClick={onLegendClick}
              onMouseOver={onLegendMouseEnter}
              onMouseOut={onLegendMouseLeave}
            />
            {series?.slice(0, 5).map(s => (
              <Serie
                type="monotone"
                key={s.key}
                dataKey={s.dataKey}
                stroke={s.color}
                strokeWidth={2}
                fill={s.color}
                name={s.name}
                hide={lineChartProps?.[s.dataKey] === true}
                opacity={Number(hover === s.dataKey || !hover ? 1 : 0.1)}
                {...(chartType === 'area'
                  ? {
                      stackId: '1',
                    }
                  : undefined)}
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </div>
    </>
  )
}

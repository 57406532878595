import React, { useCallback, useEffect, useState } from 'react'

import { Edit as EditIcon } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  Button,
  Edit,
  FormTab,
  ReferenceField,
  ReferenceInput,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import {
  LXEditPeriodicRates,
  LXEditPeriodicRatesHandle,
} from '../../components/LXEditPeriodicRates'
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar'
import beneficiaryStyles from '../Beneficiaries/Beneficiary.module.scss'
import { LXField } from './../../components/LXField'
import { API } from './../../helpers/Api.helper'
import { ListHelper } from './../../helpers/List.helper'
import { Beneficiary } from './../../types/Beneficiary'
import { Modal } from './../../types/Modal'
import styles from './ProductEdit.module.scss'

export const ProductEdit = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const translate = useTranslate()

  const refresh = useRefresh()
  const redirect = useRedirect()
  const params = useParams()

  const editRateRef = React.useRef<LXEditPeriodicRatesHandle>(null)

  const onSuccess = React.useCallback(async () => {
    await editRateRef.current?.save()

    refresh()
    redirect('show', '/products', params.id)
  }, [refresh, redirect, params.id])

  return (
    <Edit
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess,
      }}
      redirect="show"
    >
      <TabbedForm toolbar={<NoDeleteEditToolbar />}>
        <FormTab
          label={translate('pages.products._tabs.1')}
          contentClassName={beneficiaryStyles.tab}
        >
          <Grid container item direction="row" lg={12} spacing={2}>
            <LXField
              source="name"
              label={translate('_generics.name')}
              elementType={TextInput}
              size={6}
            />
            <LXField
              source="name"
              label={translate('_generics.beneficiary')}
              elementType={TextField}
              size={6}
              wrappingFunction={el => (
                <span style={{ position: 'relative' }}>
                  <ReferenceField source="beneficiaryId" reference="beneficiaries" link={false}>
                    {el}
                  </ReferenceField>
                  <EditIcon
                    color="primary"
                    className={styles.edit}
                    onClick={() => setIsModalOpen(true)}
                  />
                </span>
              )}
              labeled
            />
            <LXField size={6}>
              <ReferenceInput
                source="productTypeId"
                reference="productTypes"
                sort={ListHelper.DEFAULT_SORT}
              >
                <AutocompleteInput
                  optionText="name"
                  fullWidth
                  label={translate('pages.products.productType')}
                />
              </ReferenceInput>
            </LXField>
          </Grid>
          <ProductEditBeneficiaryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </FormTab>
        <FormTab
          label={translate('pages.products._tabs.3')}
          path="periodic-rates"
          contentClassName={beneficiaryStyles.tab}
        >
          <Typography component="span" variant="caption">
            {translate('pages.products.specificRateExplaination')}
          </Typography>
          <LXEditPeriodicRates
            ref={editRateRef}
            rateable="product"
            rateableId={params.id as string}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const ProductEditBeneficiaryModal = ({ isModalOpen, setIsModalOpen }: Modal) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const record = useRecordContext()
  const refresh = useRefresh()
  const [currentBeneficiary, setCurrentBeneficiary] = useState<Beneficiary>()
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<string>()
  const [impactedArtworksCount, setImpactedArtworksCount] = useState<number>(-1)
  const [impactedRowsCount, setImpactedRowsCount] = useState<number>(-1)
  const [hash, setHash] = useState<string>('')

  useEffect(() => {
    const getCurrentData = async () => {
      await dataProvider
        .getOne('beneficiaries', { id: record.beneficiaryId as string })
        .then(resBeneficiary => {
          setCurrentBeneficiary(resBeneficiary.data as Beneficiary)
        })
    }
    getCurrentData()
  }, [dataProvider, record])

  const patchProduct = useCallback(
    async (productId: string, beneficiaryId: string, isDryRun = true) => {
      const res: ProductPatchRes = await API.patch(`/api/products/${productId}`, {
        beneficiaryId,
        isDryRun,
        hash,
      })

      setImpactedArtworksCount(res.data.impactedArtworksCount)
      setImpactedRowsCount(res.data.impactedImportRowsCount)
      setHash(res.data.hash)

      return { isDryRun: res.data.isDryRun }
    },
    [hash],
  )

  const onBeneficiaryChange = useCallback(
    async (beneficiaryId: string) => {
      setSelectedBeneficiaryId(beneficiaryId)

      try {
        await patchProduct(record.id as string, beneficiaryId)
      } catch (e) {
        console.error(e)
        notify(e as string, { type: 'error' })
      }
    },
    [notify, record, setSelectedBeneficiaryId, patchProduct],
  )

  const transferProductToOtherBeneficiary = useCallback(async () => {
    try {
      const res = await patchProduct(record.id as string, selectedBeneficiaryId as string, false)

      refresh()

      if (res.isDryRun === false) {
        setIsModalOpen(false)
        notify('pages.products.toast_transferSuccess', { type: 'success' })
      }
    } catch (e) {
      console.error(e)
      notify(`${translate('pages.products.toast_transferError')} - ${e as string}`, {
        type: 'error',
      })
    }
  }, [notify, record, selectedBeneficiaryId, translate, patchProduct, refresh, setIsModalOpen])

  return (
    <Dialog aria-labelledby="product-edit-product-modal" open={isModalOpen}>
      <DialogTitle id="product-edit-product-modal">
        {translate('pages.products.modal_transferProductBeneficiary')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <LXField label={translate('pages.products.modal_currentBeneficiary')} labeled size={6}>
            {currentBeneficiary?.name}
          </LXField>
        </Grid>
        <hr />
        <Grid container direction="row" spacing={2}>
          <Grid item lg={6}>
            <ReferenceInput
              source="beneficiaryId"
              reference="beneficiaries"
              sort={ListHelper.DEFAULT_SORT}
              defaultValue={currentBeneficiary?.id}
            >
              <AutocompleteInput
                optionText="name"
                helperText={false}
                label={translate('_generics.beneficiary')}
                onChange={onBeneficiaryChange}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Typography variant="body1" color="textPrimary" className={styles.information}>
          <strong>
            {(impactedArtworksCount > 0 || impactedRowsCount > 0) && (
              <i>
                {translate('pages.products.modal_impacted.intro')}&#58;
                <br />
              </i>
            )}
            {impactedArtworksCount > 0 &&
              translate('pages.products.modal_impacted.artworks', {
                smart_count: impactedArtworksCount,
              })}
            <br />
            {impactedRowsCount > 0 &&
              translate('pages.products.modal_impacted.rows', {
                smart_count: impactedRowsCount,
              })}
            {impactedArtworksCount === 0 &&
              impactedRowsCount === 0 &&
              translate('pages.products.modal_noImpactedRows')}
          </strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          label={translate('_generics.cancel')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            transferProductToOtherBeneficiary()
          }}
          label={translate('_generics.transfer')}
        />
      </DialogActions>
    </Dialog>
  )
}

type ProductPatchRes = {
  data: {
    isDryRun: boolean
    hash: string
    impactedArtworksCount: number
    impactedImportRowsCount: number
  }
}

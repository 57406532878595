import Cookies, { CookieAttributes } from 'js-cookie'

export const AUTH_COOKIE = 'logicomix-auth'
export const AUTH_COOKIE_OPTIONS = {
  expires: 7,
  secure: true,
  sameSite: 'strict',
} as CookieAttributes

export function getJWT(): string {
  return Cookies.get(AUTH_COOKIE) || ''
}

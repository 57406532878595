import React, { useCallback, useEffect, useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Button, Labeled, useDataProvider, useNotify, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { Artwork } from '../../types/Artwork'
import { Beneficiary } from '../../types/Beneficiary'
import { YoutubeAsset } from '../../types/YoutubeAsset'
import genericStyles from './../../generics.module.scss'

export interface ArtworksMergeModalProps {
  artworkId: Artwork['id']
  artworkName: Artwork['name']
  beneficiaryId: Beneficiary['id']
  isOpen?: boolean
  onClose: (created: boolean) => void
}

export const ArtworksMergeModal: React.FC<ArtworksMergeModalProps> = ({
  artworkId,
  artworkName,
  beneficiaryId,
  isOpen = false,
  onClose,
}) => {
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const notify = useNotify()
  const [isMerging, setIsMerging] = useState(false)
  const [allArtworks, setAllArtworks] = useState<Artwork[]>([])
  const [selectedArtworks, setSelectedArtworks] = useState<Artwork[]>([])
  const [youtubeAssets, setYoutubeAssets] = useState<YoutubeAsset[]>([])
  const [error, setError] = useState<string>()

  const getAllArtworks = useCallback(async () => {
    const response = await dataProvider.getList('artworks', {
      sort: { field: 'id', order: 'ASC' },
      filter: { 'product.beneficiaryId': beneficiaryId },
      pagination: { page: 1, perPage: 999 },
    })
    setAllArtworks(
      response.data
        .filter(a => a.id !== artworkId)
        .sort((a, b) => a.name.localeCompare(b.name)) as Artwork[],
    )
  }, [artworkId, beneficiaryId, dataProvider])

  const getYoutubeAssets = useCallback(async () => {
    if (selectedArtworks.length > 0) {
      const response = await dataProvider.getList('youtubeAssets', {
        sort: { field: 'id', order: 'ASC' },
        filter: { artworkId: selectedArtworks.map(sa => sa.id) },
        pagination: { page: 1, perPage: 999 },
      })
      setYoutubeAssets((response.data as YoutubeAsset[]) || [])
    }
  }, [dataProvider, selectedArtworks])

  useEffect(() => {
    if (!isOpen) {
      setIsMerging(false)
    } else {
      getAllArtworks()
      getYoutubeAssets()
    }
  }, [getAllArtworks, getYoutubeAssets, isOpen])

  useEffect(() => {
    getYoutubeAssets()
  }, [getYoutubeAssets, selectedArtworks])

  const closeModal = useCallback(
    (isCreated: boolean) => {
      onClose(isCreated)
      setError(undefined)
    },
    [onClose, setError],
  )

  const mergeArtworks = useCallback(async () => {
    setIsMerging(true)
    setError(undefined)
    try {
      const artworkIds = selectedArtworks.map(sa => sa.id)
      await API.post(`/api/artworks/${artworkId}/merge`, {
        artworksTomergeIds: artworkIds,
      })
      closeModal(true)
      setIsMerging(false)
    } catch (e: any) {
      const message = e.response?.data?.message || e.response?.data

      notify(`${message}`, { type: 'error' })
      console.error('Error - merge artworks', e)
      setError(`${message} - ${e.response.data.content?.fieldError ?? e.response.data.content}`)
    }
  }, [selectedArtworks, artworkId, closeModal, notify])

  return (
    <Dialog
      aria-labelledby="youtube-import-modal"
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => closeModal(false)}
    >
      <DialogTitle id="youtube-import-modal">
        {translate('pages.artworks.mergeArtworks')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <Grid item lg={12}>
            <Typography variant="body2" color="textPrimary">
              {translate('pages.artworks.mergeArtworksDescription', {
                name: artworkName,
              })}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <InputLabel id="artworks-list-merge" style={{ fontSize: '80%' }}>
              {translate('pages.artworks.beneficiaryArtworks')}
            </InputLabel>
            <Autocomplete
              id="artworks-list-merge"
              options={allArtworks}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} variant="standard" />}
              renderOption={(props, option, index) => (
                <li {...props} key={`artwork-${index}-${option.id}`}>
                  {option.name}
                </li>
              )}
              noOptionsText={translate('_generics.noResult')}
              onChange={(_, value) => setSelectedArtworks(value)}
              disableCloseOnSelect
              multiple
              disabled={isMerging}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
        </Grid>
        {selectedArtworks.length > 0 && (
          <div style={{ marginTop: '1rem' }}>
            {selectedArtworks.map(a => (
              <Accordion elevation={0} className={genericStyles.defaultFont} key={a.id}>
                <AccordionSummary expandIcon={<ExpandMore />}>{a.name}</AccordionSummary>
                <AccordionDetails>
                  <Grid container columnSpacing={2}>
                    <Grid item>
                      <Labeled label={translate('_generics.product')}>
                        <Typography variant="body2">{a.product.name}</Typography>
                      </Labeled>
                    </Grid>
                    <Grid item>
                      <Labeled label={translate('_generics.assetId')}>
                        <>
                          {youtubeAssets
                            .filter(ya => ya.artworkId === a.id)
                            .map(ya => (
                              <Typography variant="body2" key={ya.id}>
                                {ya.id}
                              </Typography>
                            ))}
                        </>
                      </Labeled>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
        {error && (
          <p className={genericStyles.error}>
            <i>{translate('_generics.errorOccured')}</i>:&nbsp;
            <strong>{error}</strong>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal(false)}
          label={translate('_generics.cancel')}
          disabled={isMerging && !error}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            mergeArtworks()
          }}
          label={translate('_generics.merge')}
          disabled={isMerging}
        />
      </DialogActions>
    </Dialog>
  )
}

import React, { useCallback, useState } from 'react'

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin'

import genericStyles from './../../generics.module.scss'
import styles from './CreateStatementModal.module.scss'

export interface CreateStatementAdjustmentRowModalProps {
  statementId: string
  isOpen?: boolean
  onClose: (created: boolean) => void
}

export const CreateStatementAdjustmentRowModal: React.FC<CreateStatementAdjustmentRowModalProps> =
  ({ statementId, isOpen = false, onClose }) => {
    const translate = useTranslate()
    const notify = useNotify()
    const [creating, setCreating] = React.useState(false)
    const [label, setLabel] = React.useState('')
    const [amountInEuros, setAmountInEuros] = React.useState('')
    const [error, setError] = useState<string>()
    const dataProvider = useDataProvider()

    React.useEffect(() => {
      if (!isOpen) {
        setLabel('')
        setAmountInEuros('')
        setCreating(false)
      }
    }, [isOpen])

    const closeModal = useCallback(
      (isCreated: boolean) => {
        onClose(isCreated)
        setError(undefined)
      },
      [onClose, setError],
    )

    const createRow = React.useCallback(async () => {
      try {
        setCreating(true)
        setError(undefined)
        await dataProvider.create('statementAdjustmentRows', {
          data: {
            statementId,
            label,
            amountInEuros,
          },
        })
        closeModal(true)
        setCreating(false)
      } catch (e: any) {
        const message = e.response.data?.message || e.response.data

        notify(`${message}`, { type: 'error' })
        console.error('Error - create statement adjustment row', e)
        setError(`${message} - ${e.response.data.content?.fieldError || e.response.data.content}`)
      }
    }, [amountInEuros, closeModal, dataProvider, label, notify, statementId])

    const createButtonEnabled = React.useMemo(() => {
      if (label.trim().length === 0) {
        return false
      }
      try {
        const parsed = Number.parseFloat(amountInEuros.replace(',', '.'))

        return !Number.isNaN(parsed)
      } catch (e) {
        return false
      }
    }, [amountInEuros, label])

    return (
      <Dialog aria-labelledby="youtube-import-modal" open={isOpen}>
        <DialogTitle id="youtube-import-modal">
          {translate('pages.statements.addAdjustmentRow')}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="row" spacing={4}>
            <Grid item lg={12}>
              <TextField
                label={translate('pages.statements.adjustmentRow.label')}
                variant="standard"
                type="text"
                value={label}
                fullWidth
                onChange={e => setLabel(e.target.value)}
                required
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                label={translate('pages.statements.adjustmentRow.amountInEuros')}
                variant="standard"
                type="number"
                value={amountInEuros}
                fullWidth
                onChange={e => setAmountInEuros(e.target.value)}
                required
              />
            </Grid>
          </Grid>
          {creating && !error && (
            <div className={styles.message}>
              <CircularProgress size="1rem" />
            </div>
          )}
          {error && (
            <p className={genericStyles.error}>
              <i>{translate('_generics.errorOccured')}</i>:&nbsp;
              <strong>{error}</strong>
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => closeModal(false)}
            label={translate('_generics.cancel')}
            disabled={creating && !error}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createRow()
            }}
            label={translate('_generics.create')}
            disabled={!createButtonEnabled || creating}
          />
        </DialogActions>
      </Dialog>
    )
  }

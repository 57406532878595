import React from 'react'

import { Delete, ErrorOutline } from '@mui/icons-material'
import { Grid, Input } from '@mui/material'
import Typography from '@mui/material/Typography'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Button, Loading, useGetList, useTranslate } from 'react-admin'
import { useImmer } from 'use-immer'

import { API } from '../helpers/Api.helper'
import { BeneficiaryHelper, RateEditable } from '../helpers/Beneficiary.helper'
import { PeriodicRate } from '../types/Rates'
import { LXField } from './LXField'
import { DatePickerSX } from './LXSelectDate'

export type Rateable = 'beneficiary' | 'product'

export type LXEditPeriodicRatesHandle = {
  /** Perform the necessary API calls to persist all the changes made */
  save: () => Promise<void>
}
export interface LXEditPeriodicRatesProps {
  rateable: Rateable
  rateableId: string
  onUpdateRates?: (newValues: {
    toPersist: RateEditable[]
    idsToDelete: string[]
  }) => void
}

const LXEditPeriodicRatesToForward: React.ForwardRefRenderFunction<
  LXEditPeriodicRatesHandle,
  LXEditPeriodicRatesProps
> = ({ rateable, rateableId, onUpdateRates }, forwardedRef) => {
  const translate = useTranslate()
  const periodicRatesQuery = useGetList<PeriodicRate>('periodicRates', {
    pagination: { page: 1, perPage: 1000 },
    sort: API.DEFAULT_SORT,
    filter: { rateable, rateableId },
  })

  const [dataLoadedFromAPI, setDataLoadedFromAPI] = React.useState(false)

  const [deletedRateIds, updateDeletedRateIds] = useImmer<string[]>([])
  const [newRates, updateNewRates] = useImmer<RateEditable[]>([])

  const displayedRates = React.useMemo(() => {
    return newRates
      .filter(elt => !deletedRateIds.includes(elt.id))
      .sort(BeneficiaryHelper.compareStartEndDate)
  }, [newRates, deletedRateIds])

  React.useEffect(() => {
    // initialize rates first time data comes back from the API
    if (!dataLoadedFromAPI && periodicRatesQuery.data) {
      updateNewRates(BeneficiaryHelper.formatPeriodicRates(periodicRatesQuery.data))
      setDataLoadedFromAPI(true)
    }
  }, [dataLoadedFromAPI, periodicRatesQuery.data])

  React.useEffect(() => {
    onUpdateRates?.({
      toPersist: displayedRates,
      idsToDelete: deletedRateIds,
    })
  }, [displayedRates, deletedRateIds, onUpdateRates])

  const addDefaultRate = React.useCallback(() => {
    updateNewRates(rateItems => {
      let startDate: dayjs.Dayjs | undefined = undefined
      if (displayedRates.length > 0) {
        startDate = displayedRates[rateItems.length - 1].endDate?.add(1, 'month')
      }
      rateItems.push(BeneficiaryHelper.defaultRate(startDate))

      return rateItems
    })
  }, [updateNewRates, displayedRates])

  const saveAll = React.useCallback(async () => {
    await Promise.all(
      newRates.map(rateItem => {
        const { isLocal, startDate, endDate, rate } = rateItem
        const formattedRate = (rate >= 100 ? 100 : rate) / 100
        if (isLocal) {
          return API.post('/api/periodicRates', {
            rateable,
            rateableId,
            startDate: startDate.toDate().toISOString(),
            endDate: endDate?.toDate().toISOString(),
            rate: formattedRate,
          })
        }

        return API.put(`/api/periodicRates/${rateItem.id}`, {
          rateable,
          rateableId,
          startDate: startDate.toDate().toISOString(),
          endDate: endDate?.toDate().toISOString(),
          rate: formattedRate,
        })
      }),
    )
    await Promise.all(
      deletedRateIds.map(async id => {
        await API.delete(`/api/periodicRates/${id}`)
      }),
    )
  }, [newRates, rateable, rateableId, deletedRateIds])

  React.useImperativeHandle(forwardedRef, () => ({
    save: saveAll,
  }))

  const onRemoveRate = React.useCallback(
    (rateItem: RateEditable) => {
      if (rateItem.isLocal) {
        updateNewRates(rates => {
          const idxOf = rates.findIndex(elt => elt.id === rateItem.id)
          if (idxOf > -1) {
            rates.splice(idxOf, 1)
          }
        })
      } else {
        updateDeletedRateIds(deletedIds => {
          if (!deletedIds.includes(rateItem.id)) {
            deletedIds.push(rateItem.id)
          }
        })
      }
    },
    [updateNewRates, updateDeletedRateIds],
  )

  const onChangeRateValue = React.useCallback(
    (rateItem: RateEditable, newValue: number) => {
      updateNewRates(rates => {
        const r = rates.find(elt => elt.id === rateItem.id)
        if (r) {
          r.rate = newValue
        }
      })
    },
    [updateNewRates],
  )

  const onChangeStartDate = React.useCallback(
    (rateItem: RateEditable, newDate?: Date) => {
      if (!newDate) {
        return
      }
      updateNewRates(rates => {
        const r = rates.find(elt => elt.id === rateItem.id)
        if (r) {
          r.startDate = dayjs(newDate)
          if (r.endDate && r.endDate < r.startDate) {
            r.endDate = r.startDate
          }
        }
      })
    },
    [updateNewRates],
  )

  const onChangeEndDate = React.useCallback(
    (rateItem: RateEditable, newDate?: Date) => {
      updateNewRates(rates => {
        const r = rates.find(elt => elt.id === rateItem.id)
        if (r) {
          r.endDate = newDate ? dayjs(newDate) : undefined
          if (r.endDate && r.startDate > r.endDate) {
            r.startDate = r.endDate
          }
        }
      })
    },
    [updateNewRates],
  )

  const hasErrorInRates = React.useMemo(() => {
    for (let i = 0; i < displayedRates.length - 1; i++) {
      const curr = displayedRates[i]
      if (curr.endDate && curr.startDate > curr.endDate) {
        return true
      }
      if (i < displayedRates.length - 1) {
        const next = displayedRates[i + 1]
        if (curr.startDate >= next.startDate || (curr.endDate && curr.endDate >= next.startDate)) {
          return true
        }
      }
    }

    return false
  }, [displayedRates])

  if (!dataLoadedFromAPI) {
    return <Loading />
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid container item direction="column" spacing={2} lg={10}>
        {displayedRates.map((rateItem, index) => {
          const { id, rate, startDate, endDate } = rateItem

          return (
            <Grid container item direction="row" spacing={6} key={id}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <LXField
                  label={`${translate('pages.beneficiaries.rate')} ${index + 1} (%)`}
                  size={2}
                  labeled
                >
                  <Input
                    id="input-rate"
                    type="number"
                    style={{ width: 150 }}
                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                    value={rate}
                    onChange={e => {
                      onChangeRateValue(
                        rateItem,
                        Math.round(parseFloat(e.target.value) * 100) / 100,
                      )
                    }}
                  />
                </LXField>
                <LXField label={translate('_generics.fromIncluded')} size={2} labeled>
                  <MobileDatePicker
                    value={startDate}
                    onAccept={date => {
                      onChangeStartDate(rateItem, date?.toDate())
                    }}
                    views={['month', 'year']}
                    slotProps={{
                      toolbar: { hidden: true },
                    }}
                    sx={DatePickerSX}
                  />
                </LXField>
                <LXField label={translate('_generics.toIncluded')} size={2} labeled>
                  <MobileDatePicker
                    value={endDate}
                    onAccept={date => {
                      onChangeEndDate(rateItem, date?.toDate())
                    }}
                    views={['month', 'year']}
                    slotProps={{
                      toolbar: { hidden: true },
                    }}
                    sx={DatePickerSX}
                  />
                </LXField>
              </LocalizationProvider>
              <Grid container item lg={2} style={{ alignItems: 'center' }}>
                <Button onClick={() => onRemoveRate(rateItem)}>
                  <Delete color="error" fontSize="small" />
                </Button>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid item lg={2} style={{ textAlign: 'right', marginTop: '1rem' }}>
        <Button
          variant="outlined"
          onClick={() => {
            addDefaultRate()
          }}
          label={translate('pages.beneficiaries.addRate')}
        />
      </Grid>
      {hasErrorInRates && (
        <Grid item lg={12} container direction="row">
          <ErrorOutline color="error" style={{ marginRight: '1rem' }} />
          <Typography variant="body1" color="error">
            {translate('pages.beneficiaries.rateInvalid')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export const LXEditPeriodicRates = React.forwardRef(LXEditPeriodicRatesToForward)

import { useCallback, useContext } from 'react'

import { DataProviderContext, useDataProvider, useNotify } from 'react-admin'

import { ShortVideoForCorrection } from '../types/ShortVideoForCorrection'
import { ImportRows } from './../types/ImportRows'
import { API } from './Api.helper'

export const ImportsHelper = {
  IMPORT_STATUSES: {
    UPLOADED: '00_uploaded',
    REFRESHED_YT_CHANNELS: '10_refreshedYoutubeChannels',
    MATCHED_W_ARTWORKS: '20_matchedAgainstArtworks',
    FILLED_ASSET_TITLES: '30_filledAssetTitlesFromAPI',
    AUTOCORRECTED_ROWS: '40_autoCorrectedRows',
    READY: '100_ready',
    CORRECTED: '120_corrected',
    ERROR: '500_error',
  },
  IMPORT_TYPES: [
    {
      id: 'asset_summary',
      name: 'Ads - Asset summary',
    },
    {
      id: 'asset_summary_adj',
      name: 'Ads - ADJ Asset summary',
    },
    {
      id: 'red_music_asset',
      name: 'RED - Music - Rawdata Asset',
    },
    {
      id: 'red_non_music_asset',
      name: 'RED - Rawdata Asset',
    },
    {
      id: 'ecommerce_paid_feature',
      name: 'ECommerce - Paid Feature',
    },
    {
      id: 'shorts_non_music_subscriptions_video',
      name: 'Shorts - Non Music - Subscriptions Video',
    },
    {
      id: 'shorts_non_music_ads_video',
      name: 'Shorts - Non Music - Ads Video',
    },
  ],
  useUpdateImportRow: () => {
    const notify = useNotify()
    const dataProvider = useDataProvider()

    return useCallback(
      async (
        resourceId: string,
        updateData: ImportRows,
        notifyMessage?: UpdateImportNotifyMessage,
      ) => {
        try {
          await dataProvider.update('youtubeRawImportRows', {
            id: resourceId,
            previousData: { id: resourceId },
            data: updateData,
          })
          if (notifyMessage) {
            notify(notifyMessage.success, { type: 'success' })
          }
        } catch (e: any) {
          console.error(`Error - Edit name for ${resourceId}`, e)
          if (notifyMessage) {
            notify(notifyMessage.error, { type: 'error' })
          }
        }
      },
      [notify, dataProvider],
    )
  },
  useGetUntreatedResults: () => {
    const dataProvider = useContext(DataProviderContext)

    return useCallback(
      async (importId: string, step: number) => {
        let customFilter: Record<string, string | null> = {
          // @ts-ignore hack around to search for empty or null assetTitleEdited, see server implem
          assetTitleEdited: true,
        }

        if (step === 1) {
          customFilter = { beneficiaryId: null }
        } else if (step === 2) {
          customFilter = { productId: null }
        } else if (step === 3) {
          customFilter = { artworkId: null }
        }

        return dataProvider.getList('youtubeRawImportRows', {
          pagination: { page: 1, perPage: 2 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            ...{ youtubeRawImportId: importId },
            ...customFilter,
          },
        })
      },
      [dataProvider],
    )
  },
  useGetShortsUntreatedResults: () => {
    return useCallback(async (importId: string, step: number) => {
      if (step === 0) {
        return API.get<ShortVideoForCorrection[]>(
          `/api/youtubeRawImports/${importId}/shortsVideosRows`,
          {
            params: { beneficiaryId: 'null' },
          },
        )
      }
    }, [])
  },
}

type UpdateImportNotifyMessage = {
  success: string
  error: string
}

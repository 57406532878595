import React, { useCallback, useState } from 'react'

import { MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../helpers/constants'
import styles from './LXPageSize.module.scss'

type LXPageSizeType = {
  onPageSizeChange: (size: number) => void
}

export const LXPageSize = ({ onPageSizeChange }: LXPageSizeType): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE)

  const onChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      const size = e.target.value
      setPageSize(size as number)
      onPageSizeChange(size as number)
    },
    [onPageSizeChange],
  )

  return (
    <Select<number>
      id="ag-grid-pagesize"
      value={pageSize}
      onChange={onChange}
      className={styles.select}
      variant="standard"
    >
      {PAGE_SIZES.map(s => (
        <MenuItem value={s} key={s}>
          {s}
        </MenuItem>
      ))}
    </Select>
  )
}

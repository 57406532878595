import locale from 'locale-codes'
import { YOUTUBE_LOCALES } from './youtube-locales'

const COMMON_LANG = ['en', 'fr', 'de', 'es']

const LOCALES = locale.all.map(l => {
  return {
    id: l.tag,
    name: `${l.name} ${l.location ? `(${l.location} / ${l.tag})` : `(${l.tag})`}`,
  }
})

export const L10nHelper = {
  getLocales: () => [
    ...LOCALES.filter(l => COMMON_LANG.includes(l.id)),
    ...LOCALES.filter(l => !COMMON_LANG.includes(l.id)),
  ],
  getYoutubeLocales: () => [
    ...YOUTUBE_LOCALES.filter(l => COMMON_LANG.includes(l.id)),
    ...YOUTUBE_LOCALES.filter(l => !COMMON_LANG.includes(l.id)),
  ],
}

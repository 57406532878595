import React, { useCallback } from 'react'

import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import {
  CreateButton,
  Link,
  List,
  ListProps,
  SearchInput,
  SelectInput,
  TopToolbar,
  useCreatePath,
  useListContext,
} from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { CMS_LIST } from '../../helpers/constants'
import { LXImage } from './../../components/LXImage/LXImage'
import { Beneficiary } from './../../types/Beneficiary'
import styles from './BeneficiaryList.module.scss'

const BeneficiaryGrid = (): JSX.Element => {
  const { data } = useListContext<Beneficiary>()
  const createPath = useCreatePath()

  const createBeneficiaryPath = useCallback(
    (id: Beneficiary['id']) =>
      createPath({
        resource: 'beneficiaries',
        id,
        type: 'show',
      }),
    [createPath],
  )

  return (
    <ImageList className={styles.container} cols={5}>
      {data?.map(beneficiary => (
        <ImageListItem key={beneficiary.id}>
          <Link to={createBeneficiaryPath(beneficiary.id)}>
            <LXImage source="logoBase64" gridSize={12} record={beneficiary} />
            <ImageListItemBar title={beneficiary.name} />
          </Link>
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const BeneficiaryListActions = (): JSX.Element => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
)

export const BeneficiaryList = (props: ListProps): JSX.Element => (
  <List
    {...props}
    actions={<BeneficiaryListActions />}
    perPage={API.DEFAULT_PAGINATION.perPage}
    filters={[
      <SearchInput key="search" source="q" alwaysOn />,
      <SelectInput key="cms" source="cms" choices={CMS_LIST} alwaysOn />,
    ]}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <BeneficiaryGrid />
  </List>
)

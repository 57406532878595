import React from 'react'

import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  ShowButton,
  TextField,
  TopToolbar,
  useTranslate,
} from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { ListHelper } from './../../helpers/List.helper'

const ProductListActions = (): JSX.Element => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
)

export const ProductList = (props: ListProps): JSX.Element => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      actions={<ProductListActions />}
      exporter={false}
      perPage={API.DEFAULT_PAGINATION.perPage}
      sort={{ field: 'name', order: 'DESC' }}
      filters={[
        <SearchInput key="search" source="q" alwaysOn />,
        <ReferenceInput
          key="productType"
          source="productTypeId"
          reference="productTypes"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.productType')} />
        </ReferenceInput>,
        <ReferenceInput
          key="beneficiaryId"
          source="beneficiaryId"
          reference="beneficiaries"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.beneficiary')} />
        </ReferenceInput>,
      ]}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label={translate('_generics.name')} />
        <ReferenceField
          source="productTypeId"
          reference="productTypes"
          label={translate('pages.products.productType')}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="beneficiaryId"
          reference="beneficiaries"
          label={translate('pages.products.beneficiary')}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ShowButton />
      </Datagrid>
    </List>
  )
}

import {
  Button,
  Datagrid,
  FunctionField,
  Link,
  List,
  Loading,
  RaRecord,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin'
import { API } from '../../helpers/Api.helper'
import { L10nHelper } from '../../helpers/L10n.helper'
import styles from './AutomatedTreatments.module.scss'

import { Link as MUILink } from '@mui/material'

import { Translate, Warning, YouTube as YouTubeIcon } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  Stack,
  TextField as MUITextField,
  Tooltip,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { DateHelper, LONG_FORMAT } from '../../helpers/Date.helper'
import { dataProvider } from '../../providers/dataProvider'
import { TranslateSubtitles } from '../../types/AutomatedTreatments'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import {
  AutomatedTreatmentJobModal,
  AutomatedTreatmentJobModalProps,
} from './AutomatedTreatmentJobModal'

type YTChannelType = Pick<
  YoutubeChannel,
  'id' | 'name' | 'subtitleMainLanguage' | 'subtitlesLanguages'
>

type ErrorType = { id: 'with' | 'without'; label: string }
type PublicationDateType = { id: 'yes' | 'no'; label: string }

const SUBTITLES_FILTERS = { isAutoSubtitlesTranslationEnabled: true }

export const AutomatedTranslation = () => {
  const translate = useTranslate()
  const [selectedChannelId, setSelectedChannelId] = useState<string | undefined>()
  const [selectedErrorType, setSelectedErrorType] = useState<ErrorType | null>()
  const [selectedPublicationDate, setSelectedPublicationDate] =
    useState<PublicationDateType | null>()
  const [channelsWithSubtitles, setChannelsWithSubtitles] = useState<YTChannelType[] | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [jobModal, setJobModal] = useState<AutomatedTreatmentJobModalProps>()

  useEffect(() => {
    const getYoutubeChannels = async () => {
      const response = await dataProvider.getList<YoutubeChannel>('youtubeChannels', {
        sort: { field: 'id', order: 'ASC' },
        filter: SUBTITLES_FILTERS,
        pagination: { page: 1, perPage: 999 },
      })

      setChannelsWithSubtitles(
        response.data.map(({ id, name, subtitleMainLanguage, subtitlesLanguages }) => ({
          id,
          name,
          subtitleMainLanguage,
          subtitlesLanguages,
        })),
      )
      setIsLoading(false)
    }

    getYoutubeChannels()
  }, [])

  const onTranslate = useCallback(
    async ({ channelId, videoId }: { channelId?: string; videoId?: string }) => {
      let body: TranslateSubtitles = {}

      if (channelId && videoId) {
        body = {
          onlyChannelIds: [channelId],
          onlyVideoIds: [videoId],
        }
      } else if (selectedChannelId) {
        body = {
          onlyChannelIds: [selectedChannelId],
          shouldIncludePrivateVideos: true,
        }
      }

      const data = await API.post<TranslateSubtitles>('/api/translateSubtitles/jobs', body)
      setJobModal({
        isModalOpen: true,
        jobId: data.data?.job?.id,
        onClose: () => {
          setJobModal(undefined)
        },
      })
      return data
    },
    [selectedChannelId],
  )

  const getLangs = (langsList: string): Array<string> => {
    if (langsList) {
      const locales = L10nHelper.getYoutubeLocales()
      const langs = langsList.split(',')

      return langs?.map((sl: string) => locales.find(l => l.id === sl.trim())?.name ?? sl) ?? []
    }

    return []
  }

  if (isLoading && !channelsWithSubtitles) {
    return <Loading />
  }

  return (
    <List
      resource="youtubeAutoEditVideos"
      title={translate('pages.automatedTreatments._title')}
      exporter={false}
      perPage={API.DEFAULT_PAGINATION.perPage}
      filter={{
        channelId: selectedChannelId ?? channelsWithSubtitles?.map(c => c.id),
        translationIgnoredReason: null,
        ...(selectedErrorType && {
          hasTranslationError: selectedErrorType?.id === 'with',
        }),
        ...(selectedPublicationDate && {
          hasPublishDate: selectedPublicationDate?.id === 'yes',
        }),
      }}
      filters={[<SearchInput key="search" source="q" alwaysOn />]}
      empty={false}
      sort={{ field: 'translatedSubtitlesOrTranslatedTitleAt NULL LAST', order: 'DESC' }}
      actions={
        <Grid
          container
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
          gap="1rem"
          paddingBottom={1}
          minWidth="45rem"
        >
          <Autocomplete<YTChannelType>
            key="channels"
            options={channelsWithSubtitles ?? []}
            value={channelsWithSubtitles?.find(c => c.id === selectedChannelId)}
            className={styles.filter}
            getOptionLabel={option => option.name}
            renderInput={(p: AutocompleteRenderInputParams) => (
              <MUITextField
                label={translate('pages.youtubeChannels._title')}
                {...p}
                key={p.id}
                style={{ marginTop: '0.5rem' }}
                variant="standard"
              />
            )}
            onChange={(_, channel) => setSelectedChannelId(channel?.id)}
          />
          <Autocomplete<PublicationDateType>
            key="publicationDate"
            options={[
              { id: 'yes', label: translate('_generics.yes') },
              { id: 'no', label: translate('_generics.no') },
            ]}
            value={selectedPublicationDate}
            className={styles.filterSmall}
            getOptionLabel={option => option.label}
            renderInput={p => (
              <MUITextField
                label={translate('_generics.published')}
                {...p}
                key={p.id}
                style={{ marginTop: '0.5rem' }}
                variant="standard"
              />
            )}
            onChange={(_, pub) => setSelectedPublicationDate(pub)}
          />
          <Autocomplete<ErrorType>
            key="errorType"
            options={[
              { id: 'with', label: translate('_generics.with') },
              { id: 'without', label: translate('_generics.without') },
            ]}
            value={selectedErrorType}
            className={styles.filterSmall}
            getOptionLabel={option => option.label}
            renderInput={p => (
              <MUITextField
                label={translate('_generics.errors')}
                {...p}
                key={p.id}
                style={{ marginTop: '0.5rem' }}
                variant="standard"
              />
            )}
            onChange={(_, error) => setSelectedErrorType(error)}
          />
          <Tooltip
            title={translate('pages.automatedTreatments.subtitlesTranslatedForVideos')}
            placement="top"
            arrow
          >
            <span>
              <Button
                label={translate('pages.automatedTreatments.translateSubtitles')}
                onClick={() => onTranslate({})}
                variant="contained"
                disabled
              >
                <Translate />
              </Button>
            </span>
          </Tooltip>
        </Grid>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <FunctionField
          source="channelId"
          label={translate('_generics.youtubeChannel')}
          sortable={false}
          render={(record: RaRecord | undefined) => {
            const ytc = channelsWithSubtitles?.find(cs => cs.id === record?.channelId)

            return <Link to={`/youtubeChannels/${ytc?.id}/show`}>{ytc?.name}</Link>
          }}
        />
        <FunctionField
          sortBy="translatedSubtitlesOrTranslatedTitleAt NULL LAST"
          label={translate('pages.automatedTreatments.titleTranslation')}
          render={(record: RaRecord | undefined) => {
            if (!record?.translatedTitleAt) {
              return <b>-</b>
            }
            const langs = getLangs(record?.translatedTitleToLanguages)

            return (
              <Stack gap={1}>
                <Stack>
                  <em>
                    <small>{translate('_generics.date')}</small>
                  </em>
                  <Tooltip
                    title={DateHelper.format(record?.translatedTitleAt, LONG_FORMAT)}
                    placement="top"
                    arrow
                  >
                    <span>{DateHelper.format(record?.translatedTitleAt)}</span>
                  </Tooltip>
                </Stack>
                <Stack>
                  <em>
                    <small>{translate('_generics.languages')}</small>
                  </em>
                  <Tooltip title={langs?.join(' - ')} placement="top" arrow>
                    <span style={{ cursor: 'default' }}>
                      {translate('pages.automatedTreatments.numberOfLanguages', {
                        smart_count: langs?.length,
                      })}
                    </span>
                  </Tooltip>
                </Stack>
              </Stack>
            )
          }}
        />
        <FunctionField
          label={translate('pages.automatedTreatments.descriptionTranslation')}
          render={(record: RaRecord | undefined) => {
            if (!record?.translatedTitleAt) {
              return <b>-</b>
            }
            const langs = getLangs(record?.translatedDescriptionToLanguages)

            return (
              <Stack gap={1}>
                <Stack>
                  <em>
                    <small>{translate('_generics.date')}</small>
                  </em>
                  <Tooltip
                    title={DateHelper.format(record?.translatedTitleAt, LONG_FORMAT)}
                    placement="top"
                    arrow
                  >
                    <span>{DateHelper.format(record?.translatedTitleAt)}</span>
                  </Tooltip>
                </Stack>
                <Stack>
                  <em>
                    <small>{translate('_generics.languages')}</small>
                  </em>
                  {langs.length > 0 ? (
                    <Tooltip title={langs?.join(' - ')} placement="top" arrow>
                      <span style={{ cursor: 'default' }}>
                        {translate('pages.automatedTreatments.numberOfLanguages', {
                          smart_count: langs?.length,
                        })}
                      </span>
                    </Tooltip>
                  ) : (
                    <b>-</b>
                  )}
                </Stack>
              </Stack>
            )
          }}
        />

        <FunctionField
          label={translate('pages.automatedTreatments.subtitlesTranslation')}
          render={(record: RaRecord | undefined) => {
            if (!record?.translatedSubtitlesAt) {
              return <b>-</b>
            }
            const langs = getLangs(record?.translatedSubtitlesToLanguages)

            return (
              <Stack gap={1}>
                <Stack>
                  <em>
                    <small>{translate('_generics.date')}</small>
                  </em>
                  <Tooltip
                    title={DateHelper.format(record?.translatedSubtitlesAt, LONG_FORMAT)}
                    placement="top"
                    arrow
                  >
                    <span>{DateHelper.format(record?.translatedSubtitlesAt)}</span>
                  </Tooltip>
                </Stack>
                <Stack>
                  <em>
                    <small>{translate('_generics.languages')}</small>
                  </em>
                  {langs.length > 0 ? (
                    <Tooltip title={langs?.join(' - ')} placement="top" arrow>
                      <span style={{ cursor: 'default' }}>
                        {translate('pages.automatedTreatments.numberOfLanguages', {
                          smart_count: langs?.length,
                        })}
                      </span>
                    </Tooltip>
                  ) : (
                    <b>-</b>
                  )}
                </Stack>
              </Stack>
            )
          }}
        />
        <FunctionField
          source="translationError"
          label={translate('pages.automatedTreatments.translationError')}
          sortable={false}
          render={(record: RaRecord | undefined) => {
            const te = record?.translationError
            const tde = record?.titleDescriptionTranslationError
            return (
              <>
                {te && (
                  <Tooltip
                    title={JSON.stringify(te?.response?.data?.error ?? te?.errors ?? te)}
                    placement="top"
                    arrow
                  >
                    <Warning color="error" />
                  </Tooltip>
                )}
                {tde && (
                  <Tooltip
                    title={JSON.stringify(tde?.response?.data?.error ?? tde?.errors ?? tde)}
                    placement="top"
                    arrow
                  >
                    <Warning color="error" />
                  </Tooltip>
                )}
              </>
            )
          }}
        />{' '}
        <FunctionField
          sortable={false}
          label={translate('_generics.links')}
          render={(record: RaRecord | undefined) =>
            record?.id.toString() && (
              <Grid container textAlign="left" width="8rem">
                <MUILink
                  href={`https://studio.youtube.com/video/${record.id}`}
                  target="_blank"
                  className={styles.link}
                >
                  <Button label={translate('pages.youtubeClaims.studioVideoUrl')} />
                </MUILink>
                <MUILink
                  href={`https://www.youtube.com/watch?v=${record.id.toString()}`}
                  target="_blank"
                  className={styles.link}
                >
                  <Button label={translate('pages.youtubeClaims.studioVideoYTUrl')}>
                    <YouTubeIcon />
                  </Button>
                </MUILink>
              </Grid>
            )
          }
        />
        <FunctionField
          sortable={false}
          render={(record: RaRecord | undefined) => (
            <Grid container wrap="nowrap">
              <Button
                label={translate('_generics.translate')}
                onClick={() =>
                  record?.id
                    ? onTranslate({
                        channelId: record?.channelId.toString(),
                        videoId: record?.id.toString(),
                      })
                    : undefined
                }
                size="small"
              >
                <Translate />
              </Button>
            </Grid>
          )}
        />
      </Datagrid>
      {jobModal && <AutomatedTreatmentJobModal {...jobModal} />}
    </List>
  )
}

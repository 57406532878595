import React from 'react'

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Button, useNotify, useRefresh, useTranslate } from 'react-admin'

import { API } from '../../helpers/Api.helper'
import styles from './AutoAssignBeneficiariesModal.module.scss'

export interface AutoAssignBeneficiariesModalProps {
  youtubeImportId: string
  isModalOpen: boolean
  setIsModalOpen: (b: boolean) => void
  onChange: () => void
}

type Preview = {
  defaultBeneficiaryId: string
  beneficiaryName: string
  defaultProductId?: string
  defaultProductName?: string
  youtubeChannelId: string
  youtubeChannelName: string
  count: number
}

export const AutoAssignBeneficiariesModal = ({
  youtubeImportId,
  isModalOpen,
  setIsModalOpen,
  onChange,
}: AutoAssignBeneficiariesModalProps): JSX.Element => {
  const notify = useNotify()
  const translate = useTranslate()
  const [isLoading, setIsLoading] = React.useState(true)
  const [previews, setPreviews] = React.useState<Preview[]>([])
  const refresh = useRefresh()

  const getPreview = React.useCallback(async () => {
    setIsLoading(true)
    const { data } = await API.get<Preview[]>(
      `/api/youtubeRawImports/${youtubeImportId}/autoAssignBeneficiariesFromYouTubeChannelsPreview`,
    )
    setPreviews(data)
    setIsLoading(false)
  }, [youtubeImportId])

  const confirmPreview = React.useCallback(async () => {
    setIsLoading(true)
    try {
      await API.post(
        `/api/youtubeRawImports/${youtubeImportId}/autoAssignBeneficiariesFromYouTubeChannels`,
        {},
      )

      refresh()
      onChange()
      setIsModalOpen(false)
      notify(
        translate(
          'pages.importsAmendment.autoAssignBeneficiariesModal.toast_autoAssignBeneficiariesSuccess',
        ),
        {
          type: 'success',
        },
      )
    } catch (e) {
      console.error(`Error - auto assign beneficiarues - ${e}`)
      notify(
        `${translate(
          'pages.importsAmendment.autoAssignBeneficiariesModal.toast_autoAssignBeneficiariesError',
        )} - ${e}`,
        { type: 'error' },
      )
    }
  }, [notify, translate, refresh, onChange, setIsModalOpen, youtubeImportId])

  React.useEffect(() => {
    if (isModalOpen) {
      setPreviews([])
      getPreview()
    }
  }, [isModalOpen, getPreview])

  return (
    <Dialog
      aria-labelledby="auto-assign-beneficiaries-modal"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <DialogTitle id="auto-assign-beneficiaries-modal">
        {translate('pages.importsAmendment.autoAssignBeneficiariesModal.title')}
      </DialogTitle>
      <DialogContent dividers>
        <div>{translate('pages.importsAmendment.autoAssignBeneficiariesModal.message')}</div>
        <div className={styles.message}>
          {isLoading ? (
            <div className={styles.loader}>
              <CircularProgress size="2rem" />
            </div>
          ) : previews.length === 0 ? (
            <div>
              <b>{translate('pages.importsAmendment.autoAssignBeneficiariesModal.noPreview')}</b>
            </div>
          ) : (
            <ul>
              {previews.map(preview => (
                <li
                  key={preview.defaultBeneficiaryId}
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      preview.defaultProductName
                        ? 'pages.importsAmendment.autoAssignBeneficiariesModal.previewRowWithproduct'
                        : 'pages.importsAmendment.autoAssignBeneficiariesModal.previewRow',
                      {
                        ...preview,
                        smart_count: preview.count,
                      },
                    ),
                  }}
                />
              ))}
            </ul>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          label={translate('_generics.cancel')}
        />
        {isLoading || previews.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={confirmPreview}
            label={translate('_generics.confirm')}
            disabled={isLoading}
          />
        ) : undefined}
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'

import {
  AutocompleteInput,
  Datagrid,
  FunctionField,
  List,
  ListProps,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  ShowButton,
  TextField,
  useTranslate,
} from 'react-admin'

import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { ListHelper } from '../../helpers/List.helper'

export const ArtworksList = (props: ListProps): JSX.Element => {
  const translate = useTranslate()

  return (
    <List
      {...props}
      exporter={false}
      perPage={API.DEFAULT_PAGINATION.perPage}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <SearchInput key="search" source="q" alwaysOn />,
        <ReferenceInput
          key="product.beneficiaryId"
          source="product.beneficiaryId"
          reference="beneficiaries"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.beneficiary')} />
        </ReferenceInput>,
        <ReferenceInput
          key="productId"
          source="productId"
          reference="products"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.product')} />
        </ReferenceInput>,
        <ReferenceInput
          source="youtubeAssetChannelId"
          reference="youtubeChannels"
          key="filterYT"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.youtubeChannel')} />
        </ReferenceInput>,
        <ReferenceInput
          source="youtubeAsset.id"
          reference="youtubeAssets"
          key="filterYTAsset"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="id" label={translate('_generics.assetId')} />
        </ReferenceInput>,
      ]}
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="name" label={translate('_generics.name')} />
        <ReferenceField
          source="productId"
          reference="products"
          label={translate('_generics.product')}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="youtubeAssetChannelId" reference="youtubeChannels" link="show">
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="createdAt"
          label={translate('_generics.createdAt')}
          render={(record: RaRecord | undefined) => (
            <span>{DateHelper.format(record?.createdAt)}</span>
          )}
        />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

import React, { useState } from 'react'

import CsvIcon from '@mui/icons-material/ListAlt'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { CircularProgress, Grid, Menu, MenuItem } from '@mui/material'
import {
  Button,
  EditButton,
  FunctionField,
  InjectedFieldProps,
  RaRecord,
  ShowButton,
  useTranslate,
} from 'react-admin'

import { BeneficiaryHelper } from './../helpers/Beneficiary.helper'

export const LXStatementsActions = (props: InjectedFieldProps): JSX.Element => {
  return (
    <FunctionField
      {...props}
      render={(record: RaRecord | undefined) => (
        <Grid container wrap="nowrap">
          <Grid item>
            <LXStatementsDownloads record={record} key={record?.id} />
          </Grid>
          <Grid item>
            <ShowButton {...props} />
            <EditButton {...props} />
          </Grid>
        </Grid>
      )}
    />
  )
}

export const LXStatementsDownloads = ({
  record,
}: { record: RaRecord | undefined }): JSX.Element => {
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>()
  const [isCSVMenuOpen, setisCSVMenuOpen] = useState(false)
  const translate = useTranslate()

  const handlePdfDownload = async (statementId: string) => {
    setIsDownloadingPdf(true)
    await BeneficiaryHelper.downloadPdf(statementId)
    setIsDownloadingPdf(false)
  }

  const handleCsvDownload = async (
    statementId: string,
    type: 'complete' | 'basic' | 'artwork-channel-csv' = 'complete',
  ) => {
    setisCSVMenuOpen(false)
    setIsDownloadingCsv(true)
    if (type === 'complete') {
      await BeneficiaryHelper.downloadCsv(statementId)
    } else if (type === 'basic') {
      await BeneficiaryHelper.downloadBasicCsv(statementId)
    } else if (type === 'artwork-channel-csv') {
      await BeneficiaryHelper.downloadArtworkChannelCsv(statementId)
    }
    setIsDownloadingCsv(false)

    handleClose()
  }

  const handleCSVDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
    setisCSVMenuOpen(true)
  }

  const handleClose = () => {
    setisCSVMenuOpen(false)
    setAnchorElement(undefined)
  }

  return (
    <>
      <Button
        label={translate('pages.statements.pdf')}
        onClick={() => handlePdfDownload(record?.id as string)}
        disabled={isDownloadingPdf}
      >
        {isDownloadingPdf ? <CircularProgress size="1rem" /> : <PictureAsPdfIcon />}
      </Button>
      <Button
        id="csv-menu-button"
        label={translate('pages.statements.csv')}
        onClick={e => handleCSVDropdownClick(e)}
        disabled={isDownloadingCsv}
        aria-controls={isCSVMenuOpen ? 'csv-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isCSVMenuOpen ? 'true' : undefined}
      >
        {isDownloadingCsv ? <CircularProgress size="1rem" /> : <CsvIcon />}
      </Button>
      <Menu
        id="csv-menu"
        aria-labelledby="csv-menu-button"
        open={isCSVMenuOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'csv-button',
        }}
      >
        <MenuItem onClick={() => handleCsvDownload(record?.id as string)}>
          {translate('_generics.standard')}
        </MenuItem>
        <MenuItem onClick={() => handleCsvDownload(record?.id as string, 'basic')}>
          {translate('_generics.basic')}
        </MenuItem>
        <MenuItem onClick={() => handleCsvDownload(record?.id as string, 'artwork-channel-csv')}>
          {translate('_generics.artworkChannel')}
        </MenuItem>
      </Menu>
    </>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Button, useRedirect, useTranslate, useUnselectAll } from 'react-admin'
import { useParams } from 'react-router-dom'

import { API } from '../../helpers/Api.helper'
import { ImportsHelper } from '../../helpers/Imports.helper'
import styles from './ImportAmendments.module.scss'
import { ImportsAmendShortsBeneficiary } from './ImportsAmendShortsBeneficiary'

const MAX_STEP = 0

export const ImportsShortsAmendments = (): JSX.Element => {
  const [step, setStep] = useState(0)
  const [untreatedResultsTotal, setUntreatedResultsTotal] = useState(-1)

  const redirect = useRedirect()
  const translate = useTranslate()

  const unselectAll = useUnselectAll('youtubeRawImportRows')
  const getUntreatedResults = ImportsHelper.useGetShortsUntreatedResults()
  const searchParams = useParams()

  const importId = useMemo(() => searchParams?.importId ?? '', [searchParams?.importId])

  const updateUntreatedResults = useCallback(async () => {
    const untreatedResults = await getUntreatedResults(importId, step)

    setUntreatedResultsTotal(untreatedResults?.data?.length ?? 0)
  }, [getUntreatedResults, importId, step])

  const onClickFinish = useCallback(async () => {
    try {
      await API.put(`/api/youtubeRawImports/${importId}`, {
        status: '120_corrected',
      })

      redirect('/youtubeRawImports')
    } catch (e) {
      console.error(`Error - Edit status for ${importId}`, e)
    }
  }, [importId, redirect])

  useEffect(() => {
    updateUntreatedResults()
  }, [updateUntreatedResults])

  const stepSettings = React.useMemo(() => {
    switch (step) {
      case 0:
        return {
          subText: translate('pages.importsAmendment.subtext_amendAssetTitle'),
        }
      default:
        return {
          subText: '',
        }
    }
  }, [step, translate])

  return (
    <Card>
      <CardContent>
        <Grid container direction="row" className={styles.container}>
          <Grid item lg={8}>
            <Typography variant="h5">
              {translate('_generics.step')} {step + 1} / {MAX_STEP + 1}
            </Typography>
          </Grid>
          <Grid item lg={4} className={styles.buttonWrapper}>
            {step > 0 && (
              <Button
                variant="outlined"
                onClick={() => {
                  setStep(step - 1)
                  unselectAll()
                }}
                label={translate('_generics.previous')}
              />
            )}
            {step < MAX_STEP ? (
              <Button
                variant="contained"
                onClick={() => {
                  setStep(step + 1)
                  unselectAll()
                }}
                label={translate('_generics.next')}
                disabled={untreatedResultsTotal !== 0}
              />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickFinish}
                label={translate('_generics.finish')}
                disabled={untreatedResultsTotal !== 0}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={styles.container}>
          <Typography variant="subtitle2">{stepSettings.subText}</Typography>
        </Grid>
        <ImportsAmendShortsBeneficiary
          youtubeRawImportId={importId}
          updateUntreatedResults={updateUntreatedResults}
        />
      </CardContent>
    </Card>
  )
}

import React, { useEffect } from 'react'

import { Grid } from '@mui/material'
import {
  ArrayField,
  AutocompleteInput,
  Datagrid,
  FunctionField,
  List,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import { LXShowPeriodicRates } from '../../components/LXShowPeriodicRates'
import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { ListHelper } from '../../helpers/List.helper'
import { LXField } from './../../components/LXField'
import { ProductArtworksType } from './../../types/Product'

export const ProductShow = (): JSX.Element => {
  const [recordName, setRecordName] = React.useState('')
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const params = useParams()

  // Get beneficiaryName for App title
  useEffect(() => {
    if (!params?.id) {
      return
    }

    dataProvider
      .getOne('products', { id: params.id as string })
      .then(res => setRecordName(res?.data.name ?? ''))
  }, [dataProvider, params?.id])

  return (
    <Show title={`${translate('pages.products._title')} / ${recordName}`}>
      <TabbedShowLayout>
        <Tab label={translate('pages.products._tabs.1')}>
          <ProductInformations />
        </Tab>
        <Tab label={translate('pages.products._tabs.2')} path="artworks">
          <ProductArtworks productId={params.id as string} />
        </Tab>
        <Tab label={translate('pages.products._tabs.3')} path="periodic-rates">
          <LXShowPeriodicRates rateable="product" rateableId={params.id as string} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const ProductInformations = () => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" spacing={2}>
      <Grid container item direction="row" lg={12} spacing={2}>
        <LXField
          source="name"
          label={translate('_generics.name')}
          elementType={TextField}
          size={6}
          labeled
        />
        <LXField
          source="name"
          label={translate('pages.products.productType')}
          elementType={TextField}
          size={6}
          wrappingFunction={el => (
            <ReferenceField source="productTypeId" reference="productTypes" link={false}>
              {el}
            </ReferenceField>
          )}
          labeled
        />
        <LXField
          source="name"
          label={translate('pages.products.beneficiary')}
          elementType={TextField}
          size={6}
          wrappingFunction={el => (
            <ReferenceField source="beneficiaryId" reference="beneficiaries" link="show">
              {el}
            </ReferenceField>
          )}
          labeled
        />
        <LXField
          source="rate"
          label={translate('pages.products.specificRate')}
          elementType={TextField}
          labeled
        />
      </Grid>
    </Grid>
  )
}

export const ProductArtworks = ({ productId }: ProductArtworksType): JSX.Element => {
  const translate = useTranslate()

  return (
    <List
      resource="artworks"
      filter={{ productId }}
      exporter={false}
      perPage={API.DEFAULT_PAGINATION.perPage}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={[
        <SearchInput key="search" source="q" alwaysOn />,
        <ReferenceInput
          source="youtubeAssetChannelId"
          reference="youtubeChannels"
          key="filterYT"
          alwaysOn
          allowEmpty={false}
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput optionText="name" label={translate('_generics.youtubeChannel')} />
        </ReferenceInput>,
      ]}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={translate('_generics.name')} />
        <ArrayField source="youtubeAssets" label={translate('_generics.youtubeChannel')}>
          <ReferenceField source="channelId" reference="youtubeChannels" link="show">
            <TextField source="name" />
          </ReferenceField>
        </ArrayField>
        <FunctionField
          source="createdAt"
          label={translate('_generics.createdAt')}
          render={(record: RaRecord | undefined) => (
            <span>{DateHelper.format(record?.createdAt)}</span>
          )}
        />
        <ShowButton />
      </Datagrid>
    </List>
  )
}

import React from 'react'

import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from 'react-admin'

import { LXNumberField } from '../../components/LXNumberField'

export const YoutubeRawImportRowsList = (props: ListProps): JSX.Element => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="adjustmentType" />
      <TextField source="assetTitleEdited" />
      <DateField source="assetLabels" />
      <TextField source="assetType" />
      <TextField source="ownedViews" />
      <DateField source="youtubeRevenueSplitReserved" />
      <DateField source="youtubeRevenueSplitPartnerSoldYoutubeServed" />
      <DateField source="youtubeRevenueSplitPartnerSoldPartnerServed" />
      <LXNumberField source="youtubeRevenueSplitAuction" format="euros" />
      <LXNumberField source="youtubeRevenueSplit" format="euros" />
      <LXNumberField source="partnerRevenueAuction" format="euros" />
      <DateField source="partnerRevenueReserved" />
      <DateField source="partnerRevenuePartnerSoldYoutubeServed" />
      <DateField source="partnerRevenuePartnerSoldPartnerServed" />
      <LXNumberField source="partnerRevenue" format="euros" />
      <LXNumberField source="partnerRevenueInEuros" format="euros" />
      <TextField source="internalVideoTitle" />
      <ReferenceField source="beneficiaryId" reference="beneficiaries">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="productId" reference="products">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="artworkId" reference="artworks">
        <TextField source="id" />
      </ReferenceField>
      <BooleanField source="internalYoutubeChannel.isOwnedByLogicomix" />
      {
        // <ReferenceField source="youtubeRawImportId" reference="youtubeRawImports"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="assetId" reference="assets"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="assetChannelId" reference="assetChannels"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="customId" reference="customs"><TextField source="id" /></ReferenceField>
        // <DateField source="createdAt" />
        // <DateField source="updatedAt" />
        // <TextField source="internalYoutubeChannel.id" />
        // <ReferenceField source="internalYoutubeChannel.youtubeChannelId" reference="internalYoutubeChannel.youtubeChannels"><TextField source="id" /></ReferenceField>
        // <TextField source="internalYoutubeChannel.name" />
        // <TextField source="internalYoutubeChannel.kind" />
        // <TextField source="internalYoutubeChannel.subscribersCount" />
        // <TextField source="internalYoutubeChannel.videosCount" />
        // <TextField source="internalYoutubeChannel.viewsCount" />
        // <TextField source="internalYoutubeChannel.cms" />
        // <TextField source="internalYoutubeChannel.thumbnailUrl" />
        // <DateField source="internalYoutubeChannel.createdAt" />
        // <DateField source="internalYoutubeChannel.updatedAt" />
        // <TextField source="beneficiary.id" />
        // <TextField source="beneficiary.name" />
        // <ReferenceField source="beneficiary.beneficiaryTypeId" reference="beneficiary.beneficiaryTypes"><TextField source="id" /></ReferenceField>
        // <TextField source="beneficiary.address" />
        // <TextField source="beneficiary.tvaNumber" />
        // <TextField source="beneficiary.siret" />
        // <TextField source="beneficiary.contactName" />
        // <TextField source="beneficiary.contactEmail" />
        // <TextField source="beneficiary.statementSettings" />
        // <TextField source="beneficiary.logoBase64" />
        // <TextField source="beneficiary.createdAt" />
        // <TextField source="beneficiary.updatedAt" />
        // <TextField source="product.id" />
        // <TextField source="product.name" />
        // <ReferenceField source="product.productTypeId" reference="product.productTypes"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="product.beneficiaryId" reference="product.beneficiaries"><TextField source="id" /></ReferenceField>
        // <TextField source="product.createdAt" />
        // <TextField source="product.updatedAt" />
        // <TextField source="artwork.id" />
        // <TextField source="artwork.name" />
        // <ReferenceField source="artwork.productId" reference="artwork.products"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="artwork.youtubeAssetId" reference="artwork.youtubeAssets"><TextField source="id" /></ReferenceField>
        // <ReferenceField source="artwork.youtubeAssetChannelId" reference="artwork.youtubeAssetChannels"><TextField source="id" /></ReferenceField>
        // <TextField source="artwork.productionYear" />
        // <TextField source="artwork.createdAt" />
        // <TextField source="artwork.updatedAt" />
      }
    </Datagrid>
  </List>
)

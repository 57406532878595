import React, { useCallback, useMemo, useRef } from 'react'

import { Grid } from '@mui/material'
import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useTranslate } from 'react-admin'

import { LXDownloadButton } from '../../components/LXDownloadButton'
import { useAnalyticsContext, useAnalyticsDispatchContext } from '../../contexts/AnalyticsContext'
import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { NumberFormatter } from '../../helpers/NumberFormatter.helper'
import { DEFAULT_PAGE_SIZE } from '../../helpers/constants'
import { AnalyticsDataResponse, AnalyticsGroupBy, MetricItem } from '../../types/Analytics'
import { AgGridLoader } from '../Imports/AgGrid/aggridLoader'

type AnalyticsTableProps = {
  data?: AnalyticsDataResponse
  metrics: MetricItem[]
  groupBy?: AnalyticsGroupBy
  exportCSV?: () => Promise<void>
}

export const AnalyticsTable = ({
  data,
  metrics,
  groupBy,
  exportCSV,
}: AnalyticsTableProps): JSX.Element | null => {
  const translate = useTranslate()
  const { selectedSeriesIdsByGroupBy } = useAnalyticsContext()
  const { setSelectedSeriesIdsByGroupBy } = useAnalyticsDispatchContext()

  const gridRef = useRef<AgGridReact>(null)

  const updateTableSelection = useCallback(() => {
    if (!groupBy) {
      return
    }

    const selectedSeriesIds = selectedSeriesIdsByGroupBy[groupBy]

    if (!selectedSeriesIds) {
      return
    }

    if (selectedSeriesIds.length > 0) {
      gridRef.current?.api?.forEachNode(node => {
        if (selectedSeriesIds.includes(node.data.groupKey)) {
          if (!node.isSelected()) {
            node.setSelected(true)
          }
        } else if (node.isSelected()) {
          node.setSelected(false)
        }
      })
    }
  }, [selectedSeriesIdsByGroupBy, groupBy])

  const rows = useMemo(() => {
    const sorted = data?.groups?.sort((a, b) => {
      const at = AnalyticsHelper.extractValueFromMetric(a.total, metrics[0].id)
      const bt = AnalyticsHelper.extractValueFromMetric(b.total, metrics[0].id)

      if (typeof at === 'undefined' && typeof bt === 'undefined') {
        return 0
      }
      if (typeof bt === 'undefined') {
        return -1
      }
      if (typeof at === 'undefined') {
        return 1
      }

      return bt - at
    })

    return sorted ?? []
  }, [data?.groups, metrics])

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'label',
        headerName: groupBy ? translate(`pages.analytics.groupBy.${groupBy}`) : '',
        resizable: true,
        checkboxSelection: true,
        flex: 1,
      },
      ...metrics.map<ColDef>(metric => ({
        field: metric.id,
        headerName: metric.name,
        resizable: true,
        sortable: true,
        sortingOrder: ['desc', 'asc'],
        flex: 1,
        valueGetter: ({ data: _data }: { data: AnalyticsDataResponse['groups'][number] }) => {
          const value = AnalyticsHelper.extractValueFromMetric(_data.total, metric.id)

          return value ?? 0
        },
        valueFormatter: ({ data: _data }: { data: AnalyticsDataResponse['groups'][number] }) => {
          const value = AnalyticsHelper.extractValueFromMetric(_data.total, metric.id)
          if (typeof value === 'undefined') {
            return ''
          }

          return NumberFormatter.formatLargeNumber(value)
        },
      })),
    ],
    [metrics, groupBy, translate],
  )

  const onRowClicked = useCallback(
    (event: RowClickedEvent<any, any>) => {
      const isSelected = event.node.isSelected()
      const groupKey = event.node.data.groupKey
      if (isSelected) {
        setSelectedSeriesIdsByGroupBy(old => {
          if (!groupBy) {
            return old
          }
          if (!old[groupBy] || old[groupBy].includes(groupKey)) {
            old[groupBy] = []
          }
          old[groupBy].push(groupKey)
        })
      } else {
        setSelectedSeriesIdsByGroupBy(old => {
          if (!groupBy) {
            return old
          }
          if (!old[groupBy]?.includes(groupKey)) {
            old[groupBy] = []
          }
          old[groupBy] = old[groupBy].filter(id => id !== groupKey)
        })
      }
    },
    [groupBy, setSelectedSeriesIdsByGroupBy],
  )

  return (
    <Grid
      container
      lg={12}
      spacing={1}
      justifyContent="center"
      style={{ margin: 'auto', width: '95%' }}
    >
      {exportCSV && (
        <Grid container lg={12} justifyContent="flex-end">
          <LXDownloadButton label={translate('pages.analytics.exportToCsv')} download={exportCSV} />
        </Grid>
      )}
      <div className="ag-theme-material" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          rowData={rows}
          ensureDomOrder
          rowModelType="clientSide"
          columnDefs={columnDefs}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onRowSelected={onRowClicked}
          onGridReady={updateTableSelection}
          onModelUpdated={updateTableSelection}
          paginationPageSize={DEFAULT_PAGE_SIZE}
          loadingOverlayComponent={AgGridLoader}
          domLayout="autoHeight"
        />
      </div>
    </Grid>
  )
}

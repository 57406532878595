import React, { useEffect, useMemo, useRef, useState } from 'react'

import { PersonAdd, Refresh } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import YouTubeIcon from '@mui/icons-material/YouTube'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { CellClassParams, ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import pLimit from 'promise-limit'
import { Button, Link as RALink, RaRecord, useGetList, useNotify, useTranslate } from 'react-admin'

import { AutoAssignBeneficiariesModal } from '../../components/AutoAssignBeneficiariesModal/AutoAssignBeneficiariesModal'
import { API } from '../../helpers/Api.helper'
import { ShortVideoForCorrection } from '../../types/ShortVideoForCorrection'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import { useAgGridBulkSelect } from './AgGrid/aggridBulkSelect'
import { AgGridLoader, useAgGridLoader } from './AgGrid/aggridLoader'
import styles from './ImportAmendmentsStep.module.scss'
import { ImportCreateBeneficiaryModal } from './ImportCreateBeneficiaryModal'

type ImportsAmendShortsBeneficiaryProps = {
  youtubeRawImportId: string
  updateUntreatedResults?: () => void
}

const UpdateBeneficiaryCellRenderer = React.memo((params: any) => {
  const translate = useTranslate()
  const notify = useNotify()

  const beneficiaryName = params.data?.beneficiaryName
  const beneficiaryId = params.data?.beneficiaryId

  const [internalValue, setInternalValue] = React.useState(() =>
    beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null,
  )

  React.useEffect(() => {
    setInternalValue(beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null)
  }, [beneficiaryId, beneficiaryName])

  const onChange = React.useCallback(
    async (_: unknown, value: any) => {
      setInternalValue(value)
      try {
        await API.put(`api/youtubeVideos/${params.data.videoId}`, {
          beneficiaryId: value?.id,
        })
        params.node?.setData({
          ...params.data,
          beneficiaryId: value?.id,
          beneficiaryName: value?.name,
        })
        params.onDidUpdate?.()
        // TODO: find how to notify from here without reloading : there is a werid bug, if we keep the notify :
        // - 1/ select a beneficiary in the autocomplete
        // - 2/ the toast is shown
        // - 3/ open another autocomplete
        // - 4/ when the toast is dismissed, the autocomplete is closed
        // notify('pages.importsAmendment.toast_editBeneficiarySuccess', { type: 'success' })
      } catch (e) {
        setInternalValue(beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null)
        notify('pages.importsAmendment.toast_editBeneficiaryError', {
          type: 'error',
        })
      }
    },
    [beneficiaryId, beneficiaryName, params.data?.id, params.node],
  )

  return useMemo(
    () => (
      <Autocomplete
        id="beneficiary-edit"
        options={params.allBeneficiaries}
        value={internalValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        accessKey="id"
        getOptionLabel={option => option.name}
        renderInput={_params => <TextField {..._params} key={_params.id} variant="standard" />}
        noOptionsText={translate('_generics.noResult')}
        onChange={onChange}
      />
    ),
    [params.allBeneficiaries, internalValue, translate, onChange],
  )
})

export const ImportsAmendShortsBeneficiary = ({
  youtubeRawImportId,
  updateUntreatedResults,
}: ImportsAmendShortsBeneficiaryProps): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAutoAssignModalOpen, setIsAutoAssignModalOpen] = useState(false)

  const gridRef = useRef<AgGridReact>(null)
  const updateUntreatedResultsRef = React.useRef(updateUntreatedResults)
  updateUntreatedResultsRef.current = updateUntreatedResults

  const youtubeChannels = useGetList<YoutubeChannel>('youtubeChannels', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })
  const beneficiaries = useGetList('beneficiaries', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const [q, setQ] = React.useState('')
  const [selectedYoutubeChannel, setSelectedYoutubeChannel] = React.useState<YoutubeChannel | null>(
    null,
  )
  const [showOnlyIncomplete, setShowOnlyIncomplete] = React.useState(false)
  const [bulkBeneficiary, setBulkBeneficiary] = React.useState<RaRecord | null>(null)

  const allowNodeToBeSelected = React.useCallback((node: any) => {
    if (
      node?.data?.beneficiaryId &&
      node?.data?.beneficiaryId === node?.data?.youtubeChannelDefaultBeneficiaryId
    ) {
      return false
    }

    return true
  }, [])

  const {
    currentPageHasEverythingSelected,
    onCheckAllChanged,
    onSelectionChanged,
    totalSelectionCount,
    selection,
    onPaginationChanged,
  } = useAgGridBulkSelect(gridRef, { allowNodeToBeSelected })

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged()
  }, [showOnlyIncomplete, selectedYoutubeChannel, q])

  React.useEffect(() => {
    onSelectionChanged()
    gridRef.current?.api?.deselectAll()
  }, [q, selectedYoutubeChannel, showOnlyIncomplete, onSelectionChanged])

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'videoId',
        headerName: 'Video ID',
        resizable: true,
        checkboxSelection: true,
        headerComponent: () => {
          return (
            <>
              <Checkbox checked={currentPageHasEverythingSelected} onChange={onCheckAllChanged} />
              <div>Video ID</div>
            </>
          )
        },
      },
      {
        field: 'youtubeChannelName',
        headerName: translate('_generics.youtubeChannel'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        width: 200,
        cellRenderer: (params: ICellRendererParams<ShortVideoForCorrection>) => {
          if (!params.data) {
            return null
          }

          return (
            <RALink
              to={`/youtubeChannels/${params.data.youtubeChannelId}`}
              target="_blank"
              rel="noreferrer"
            >
              {params.value}
            </RALink>
          )
        },
      },
      {
        field: 'videoTitle',
        headerName: translate('pages.importsAmendment.assetTitle'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        flex: 1,
        cellStyle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          paddingTop: 15,
          paddingBottom: 15,
        },
        wrapText: true,
        autoHeight: true,
        cellRenderer: (params: ICellRendererParams<ShortVideoForCorrection>) => {
          if (!params.data) {
            return null
          }

          return (
            <>
              <Link href={`https://www.youtube.com/watch?v=${params.data.videoId}`} target="_blank">
                <Button
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                    marginBottom: 0,
                    minHeight: 0,
                  }}
                >
                  <YouTubeIcon />
                </Button>
              </Link>
              {params.data.videoTitle}
              <br />
            </>
          )
        },
      },
      {
        field: 'beneficiaryId',
        headerName: translate('_generics.beneficiary'),
        suppressMenu: true,
        resizable: true,
        width: 250,
        equals: (a, b) => a?.beneficiaryId === b?.beneficiaryId,
        cellStyle: (params: CellClassParams<ShortVideoForCorrection>) => {
          const hasSpace =
            !params.data?.youtubeChannelDefaultBeneficiaryId ||
            params.data?.beneficiaryId !== params.data?.youtubeChannelDefaultBeneficiaryId

          return hasSpace
            ? {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                paddingTop: 6,
                paddingBottom: 6,
              }
            : ({} as any)
        },
        cellRenderer: (params: ICellRendererParams<ShortVideoForCorrection>) => {
          if (!params.data) {
            return null
          }
          if (
            params.data.beneficiaryId &&
            params.data.beneficiaryId === params.data.youtubeChannelDefaultBeneficiaryId
          ) {
            return (
              <RALink
                to={`/beneficiaries/${params.data.beneficiaryId}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.data.beneficiaryName}
              </RALink>
            )
          }

          return (
            <UpdateBeneficiaryCellRenderer
              {...params}
              allBeneficiaries={beneficiaries?.data ?? []}
              onDidUpdate={updateUntreatedResultsRef.current}
            />
          )
        },
      },
    ],
    [translate, currentPageHasEverythingSelected, onCheckAllChanged, beneficiaries?.data],
  )

  const [rows, setRows] = React.useState<ShortVideoForCorrection[]>([])
  const { startLoading, stopLoading } = useAgGridLoader(gridRef)

  const refreshData = React.useCallback(async () => {
    startLoading('all')
    API.get<ShortVideoForCorrection[]>(
      `/api/youtubeRawImports/${youtubeRawImportId}/shortsVideosRows`,
      {
        params: {
          q: q.trim().length > 0 ? q : undefined,
          ...(selectedYoutubeChannel ? { youtubeChannelId: selectedYoutubeChannel.id } : {}),
          ...(showOnlyIncomplete ? { beneficiaryId: 'null' } : {}),
        },
      },
    ).then(data => {
      setRows(data.data)
      gridRef.current?.api?.setRowData(data.data)
      stopLoading('all')
    })
  }, [q, selectedYoutubeChannel, showOnlyIncomplete, youtubeRawImportId])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  const onAutoAssignBeneficiariesModalChange = React.useCallback(() => {
    refreshData()
  }, [refreshData])

  const onBulkUpdate = React.useCallback(() => {
    const limit = pLimit(10)
    const beneficiaryId = bulkBeneficiary?.id
    if (!beneficiaryId) {
      return
    }
    Promise.all(
      selection.map(node =>
        limit(async () => {
          await API.put(`api/youtubeVideos/${node.data.videoId}`, {
            beneficiaryId,
          })
          node.setData({
            ...node.data,
            beneficiaryId,
            beneficiaryName: bulkBeneficiary?.name,
          })
        }),
      ),
    )
      .then(() => {
        updateUntreatedResultsRef.current?.()
        gridRef.current?.api.deselectAll()
        notify('pages.importsAmendment.toast_editBulkBeneficiarySuccess', {
          type: 'success',
        })
      })
      .catch(() => {
        notify('pages.importsAmendment.toast_editBeneficiaryError', {
          type: 'error',
        })
      })
  }, [bulkBeneficiary, notify, selection])

  return (
    <>
      <Grid container direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            setIsModalOpen(true)
          }}
          label={translate('pages.beneficiaries.addBeneficiary')}
          className={styles.button}
        >
          <PersonAdd />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            refreshData()
            updateUntreatedResultsRef.current?.()
          }}
          label={translate('_generics.refresh')}
          className={styles.button}
        >
          <Refresh />
        </Button>
      </Grid>
      <Grid
        item
        container
        lg={12}
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={2}
        style={{
          paddingLeft: 12,
          paddingTop: 12,
          paddingBottom: 12,
          marginTop: 12,
        }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-start"
          lg={6}
          spacing={2}
        >
          <Grid item lg={6}>
            <TextField
              hiddenLabel
              label=""
              fullWidth
              value={q}
              onChange={e => setQ(e.target.value)}
              placeholder={translate('ra.action.search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <Autocomplete<YoutubeChannel>
              id="artworks-list-merge"
              options={youtubeChannels?.data ?? []}
              value={selectedYoutubeChannel}
              accessKey="id"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  key={params.id}
                  placeholder={translate('_generics.youtubeChannel')}
                  variant="standard"
                />
              )}
              noOptionsText={translate('_generics.noResult')}
              onChange={(_, value) => setSelectedYoutubeChannel(value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={showOnlyIncomplete}
                onChange={(e, checked) => setShowOnlyIncomplete(checked)}
              />
            }
            label={translate('pages.importsAmendment.showOnlyMissing')}
          />
        </Grid>
      </Grid>
      {totalSelectionCount > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          lg={12}
          style={{ backgroundColor: '#e9ecf7', padding: '12px' }}
        >
          <Grid item lg={4} style={{ alignSelf: 'center' }}>
            <Typography variant="body2">
              {totalSelectionCount}{' '}
              {translate('_generics.selected', {
                smart_count: totalSelectionCount,
              })}
              <Button
                size="small"
                onClick={() => {
                  gridRef.current?.api?.deselectAll()
                }}
              >
                <ClearIcon />
              </Button>
            </Typography>
          </Grid>
          <Grid item container lg={8} spacing={2} justifyContent="flex-end">
            <Grid item lg={6}>
              <Autocomplete
                id="beneficiary-edit-bulk"
                options={beneficiaries?.data ?? []}
                value={bulkBeneficiary}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                accessKey="id"
                getOptionLabel={option => option.name}
                renderInput={_params => (
                  <TextField
                    {..._params}
                    key={_params.id}
                    placeholder={translate('_generics.beneficiary')}
                    variant="standard"
                  />
                )}
                noOptionsText={translate('_generics.noResult')}
                onChange={async (_, value) => {
                  setBulkBeneficiary(value)
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                label={translate('_generics.update')}
                onClick={onBulkUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <div className="ag-theme-material" style={{ width: '100%', height: '65vh' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          enableCellTextSelection
          ensureDomOrder
          rowModelType="clientSide"
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          onPaginationChanged={onPaginationChanged}
          loadingOverlayComponent={AgGridLoader}
        />
      </div>
      <ImportCreateBeneficiaryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <AutoAssignBeneficiariesModal
        youtubeImportId={youtubeRawImportId}
        isModalOpen={isAutoAssignModalOpen}
        setIsModalOpen={setIsAutoAssignModalOpen}
        onChange={onAutoAssignBeneficiariesModalChange}
      />
    </>
  )
}

const defaultColDef = {
  cellStyle: { paddingLeft: 8, paddingRight: 8 },
}

import React from 'react'

import { Grid } from '@mui/material'
import { useGetList, useTranslate } from 'react-admin'

import { API } from '../helpers/Api.helper'
import { DateHelper } from '../helpers/Date.helper'
import { Rate } from '../types/Rates'
import { Rateable } from './LXEditPeriodicRates'
import { LXField } from './LXField'

export interface LXShowPeriodicRatesProps {
  rateable: Rateable
  rateableId: string
}

export const LXShowPeriodicRates: React.FC<LXShowPeriodicRatesProps> = ({
  rateable,
  rateableId,
}) => {
  const translate = useTranslate()
  const { data } = useGetList<Rate>('periodicRates', {
    pagination: API.DEFAULT_PAGINATION,
    sort: { field: 'startDate', order: 'ASC' },
    filter: { rateable, rateableId },
  })

  return (
    <Grid container direction="row" spacing={1}>
      {data?.map(({ id, rate, startDate, endDate }, index) => (
        <Grid container item direction="row" lg={12} spacing={2} key={id}>
          <LXField label={`${translate('pages.beneficiaries.rate')} ${index + 1}`} labeled>
            <>{Math.round(rate * 10000) / 100}%</>
          </LXField>
          <LXField label={translate('_generics.from')} labeled>
            {DateHelper.format(startDate, 'MMM YYYY')}
          </LXField>
          {endDate && (
            <LXField label={translate('_generics.to')} labeled>
              {DateHelper.format(endDate, 'MMM YYYY')}
            </LXField>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

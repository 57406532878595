export type YoutubeRawImportType =
  | 'asset_summary'
  | 'asset_summary_adj'
  | 'red_music_asset'
  | 'red_non_music_asset'
  | 'ecommerce_paid_feature'
  | 'shorts_non_music_subscriptions_video'
  | 'shorts_non_music_ads_video'

export const YoutubeRawImportType_AllValues: YoutubeRawImportType[] = [
  'asset_summary',
  'asset_summary_adj',
  'red_music_asset',
  'red_non_music_asset',
  'ecommerce_paid_feature',
  'shorts_non_music_subscriptions_video',
  'shorts_non_music_ads_video',
]

export type YoutubeAutomaticRawImportType =
  | 'content_owner_asset_ad_revenue_summary_a1'
  | 'content_owner_music_asset_red_revenue_raw_a1'
  | 'content_owner_non_music_asset_red_revenue_raw_a1'
export const YoutubeAutomaticRawImportType_AllValues: YoutubeAutomaticRawImportType[] = [
  'content_owner_asset_ad_revenue_summary_a1',
  'content_owner_music_asset_red_revenue_raw_a1',
  'content_owner_non_music_asset_red_revenue_raw_a1',
]

export const SourceHelper = {
  SOURCES: ['Logicomix', 'Logicomix Network', 'Bump', 'Endemol'],
}

import React from 'react'

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { RaRecord, ResourceContextProvider, useRefresh, useTranslate } from 'react-admin'

import { ImportsHelper } from '../../helpers/Imports.helper'
import { Modal } from '../../types/Modal'
import { ProductCreate } from '../Products/ProductCreate'
import genericStyles from './../../generics.module.scss'

type ImportAmendProductModalProps = Modal & {
  record?: RaRecord
}

export const ImportCreateProductModal = (props: ImportAmendProductModalProps) => {
  const { isModalOpen, setIsModalOpen, record } = props
  const translate = useTranslate()
  const refresh = useRefresh()

  const updateRow = ImportsHelper.useUpdateImportRow()

  const onCreate = React.useCallback(
    async (productId?: string) => {
      if (record?.id && productId) {
        await updateRow(
          record.id as string,
          { productId },
          {
            success: translate('pages.importsAmendment.toast_addProductSuccess'),
            error: translate('pages.importsAmendment.toast_addProductError'),
          },
        )
      }
      refresh()
      setIsModalOpen(false)
    },
    [record?.id, refresh, setIsModalOpen, translate, updateRow],
  )

  const initialValues = React.useMemo(() => {
    return {
      name: record?.assetTitleEdited,
      beneficiaryId: record?.beneficiaryId,
      productTypeId: record?.['beneficiary.defaultProductTypeId'],
    }
  }, [record])

  return (
    <Dialog aria-labelledby="create-product-modal" maxWidth="md" open={isModalOpen}>
      <DialogTitle id="create-product-modal" className={genericStyles.modalTitle}>
        {translate('pages.products.addProduct')}
        <Close className={genericStyles.closeModal} onClick={() => setIsModalOpen(false)} />
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Grid item direction="row" spacing={4}>
          <ResourceContextProvider value="products">
            <ProductCreate resource="products" onCreate={onCreate} initialValues={initialValues} />
          </ResourceContextProvider>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

import millify from 'millify'

export const NumberFormatter = {
  formatEuros: (value: number | string = 0): string => {
    const formattedValue = typeof value === 'string' ? parseFloat(value) : value

    return `${formattedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`
  },

  formatLargeNumber: (value = 0): string => {
    return `${Math.round((value * 100) / 100)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`.replace(/\./g, ',')
  },

  formatRate: (value = 0): string => {
    return `${Math.round((value * 10000) / 100)} %`.replace(/\./g, ',')
  },

  formatLargeNumberWithAbbreviation: (value = 0): string => {
    return millify(value, {
      precision: 2,
      space: true,
    })
  },
}

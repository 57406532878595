import React, { useState } from 'react'

import CsvIcon from '@mui/icons-material/ListAlt'
import { CircularProgress } from '@mui/material'
import { Button } from 'react-admin'

interface LXDownloadButtonProps {
  download: () => Promise<void> | undefined
  label: string
}

export const LXDownloadButton = ({ download, label }: LXDownloadButtonProps): JSX.Element => {
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownload = async () => {
    setIsDownloading(true)
    await download()
    setIsDownloading(false)
  }

  return (
    <Button label={label} onClick={handleDownload} disabled={isDownloading}>
      {isDownloading ? <CircularProgress size="1rem" /> : <CsvIcon />}
    </Button>
  )
}

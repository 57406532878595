import { useCallback, useRef } from 'react'
import React from 'react'

import { CircularProgress } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'

export const AgGridLoader = React.memo(() => {
  const [isShown, setIsShown] = React.useState(false)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsShown(true)
    }, 200)

    return () => clearInterval(interval)
  }, [])

  if (!isShown) {
    return null
  }

  return (
    <div>
      <CircularProgress size={60} />
    </div>
  )
})
AgGridLoader.displayName = 'AgGridLoader'

export const useAgGridLoader = (_gridRef: React.RefObject<AgGridReact<any>>) => {
  const gridRef = useRef<AgGridReact<any> | null>()
  gridRef.current = _gridRef.current

  const isLoadingPageIdentifierRef = useRef<string>('')

  const startLoading = useCallback((pageIdentifier: string) => {
    isLoadingPageIdentifierRef.current = pageIdentifier
    gridRef.current?.api?.showLoadingOverlay()
  }, [])

  const stopLoading = useCallback((pageIdentifier: string) => {
    if (isLoadingPageIdentifierRef.current === pageIdentifier) {
      gridRef.current?.api?.hideOverlay()
    }
  }, [])

  return {
    startLoading,
    stopLoading,
  }
}

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { Button, useNotify, useRefresh, useTranslate } from 'react-admin'

import { LXSelectDate } from '../../components/LXSelectDate'
import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { SourceHelper, YoutubeAutomaticRawImportType_AllValues } from '../../helpers/Source.helper'
import { ImportStatus } from './ImportStatus'

const DEFAULT_IMPORT_TYPES = YoutubeAutomaticRawImportType_AllValues.reduce(
  (agg: { [key: string]: boolean }, elt) => {
    agg[elt] = true

    return agg
  },
  {},
)
const DEFAULT_SOURCES = SourceHelper.SOURCES.reduce((agg: { [key: string]: boolean }, elt) => {
  agg[elt] = true

  return agg
}, {})

export const YTImportModalFromAPI = (props: YTListActionsProps): JSX.Element => {
  const { isModalOpen, setIsModalOpen } = props
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()

  const [importTypes, setImportTypes] = React.useState<{
    [importType: string]: boolean
  }>(DEFAULT_IMPORT_TYPES)
  const [sources, setSources] = React.useState<{ [source: string]: boolean }>(DEFAULT_SOURCES)
  const [date, setDate] = useState(DateHelper.currentDate().subtract(1, 'month'))
  const [conversionRate, setConversionRate] = useState(1)
  const [isImporting, setIsImporting] = useState(false)
  const [isImportingCount, setIsImportingCount] = React.useState(0)
  const [isImportingDone, setIsImportingDone] = useState(false)
  const [importingIds, setImportingIds] = React.useState<{ id: string; name: string }[]>([])

  React.useEffect(() => {
    if (!isModalOpen) {
      setImportTypes({ ...DEFAULT_IMPORT_TYPES })
      setSources({ ...DEFAULT_SOURCES })
      setConversionRate(1)
      setDate(DateHelper.currentDate().subtract(1, 'month'))
      setIsImporting(false)
      setIsImportingCount(0)
      setIsImportingDone(false)
      setImportingIds([])
    }
  }, [isModalOpen])

  const importReport = async () => {
    setIsImporting(true)

    const keptImportTypes = Object.entries(importTypes)
      .filter(entry => entry[1])
      .map(entry => entry[0])
    const keptSources = Object.entries(sources)
      .filter(entry => entry[1])
      .map(entry => entry[0])

    const toImport = keptImportTypes.reduce(
      (agg: Array<{ importType: string; source: string }>, importType) => {
        return agg.concat(
          keptSources.map(source => ({
            importType,
            source,
          })),
        )
      },
      [],
    )

    setIsImportingCount(toImport.length)
    await Promise.all(
      toImport.map(async ({ importType, source }) => {
        try {
          // 1. New import initialization
          const importStep1 = await API.post('/api/youtubeRawImports/downloadReport', {
            youtubeReportId: importType,
            source: source,
            month: String(date.month() + 1),
            year: String(date.year()),
            conversionRateDollarToEuros: conversionRate.toString(),
          })

          setImportingIds(old => [
            ...old.concat({
              id: importStep1.data.id,
              name: `${source} - ${translate(
                `pages.youtubeRawImports.importTypesAuto.${importType}`,
              )}`,
            }),
          ])
        } catch (e: any) {
          console.error('Error - Import Report', e.response)
          let shouldBeDone = false
          setIsImportingCount(old => {
            if (old === 1) {
              shouldBeDone = true
            }

            return old - 1
          })
          notify(
            `${translate('pages.youtubeRawImports.toast_importError')} - ${
              e.response?.data?.message ?? e.response?.data
            }`,
            { type: 'error' },
          )
          if (shouldBeDone) {
            setIsImporting(false)
          }
        }
      }),
    )
  }

  const [completedImports, setCompletedImports] = React.useState<{
    [youtubeRawImportId: string]: { completed: boolean; error?: any }
  }>({})
  const onCompleteImport = useCallback(
    ({ youtubeRawImportId, error }: { youtubeRawImportId: string; error?: any }) => {
      setCompletedImports(old => {
        const newCompletedImports = { ...old }
        newCompletedImports[youtubeRawImportId] = { completed: true, error }

        return newCompletedImports
      })
    },
    [],
  )

  const isTotallyDone = React.useMemo(() => {
    return (
      isImportingCount > 0 &&
      isImportingCount === importingIds.length &&
      Object.values(completedImports).filter(({ completed }) => completed).length ===
        importingIds.length
    )
  }, [completedImports, importingIds.length, isImportingCount])

  React.useEffect(() => {
    if (isTotallyDone) {
      notify(translate('pages.youtubeRawImports.toast_importSuccess'), {
        type: 'success',
      })
      refresh()
      setIsImportingDone(true)
      setIsImporting(false)
    }
  }, [isTotallyDone])

  return (
    <Dialog aria-labelledby="youtube-import-modal" open={isModalOpen}>
      <DialogTitle id="youtube-import-modal">
        {translate('pages.youtubeRawImports.newReportFromAPI')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <Grid item lg={6}>
            <SelectImportType
              label={translate('pages.youtubeRawImports.importType')}
              importTypes={importTypes}
              setImportTypes={setImportTypes}
              disabled={isImporting}
            />
          </Grid>
          <Grid item lg={6}>
            <SelectSource
              label={translate('pages.youtubeRawImports.source')}
              sources={sources}
              setSources={setSources}
              disabled={isImporting}
            />
          </Grid>
          <Grid item lg={6}>
            <LXSelectDate
              label={translate('_generics.date')}
              date={date}
              setDate={setDate}
              datePickerProps={{
                maxDate: DateHelper.currentDate(),
              }}
              disabled={isImporting}
            />
          </Grid>
          <Grid item lg={6}>
            <InputConversionRate
              label={translate('pages.youtubeRawImports.conversionRateDollarToEuros')}
              conversionRate={conversionRate}
              setConversionRate={setConversionRate}
              disabled={isImporting}
            />
          </Grid>
        </Grid>
        {importingIds.map(elt => (
          <ImportStatus key={elt.id} youtubeRawImport={elt} onComplete={onCompleteImport} />
        ))}
      </DialogContent>
      <DialogActions>
        {isImportingDone ? (
          <Button
            onClick={() => {
              setIsModalOpen(false)
            }}
            label={translate('_generics.ok')}
          />
        ) : (
          <>
            <Button
              onClick={() => {
                setIsModalOpen(false)
              }}
              label={translate('_generics.cancel')}
              disabled={isImporting}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                importReport()
              }}
              label={translate('_generics.import')}
              disabled={
                isImporting ||
                Object.entries(importTypes).filter(elt => elt[1]).length === 0 ||
                Object.entries(sources).filter(elt => elt[1]).length === 0
              }
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

const SelectImportType: React.FC<SelectImportTypeProps> = ({
  label,
  importTypes,
  setImportTypes,
  disabled,
}) => {
  const translate = useTranslate()

  return (
    <FormControl required component="fieldset" fullWidth variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {YoutubeAutomaticRawImportType_AllValues.map(elt => (
          <FormControlLabel
            key={elt}
            control={
              <Checkbox
                checked={importTypes[elt]}
                disabled={disabled}
                onChange={(_, checked) =>
                  setImportTypes(old => {
                    old[elt] = checked

                    return { ...old }
                  })
                }
                name={elt}
              />
            }
            label={translate(`pages.youtubeRawImports.importTypesAuto.${elt}`)}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}

const SelectSource: React.FC<SelectSourceProps> = ({ label, sources, setSources, disabled }) => (
  <FormControl required component="fieldset" fullWidth variant="standard">
    <FormLabel component="legend">{label}</FormLabel>
    <FormGroup>
      {SourceHelper.SOURCES.map(elt => (
        <FormControlLabel
          key={elt}
          control={
            <Checkbox
              checked={sources[elt]}
              disabled={disabled}
              onChange={(_, checked) =>
                setSources(old => {
                  old[elt] = checked

                  return { ...old }
                })
              }
              name={elt}
            />
          }
          label={elt}
        />
      ))}
    </FormGroup>
  </FormControl>
)

const InputConversionRate = (props: InputConversionRateProps) => (
  <FormControl fullWidth>
    <InputLabel id="input-conversion-rate">{props.label}</InputLabel>
    <Input
      id="input-conversion-rate"
      type="number"
      value={props.conversionRate}
      disabled={props.disabled}
      inputProps={{ min: 0.01, step: 0.01 }}
      onChange={e => {
        props.setConversionRate(Number(e.target.value))
      }}
    />
  </FormControl>
)

type InputConversionRateProps = {
  label: string
  conversionRate: number
  setConversionRate: (cr: number) => void
  disabled?: boolean
}

type SelectImportTypeProps = {
  label: string
  importTypes: { [importType: string]: boolean }
  setImportTypes: Dispatch<SetStateAction<{ [importType: string]: boolean }>>
  disabled?: boolean
}

type SelectSourceProps = {
  label: string
  sources: { [source: string]: boolean }
  setSources: Dispatch<SetStateAction<{ [source: string]: boolean }>>
  disabled?: boolean
}

type YTListActionsProps = {
  isModalOpen: boolean
  setIsModalOpen: (b: boolean) => void
}

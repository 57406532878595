import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react'

import { Dayjs } from 'dayjs'
import { useTranslate } from 'react-admin'
import { Updater, useImmer } from 'use-immer'

import { DateHelper } from '../helpers/Date.helper'
import { CMS_LIST } from '../helpers/constants'
import { AnalyticsGroupBy, AnalyticsPreset, MetricItem, RevenueType } from '../types/Analytics'
import { Artwork } from '../types/Artwork'
import { Beneficiary } from '../types/Beneficiary'
import { Product } from '../types/Product'
import { ProductType } from '../types/ProductType'
import { YoutubeChannel } from '../types/YoutubeChannel'

type AnalyticsContextType = {
  selectedArtworks: Artwork[]
  selectedBeneficiaries: Beneficiary[]
  selectedCMSs: typeof CMS_LIST
  selectedProducts: Product[]
  selectedProductTypes: ProductType[]
  selectedRevenueTypes: RevenueType[]
  selectedYoutubeChannels: YoutubeChannel[]
  startDate: Dayjs
  endDate: Dayjs
  groupBy: AnalyticsGroupBy | undefined
  currentPreset: AnalyticsPreset | undefined
  selectedMapMetric: MetricItem
  selectedChartMetrics: MetricItem[]
  selectedSeriesIdsByGroupBy: { [groupBy: string]: string[] }
}

type AnalyticsDispatchContextType = {
  setSelectedArtworks: Dispatch<SetStateAction<Artwork[]>>
  setSelectedBeneficiaries: Dispatch<SetStateAction<Beneficiary[]>>
  setSelectedCMSs: Dispatch<SetStateAction<typeof CMS_LIST>>
  setSelectedProducts: Dispatch<SetStateAction<Product[]>>
  setSelectedProductTypes: Dispatch<SetStateAction<ProductType[]>>
  setSelectedRevenueTypes: Dispatch<SetStateAction<RevenueType[]>>
  setSelectedYoutubeChannels: Dispatch<SetStateAction<YoutubeChannel[]>>
  setStartDate: Dispatch<SetStateAction<Dayjs>>
  setEndDate: Dispatch<SetStateAction<Dayjs>>
  setGroupBy: Dispatch<SetStateAction<AnalyticsGroupBy | undefined>>
  setCurrentPreset: Dispatch<SetStateAction<AnalyticsPreset | undefined>>
  setSelectedMapMetric: Dispatch<SetStateAction<MetricItem>>
  setSelectedChartMetrics: Updater<MetricItem[]>

  resetFilters: () => void
  setSelectedSeriesIdsByGroupBy: Updater<{ [groupBy: string]: string[] }>
}

export const AnalyticsContext = createContext<AnalyticsContextType>({} as AnalyticsContextType)
export const AnalyticsDispatchContext = createContext<AnalyticsDispatchContextType>(
  {} as AnalyticsDispatchContextType,
)

export const useAnalyticsContext = (): AnalyticsContextType => useContext(AnalyticsContext)
export const useAnalyticsDispatchContext = (): AnalyticsDispatchContextType =>
  useContext(AnalyticsDispatchContext)

export const AnalyticsProvider = ({
  children,
}: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
  const translate = useTranslate()

  const [selectedArtworks, setSelectedArtworks] = useState<Artwork[]>([])
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState<Beneficiary[]>([])
  const [selectedCMSs, setSelectedCMSs] = useState<typeof CMS_LIST>([])
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
  const [selectedProductTypes, setSelectedProductTypes] = useState<ProductType[]>([])
  const [selectedRevenueTypes, setSelectedRevenueTypes] = useState<RevenueType[]>([])
  const [selectedYoutubeChannels, setSelectedYoutubeChannels] = useState<YoutubeChannel[]>([])

  const [startDate, setStartDate] = useState(DateHelper.january2022Date())
  const [endDate, setEndDate] = useState(DateHelper.currentDate())
  const [groupBy, setGroupBy] = useState<AnalyticsGroupBy | undefined>()
  const [currentPreset, setCurrentPreset] = useState<AnalyticsPreset | undefined>()
  const [selectedMapMetric, setSelectedMapMetric] = useState<MetricItem>({
    id: 'analytics.views',
    name: translate('pages.analytics.metrics.analytics.views'),
  })
  const [selectedChartMetrics, setSelectedChartMetrics] = useImmer<MetricItem[]>([
    {
      id: 'statements.revenueInEuros',
      name: translate('pages.analytics.metrics.statements.revenueInEuros'),
    },
    {
      id: 'analytics.views',
      name: translate('pages.analytics.metrics.analytics.views'),
    },
  ])
  const [selectedSeriesIdsByGroupBy, setSelectedSeriesIdsByGroupBy] = useImmer<{
    [groupBy: string]: string[]
  }>({})

  const resetFilters = useCallback(() => {
    setSelectedArtworks([])
    setSelectedBeneficiaries([])
    setSelectedCMSs([])
    setSelectedProducts([])
    setSelectedProductTypes([])
    setSelectedRevenueTypes([])
    setSelectedYoutubeChannels([])

    setStartDate(DateHelper.january2022Date())
    setEndDate(DateHelper.currentDate())
    setGroupBy(undefined)
    setCurrentPreset(undefined)
  }, [])

  return (
    <AnalyticsContext.Provider
      value={{
        selectedArtworks,
        selectedBeneficiaries,
        selectedCMSs,
        selectedProducts,
        selectedProductTypes,
        selectedRevenueTypes,
        selectedYoutubeChannels,
        startDate,
        endDate,
        groupBy,
        currentPreset,
        selectedMapMetric,
        selectedChartMetrics,
        selectedSeriesIdsByGroupBy,
      }}
    >
      <AnalyticsDispatchContext.Provider
        value={{
          setSelectedArtworks,
          setSelectedBeneficiaries,
          setSelectedCMSs,
          setSelectedProducts,
          setSelectedProductTypes,
          setSelectedRevenueTypes,
          setSelectedYoutubeChannels,
          setStartDate,
          setEndDate,
          setGroupBy,
          setCurrentPreset,
          setSelectedMapMetric,
          setSelectedChartMetrics,
          resetFilters,
          setSelectedSeriesIdsByGroupBy,
        }}
      >
        {children}
      </AnalyticsDispatchContext.Provider>
    </AnalyticsContext.Provider>
  )
}

import React, { useCallback, useState } from 'react'

import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { captureException } from '@sentry/react'
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin'
import { useImmer } from 'use-immer'

import { API } from '../../helpers/Api.helper'
import { Statement, StatementId } from '../../types/Statement'
import genericStyles from './../../generics.module.scss'
import styles from './RegenerateStatementsModal.module.scss'

type RegenerateStatementsModalProps = {
  statementIds: StatementId[]
  isOpen?: boolean
  onClose: (hasRegenerated: boolean) => void
}

type StatementValidation = {
  [id: StatementId]: boolean
}

export const RegenerateStatementsModal: React.FC<RegenerateStatementsModalProps> = ({
  statementIds,
  isOpen = false,
  onClose,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [isRegenerating, setIsRegenerating] = useState(false)
  const [statements, setStatements] = useState<Statement[]>([])
  const [statementsValidation, setStatementsValidation] = useImmer<StatementValidation>(
    statementIds.reduce(
      (acc, id) => ({
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...acc,
        [id]: false,
      }),
      {},
    ),
  )
  const [error, setError] = useState<string>()

  const closeModal = useCallback(
    (hasRegenerated: boolean) => {
      onClose(hasRegenerated)
      setError(undefined)
      setStatements([])
      setStatementsValidation({})
    },
    [onClose, setError, setStatements, setStatementsValidation],
  )

  React.useEffect(() => {
    const getStatements = async () => {
      setIsRegenerating(true)

      try {
        const selectedStatements = await dataProvider.getMany<Statement>('statements', {
          ids: statementIds,
        })
        setStatements(selectedStatements.data)
      } catch (e: any) {
        const message = e.response?.data?.message ?? e.response

        notify(`${message}`, { type: 'error' })
        console.error('Error - regenerate statement - get statements', e)
        setError(`${message} - ${e.response.data.content?.fieldError || e.response.data.content}`)
      }
    }

    if (isOpen) {
      getStatements()
    } else {
      setIsRegenerating(false)
    }
  }, [isOpen, dataProvider, statementIds, setStatements, notify])

  React.useEffect(() => {
    const regenerateStatements = async () => {
      for (let i = 0; i < statements.length; i++) {
        const { id, beneficiaryId, month, year } = statements[i]

        try {
          await API.post('/api/statements', { beneficiaryId, month, year })
          setStatementsValidation(stv => {
            stv[id] = true
          })
        } catch (e: any) {
          captureException(e)
          const message = e.response.data?.message || e.response.data
          notify(`Statement ${month}/${year} - ${message}`, { type: 'error' })
          console.error('Error - regenerate statement', e)
          setError(`${message} - ${e.response.data.content?.fieldError || e.response.data.content}`)
        }
      }
    }

    regenerateStatements()
    setIsRegenerating(false)
  }, [setIsRegenerating, statements, setStatementsValidation, notify])

  return (
    <Dialog aria-labelledby="youtube-import-modal" open={isOpen} onClose={() => closeModal(false)}>
      <DialogTitle id="youtube-import-modal">
        {translate('pages.beneficiaries.regenerateStatements')}
      </DialogTitle>
      <DialogContent dividers>
        <ul className={styles.list}>
          {statements.length > 0 &&
            statements.map(s => (
              <li key={s.id} className={styles.listItem}>
                {statementsValidation[s.id] ? (
                  <>&#9989;&nbsp;&nbsp;</>
                ) : (
                  <CircularProgress size="1rem" />
                )}
                <span className={styles.listDate}>
                  {s.month} / {s.year}
                </span>
              </li>
            ))}
        </ul>
        {error && (
          <p className={genericStyles.error}>
            <i>{translate('_generics.errorOccured')}</i>:&nbsp;
            <strong>{error}</strong>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal(true)}
          label={translate('_generics.close')}
          disabled={isRegenerating}
        />
      </DialogActions>
    </Dialog>
  )
}

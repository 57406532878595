import React, { useCallback, useEffect, useState } from 'react'

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { Button, useNotify, useRefresh, useTranslate } from 'react-admin'

import genericStyles from './../../generics.module.scss'
import { API } from './../../helpers/Api.helper'
import { Modal } from './../../types/Modal'
import styles from './AutoAssignProductsModal.module.scss'

export const AutoAssignProductsModal = ({
  isModalOpen,
  setIsModalOpen,
  importId,
  onChange,
}: AutoAssignProductsModalProps): JSX.Element => {
  const notify = useNotify()
  const translate = useTranslate()
  const refresh = useRefresh()
  const [isLoading, setIsLoading] = useState(true)
  const [previewedRows, setPreviewedRows] = useState<AutoCorrectProductPreview[]>([])

  useEffect(() => {
    if (isModalOpen) {
      const getPreview = async () => {
        const res = await API.get<AutoCorrectProductPreview[]>(
          `/api/youtubeRawImports/${importId}/autoCorrectImportPreview`,
        )
        setPreviewedRows(res.data)
      }

      setIsLoading(true)
      getPreview()
      setIsLoading(false)
    }
  }, [importId, isModalOpen])

  const confirmAutoCorrect = useCallback(async () => {
    try {
      await API.post(`/api/youtubeRawImports/${importId}/autoCorrectImport`, {})

      refresh()
      onChange()
      setIsModalOpen(false)
      notify(
        translate('pages.importsAmendment.autoAssignProductsModal.toast_autoAssignProductsSuccess'),
        {
          type: 'success',
        },
      )
    } catch (e) {
      console.error(`Error - auto correct product - ${e}`)
      notify(
        `${translate(
          'pages.importsAmendment.autoAssignProductsModal.toast_autoAssignProductsError',
        )} - ${e}`,
        {
          type: 'error',
        },
      )
    }
  }, [translate, notify, refresh, onChange, setIsModalOpen, importId])

  return (
    <Dialog aria-labelledby="autocorrect-product-modal" open={isModalOpen}>
      <DialogTitle id="autocorrect-product-modal" className={genericStyles.modalTitle}>
        {translate('pages.importsAmendment.autoAssignProductsModal.title')}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" color="textPrimary">
          {translate('pages.importsAmendment.autoAssignProductsModal.description')}
        </Typography>
        {isLoading && (
          <div className={styles.loader}>
            <CircularProgress size="2rem" />
          </div>
        )}
        <ul>
          {previewedRows.length === 0 ? (
            <li className={styles.nopreview}>
              <b>{translate('pages.importsAmendment.autoAssignProductsModal.noPreview')}</b>
            </li>
          ) : (
            previewedRows.map(row => {
              const translationKey = row.defaultProductName
                ? 'pages.importsAmendment.autoAssignProductsModal.rowWithDefaultProduct'
                : 'pages.importsAmendment.autoAssignProductsModal.rowWithoutDefaultProduct'

              return (
                <li
                  key={row.beneficiaryId}
                  dangerouslySetInnerHTML={{
                    __html: translate(translationKey, {
                      ...row,
                      smart_count: row.count,
                    }),
                  }}
                />
              )
            })
          )}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsModalOpen(false)} label={translate('_generics.cancel')} />
        {previewedRows.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              confirmAutoCorrect()
            }}
            label={translate('_generics.confirm')}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

type AutoAssignProductsModalProps = Modal & {
  importId: string
  onChange: () => void
}

type AutoCorrectProductPreview = {
  beneficiaryId: string
  beneficiaryName: string
  defaultProductId: string | undefined
  defaultProductName: string | undefined
  defaultProductTypeId: string
  defaultProductTypeName: string
  count: number
}

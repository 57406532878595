import React, { useCallback } from 'react'

import { Accept, useDropzone } from 'react-dropzone'

import styles from './LXDropzone.module.scss'

export const LXDropzone = ({
  accept,
  onDrop,
  children,
  className,
  maxFiles = 1,
}: LXDropzoneProps): JSX.Element => {
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles,
    onDrop: useCallback(
      (droppedFiles: File[]) => {
        onDrop(droppedFiles)
      },
      [onDrop],
    ),
  })

  return (
    <div className={`${styles.container} ${className || ''}`} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

type LXDropzoneProps = {
  children: JSX.Element
  onDrop: (files: File[]) => void
  accept: Accept
  maxFiles?: number
  className?: string
}

import React from 'react'

import { SxProps } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { DatePickerProps, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

type SelectDateProps = {
  label: string
  date: dayjs.Dayjs
  setDate: (d: dayjs.Dayjs) => void
  datePickerProps?: Omit<DatePickerProps<dayjs.ConfigType>, 'value' | 'onChange'>
  disabled?: boolean
}

export const DatePickerSX: SxProps = {
  '& input': {
    paddingTop: 0,
    backgroundColor: 'white',
    paddingLeft: 0,
    minWidth: '7.5rem',
  },
}

export const LXSelectDate: React.FC<SelectDateProps> = ({
  label,
  date,
  setDate,
  datePickerProps,
  disabled,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <InputLabel id="input-date-picker" style={{ fontSize: '80%' }}>
      {label}
    </InputLabel>
    <MobileDatePicker
      views={['month', 'year']}
      {...datePickerProps}
      value={date}
      disabled={disabled}
      onAccept={newDate => {
        setDate(newDate as dayjs.Dayjs)
      }}
      slotProps={{
        toolbar: { hidden: true },
      }}
      sx={DatePickerSX}
    />
  </LocalizationProvider>
)

import React from 'react'

import {
  DateField,
  Edit,
  FunctionField,
  ReferenceField,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin'

import { LXNumberField } from '../../components/LXNumberField'

export const StatementEdit: React.FC = props => {
  const translate = useTranslate()

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <ReferenceField
          source="beneficiaryId"
          reference="beneficiaries"
          link="show"
          label={translate('_generics.beneficiary')}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          source="period"
          label={`${translate('_generics.month._title')} / ${translate('_generics.year')}`}
          render={(record: any) => (
            <span>{`${record.month.toString().padStart(2, '0')} / ${record.year}`}</span>
          )}
        />
        <LXNumberField
          source="videosViews"
          format="large"
          label={translate('statements.videosViews')}
        />
        <LXNumberField
          source="youtubeGeneratedRevenue"
          format="euros"
          label={translate('statements.generatedRevenue')}
        />
        <LXNumberField
          source="ugcGeneratedRevenue"
          format="euros"
          label={translate('statements.youtubeGeneratedRevenue')}
        />
        <LXNumberField
          source="generatedRevenue"
          format="euros"
          label={translate('statements.ugcGeneratedRevenue')}
        />
        <LXNumberField
          source="appliedRate"
          format="percentRate"
          label={translate('statements.appliedRate')}
        />
        <LXNumberField
          source="netRevenue"
          format="euros"
          label={translate('pages.statements.netRevenue')}
        />
        <DateField source="createdAt" />

        <TextInput source="commentGeneric" multiline />
        <TextInput source="commentPerfSummary" multiline />
        <TextInput source="commentPerfByProductType" multiline />
        <TextInput source="commentPerfByProduct" multiline />
        <TextInput source="commentPerfByArtwork" multiline />
      </SimpleForm>
    </Edit>
  )
}

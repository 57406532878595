import React from 'react'

import { Grid } from '@mui/material'
import {
  BooleanInput,
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin'

import { LXField } from '../../components/LXField'
import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar'

export const YoutubeChannelCreate: React.FC = props => {
  const translate = useTranslate()

  return (
    <Create {...props} redirect="show">
      <SimpleForm toolbar={<NoDeleteEditToolbar />}>
        <Grid item direction="column" lg={10} spacing={2} style={{ width: '100%' }}>
          <Grid container item direction="row" lg={10} spacing={2}>
            <LXField size={6}>
              <TextInput
                source="id"
                label={translate('_generics.youtubeChannelId')}
                validate={[required()]}
                fullWidth
              />
            </LXField>
            <Grid item lg={3}>
              <SelectInput
                source="cms"
                choices={[
                  { id: 'Logicomix', name: 'Logicomix' },
                  { id: 'Logicomix Network', name: 'Logicomix Network' },
                  { id: 'BUMP', name: 'Bump' },
                  { id: 'endemol', name: 'Endemol' },
                ]}
                label={translate('pages.youtubeChannels.cms')}
                required
              />
            </Grid>
          </Grid>
          <hr style={{ width: '100%' }} />
          <Grid container item direction="row" lg={10} spacing={2}>
            <Grid item sm={12} lg={6}>
              <BooleanInput
                source="isOwnedByLogicomix"
                label={translate('pages.youtubeChannels.isOwnedByLogicomix')}
              />
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

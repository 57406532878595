import React, { useCallback, useEffect, useState } from 'react'

import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { captureException } from '@sentry/react'
import pLimit from 'promise-limit'
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin'
import { useImmer } from 'use-immer'

import { LXSelectDate } from '../../components/LXSelectDate'
import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { Beneficiary } from '../../types/Beneficiary'
import { Statement } from '../../types/Statement'
import { YoutubeRawImport } from '../../types/YoutubeRawImport'
import genericStyles from './../../generics.module.scss'
import styles from './CreateStatementModal.module.scss'

interface StatementCreationStatus {
  status: 'loading' | 'done' | 'error'
  beneficiaryId: string
  beneficiaryName: string
  month: number
  year: number
  statement?: Statement
  error?: string
}
export interface CreateAllStatementModalProps {
  isOpen?: boolean
  onClose: (created: boolean) => void
}

const limit = pLimit(4)

export const CreateAllStatementModal: React.FC<CreateAllStatementModalProps> = ({
  isOpen = false,
  onClose,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const [creating, setCreating] = React.useState(false)
  const [startDate, setStartDate] = useState(DateHelper.currentDate().subtract(2, 'month'))
  const [endDate, setEndDate] = useState(DateHelper.currentDate().subtract(1, 'month'))
  const [error, setError] = useState<string>()
  const [isAll, setIsAll] = useState(false)
  const [allBeneficiaries, setAllBeneficiaries] = useState<Beneficiary[]>([])
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState<Beneficiary[]>([])
  const dataProvider = useDataProvider()

  const [creatingStatements, updateCreatingStatements] = useImmer<{
    [beneficiaryId: string]: StatementCreationStatus
  }>({})
  const [isStatementsCreationDone, setIsStatementsCreationDone] = React.useState(false)

  const getAllBeneficiaries = useCallback(async () => {
    const response = await dataProvider.getList('beneficiaries', {
      sort: { field: 'id', order: 'ASC' },
      filter: {},
      pagination: { page: 1, perPage: 999 },
    })
    setAllBeneficiaries(response.data as Beneficiary[])
  }, [dataProvider])

  const getLatestTreatedReport = useCallback(async () => {
    const response = await dataProvider.getList<YoutubeRawImport>('youtubeRawImports', {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 1 },
      filter: {
        status: '120_completed',
      },
    })

    return response.data[0]
  }, [dataProvider])

  const updateStartDateWithReport = useCallback(async () => {
    const report = await getLatestTreatedReport()

    setStartDate(
      report ? DateHelper.toDayjs(report.period) : DateHelper.currentDate().subtract(2, 'month'),
    )
  }, [getLatestTreatedReport])

  useEffect(() => {
    if (!isOpen) {
      setStartDate(DateHelper.currentDate().subtract(2, 'month'))
      setEndDate(DateHelper.currentDate().subtract(1, 'month'))
      setCreating(false)
      setIsStatementsCreationDone(false)
      updateCreatingStatements(() => ({}))
    } else {
      getAllBeneficiaries()
    }
  }, [
    getAllBeneficiaries,
    getLatestTreatedReport,
    isOpen,
    updateCreatingStatements,
    updateStartDateWithReport,
  ])

  const closeModal = useCallback(
    (isCreated: boolean) => {
      onClose(isCreated)
      setError(undefined)
      setIsStatementsCreationDone(false)
      updateCreatingStatements(() => ({}))
    },
    [onClose, setError, updateCreatingStatements],
  )

  const getStatementsList = useCallback(() => {
    const statements: {
      beneficiaryId: Beneficiary['id']
      beneficiaryName: Beneficiary['name']
      month: number
      year: number
    }[] = []
    const beneficiaries = isAll ? allBeneficiaries : selectedBeneficiaries

    for (const b of beneficiaries) {
      // beneficiaries.forEach(b => {
      let d = startDate
      while (d.isBefore(endDate) || d.isSame(endDate)) {
        statements.push({
          beneficiaryId: b.id,
          beneficiaryName: b.name,
          month: d.get('month') + 1,
          year: d.get('year'),
        })
        d = d.add(1, 'month')
      }
    }
    // })

    return statements
  }, [allBeneficiaries, endDate, isAll, selectedBeneficiaries, startDate])

  const createAllStatements = React.useCallback(async () => {
    setCreating(true)
    setError(undefined)
    setIsStatementsCreationDone(false)
    updateCreatingStatements(() => ({}))
    try {
      const statements = getStatementsList()
      updateCreatingStatements(draft => {
        for (const st of statements) {
          draft[`${st.beneficiaryId}-${st.year}-${st.month}`] = {
            beneficiaryId: st.beneficiaryId as string,
            beneficiaryName: st.beneficiaryName,
            month: st.month,
            year: st.year,
            status: 'loading',
          }
        }
      })

      await Promise.all(
        statements.map(async ({ beneficiaryId, month, year }) => {
          await limit(async () => {
            try {
              const statement = (
                await API.post('/api/statements', {
                  beneficiaryId,
                  month,
                  year,
                })
              ).data
              updateCreatingStatements(draft => {
                draft[`${beneficiaryId}-${year}-${month}`].status = 'done'
                draft[`${beneficiaryId}-${year}-${month}`].statement = statement
              })
            } catch (err: any) {
              captureException(err)
              updateCreatingStatements(draft => {
                draft[`${beneficiaryId}-${year}-${month}`].status = 'error'
                draft[`${beneficiaryId}-${year}-${month}`].error =
                  err?.response?.data?.content?.fieldError ?? err?.response?.data?.message
              })
            }
          })
        }),
      )
      setIsStatementsCreationDone(true)
    } catch (e: any) {
      const message = e.response.data?.message || e.response.data
      captureException(e)
      notify(`${message}`, { type: 'error' })
      console.error('Error - create statement', e)
      setError(`${message} - ${e.response.data.content?.fieldError ?? e.response.data.content}`)
    }
  }, [getStatementsList, notify, updateCreatingStatements])

  const resultsDisplay = React.useMemo(() => {
    return Object.values(creatingStatements).sort((a, b) => {
      if (a.status === b.status) {
        return a.beneficiaryName.localeCompare(b.beneficiaryName)
      }
      if (a.status === 'error') {
        return -1
      }
      if (b.status === 'error') {
        return 1
      }
      if (a.status === 'loading') {
        return -1
      }

      return 1
    })
  }, [creatingStatements])

  return (
    <Dialog
      aria-labelledby="youtube-import-modal"
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={() => closeModal(false)}
    >
      <DialogTitle id="youtube-import-modal">{translate('pages.statements.create')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={6}>
          <Grid container item direction="column" lg={6}>
            <Grid item>
              <InputLabel id="beneficiaries-autocomplete" style={{ fontSize: '80%' }}>
                {translate('pages.beneficiaries._title')}
              </InputLabel>
              <Autocomplete
                id="beneficiaries-autocomplete"
                options={allBeneficiaries}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} variant="standard" />}
                onChange={(_, value) => setSelectedBeneficiaries(value)}
                disableCloseOnSelect
                multiple
                disabled={isAll || creating}
                classes={{
                  tag: genericStyles.tagRemove,
                }}
              />
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  disabled={creating}
                  control={
                    <Checkbox
                      checked={isAll}
                      size="small"
                      onChange={e => setIsAll(e.target.checked)}
                    />
                  }
                  label={translate('pages.beneficiaries.allBeneficiaries')}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container item direction="row" lg={6}>
            <Grid item lg={6}>
              <LXSelectDate
                disabled={creating}
                label={translate('_generics.startDate')}
                date={startDate}
                setDate={setStartDate}
                datePickerProps={{
                  minDate: DateHelper.january2021Date(),
                  maxDate: DateHelper.currentDate(),
                  shouldDisableDate: day => !!day && DateHelper.compare(day, endDate, 'after'),
                }}
              />
            </Grid>
            <Grid item lg={6}>
              <LXSelectDate
                disabled={creating}
                label={translate('_generics.endDate')}
                date={endDate}
                setDate={setEndDate}
                datePickerProps={{
                  minDate: DateHelper.january2021Date(),
                  maxDate: DateHelper.currentDate(),
                  shouldDisableDate: day => !!day && DateHelper.compare(day, startDate, 'before'),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {creating && (
          <div className={styles.status}>
            {!isStatementsCreationDone && <CircularProgress size="1rem" />}
            <div className="text-left">
              {resultsDisplay.map(
                ({
                  beneficiaryId,
                  beneficiaryName,
                  month,
                  year,
                  status,
                  error: berror,
                  statement,
                }) => (
                  <div key={beneficiaryId}>
                    <span
                      style={{
                        color: status === 'error' ? 'red' : status === 'done' ? 'green' : undefined,
                      }}
                    >
                      {status === 'done' ? ' ✅ ' : status === 'error' ? ' ❌ ' : ' 🔄 '}{' '}
                      {beneficiaryName} {month}/{year} {status === 'error' ? `- ${berror}` : ''}
                    </span>
                    {status === 'done' && (
                      <span>
                        {' '}
                        {Math.round(Number.parseFloat(statement?.netRevenue ?? '0'))}
                        €
                      </span>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        )}
        {error && (
          <p className={genericStyles.error}>
            <i>{translate('_generics.errorOccured')}</i>:&nbsp;
            <strong>{error}</strong>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        {!creating && (
          <>
            <Button
              onClick={() => closeModal(false)}
              label={translate('_generics.cancel')}
              disabled={creating && !error}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                createAllStatements()
              }}
              label={translate('_generics.create')}
              disabled={creating}
            />
          </>
        )}
        {isStatementsCreationDone && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              closeModal(true)
            }}
            label={translate('_generics.ok')}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

import React, { useCallback, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Typography,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Button, useNotify, useRefresh, useTranslate } from 'react-admin'

import { LXSelectDate } from '../../components/LXSelectDate'
import { YoutubeRawImportType, YoutubeRawImportType_AllValues } from '../../helpers/Source.helper'
import { LXDropzone } from './../../components/LXDropzone/LXDropzone'
import { API } from './../../helpers/Api.helper'
import { DateHelper } from './../../helpers/Date.helper'
import { SourceHelper } from './../../helpers/Source.helper'
import { ImportStatus } from './ImportStatus'
import styles from './YTImportModal.module.scss'

export const YTImportModal = (props: YTListActionsProps): JSX.Element => {
  const { isModalOpen, setIsModalOpen } = props
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()

  const [importType, setImportType] = React.useState<YoutubeRawImportType>()
  const [files, setFiles] = useState([] as File[])
  const [source, setSource] = React.useState<string>('')
  const [date, setDate] = useState(DateHelper.currentDate().subtract(1, 'month'))
  const [conversionRate, setConversionRate] = useState(1)
  const [isImporting, setIsImporting] = useState(false)
  const [importingId, setImportingId] = React.useState<string>()

  React.useEffect(() => {
    if (!isModalOpen) {
      setImportType(undefined)
      setFiles([])
      setSource('')
      setConversionRate(1)
      setDate(DateHelper.currentDate().subtract(1, 'month'))
      setIsImporting(false)
      setImportingId(undefined)
    }
  }, [isModalOpen])

  const importReport = async () => {
    if (!importType) {
      return
    }
    const formData = new FormData()
    formData.append('type', importType)
    formData.append('source', source)
    formData.append('month', String(date.month() + 1))
    formData.append('year', String(date.year()))
    formData.append('conversionRateDollarToEuros', conversionRate.toString())
    formData.append('file', files[0])
    setIsImporting(true)

    try {
      // 1. New import initialization
      const importStep1 = await API.post<FormData>('/api/youtubeRawImports', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      setImportingId(importStep1.data.id)
    } catch (e: any) {
      console.error(e.response)
      notify(
        `${translate('pages.youtubeRawImports.toast_importError')} - ${
          e.response?.data?.message || e.response?.data
        }`,
        { type: 'error' },
      )
      refresh()
      setIsImporting(false)
    }
  }

  const onCompleteImport = useCallback(
    ({ youtubeRawImportId, error }: { youtubeRawImportId: string; error?: any }) => {
      if (error) {
        notify(
          `${translate('pages.youtubeRawImports.toast_importError')} - ${
            error?.response?.data?.message ?? error?.response?.data
          }`,
          { type: 'error' },
        )
        refresh()
        setIsImporting(false)

        return
      }
      notify(translate('pages.youtubeRawImports.toast_importSuccess'), {
        type: 'success',
      })
      refresh()
      setIsModalOpen(false)
    },
    [notify, refresh, setIsModalOpen, translate],
  )

  const fileErrors = React.useMemo(() => {
    if (files.length === 0 || !source) {
      return []
    }
    const fileSource =
      {
        Logicomix: 'Logicomix',
        'Logicomix Network': 'Logicomix_Affiliate',
        Bump: 'Bump',
        Endemol: 'EndemolBeyondFR',
      }[source] ?? source

    const fileSuffix =
      {
        asset_summary: 'asset_summary',
        asset_summary_adj: 'ADJ_asset_summary',
        red_music_asset: 'red_music_rawdata_asset',
        red_non_music_asset: 'red_rawdata_asset',
        ecommerce_paid_feature: 'Ecommerce_paid_feature',
        shorts_non_music_subscriptions_video: 'monthly_shorts_non_music_subscription_video_summary',
        shorts_non_music_ads_video: 'monthly_shorts_non_music_ads_video_summary',
      }[importType ? `${importType}` : ''] ?? importType

    const errors: string[] = []
    const fileName = files[0].name

    if (fileSource) {
      const matchedSource = fileName.match(/^YouTube_([a-zA-Z0-9_]+)_M_/)
      if (!matchedSource || matchedSource.length < 1 || matchedSource[1] !== fileSource) {
        errors.push(
          ` - ${translate('pages.youtubeRawImports.fileChecks.badSource', {
            source: fileSource,
          })}`,
        )
      }
    }

    const matchedDate = fileName.match(/_M_(\d{8})_/)
    const expectedDate = `${date.year()}${(date.month() + 1).toString().padStart(2, '0')}01`
    if (!matchedDate || matchedDate.length < 1 || matchedDate[1] !== expectedDate) {
      errors.push(
        ` - ${translate('pages.youtubeRawImports.fileChecks.badDate', {
          date: expectedDate,
        })}`,
      )
    }

    if (importType) {
      const matchedFileType = fileName.match(
        new RegExp(`\\d_${fileSuffix}_v\\d-\\d\\.(csv|csv\\.gz)$`),
      )
      if (!matchedFileType) {
        errors.push(
          ` - ${translate('pages.youtubeRawImports.fileChecks.badFiletype', {
            fileType: fileSuffix,
          })}`,
        )
      }
    }

    if (errors.length > 0) {
      errors.push(
        `<br/>${translate('pages.youtubeRawImports.fileChecks.expectedFileName', {
          expectedFileName: `YouTube_${fileSource}_M_${expectedDate}_${fileSuffix}_v1-1.csv(.gz)`,
        })}`,
      )
    }

    return errors
  }, [files, source, date, importType, translate])

  return (
    <Dialog aria-labelledby="youtube-import-modal" open={isModalOpen}>
      <DialogTitle id="youtube-import-modal">
        {translate('pages.youtubeRawImports.newReport')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <Grid item lg={6}>
            <SelectImportType
              label={translate('pages.youtubeRawImports.importType')}
              importType={importType}
              setImportType={setImportType}
            />
          </Grid>
          <Grid item lg={6}>
            <SelectSource
              label={translate('pages.youtubeRawImports.source')}
              source={source}
              setSource={setSource}
            />
          </Grid>
          <Grid item lg={6}>
            <LXSelectDate
              label={translate('_generics.date')}
              date={date}
              setDate={setDate}
              datePickerProps={{
                maxDate: DateHelper.currentDate(),
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <InputConversionRate
              label={translate('pages.youtubeRawImports.conversionRateDollarToEuros')}
              conversionRate={conversionRate}
              setConversionRate={setConversionRate}
            />
          </Grid>
        </Grid>
        <LXDropzone
          className={styles.dropzone}
          accept={{ '*': ['text/csv', '.gz'] }}
          onDrop={setFiles}
        >
          {files.length > 0 ? (
            <p>{files[0].name}</p>
          ) : (
            <p>{translate('pages.youtubeRawImports.dropzonePlaceholder')}</p>
          )}
        </LXDropzone>
        {fileErrors.length > 0 && (
          <>
            <Typography variant="body1" color="textPrimary">
              {'⚠️ '}
              {translate('pages.youtubeRawImports.fileChecks.badFileName')}
            </Typography>
            <br />
            {fileErrors.map(error => (
              <Typography
                key={error}
                className={styles.error}
                variant="body1"
                color="textPrimary"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            ))}
          </>
        )}
        {isImporting && importingId && (
          <ImportStatus
            youtubeRawImport={{ id: importingId, name: '' }}
            onComplete={onCompleteImport}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          label={translate('_generics.cancel')}
          disabled={isImporting}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            importReport()
          }}
          label={translate('_generics.import')}
          disabled={isImporting || !importType || source.length === 0 || files.length === 0}
        />
      </DialogActions>
    </Dialog>
  )
}

const SelectImportType: React.FC<SelectImportTypeProps> = ({
  label,
  importType,
  setImportType,
}) => {
  const translate = useTranslate()

  return (
    <FormControl fullWidth variant="standard">
      <InputLabel id="source-select-label">{label}</InputLabel>
      <Select
        labelId="source-select-label"
        id="demo-simple-select"
        required
        label={label}
        value={importType}
        onChange={e => setImportType(e.target.value as YoutubeRawImportType)}
      >
        {YoutubeRawImportType_AllValues.map(elt => (
          <MenuItem key={elt} value={elt}>
            {translate(`pages.youtubeRawImports.importTypes.${elt}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const SelectSource: React.FC<SelectSourceProps> = ({ label, source, setSource }) => (
  <FormControl fullWidth variant="standard">
    <InputLabel id="source-select-label">{label}</InputLabel>
    <Select
      labelId="source-select-label"
      id="demo-simple-select"
      required
      label={label}
      value={source}
      onChange={e => setSource(e.target.value)}
    >
      {SourceHelper.SOURCES.map(elt => (
        <MenuItem key={elt} value={elt}>
          {elt}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const InputConversionRate = (props: InputConversionRateProps) => (
  <FormControl fullWidth>
    <InputLabel id="input-conversion-rate">{props.label}</InputLabel>
    <Input
      id="input-conversion-rate"
      type="number"
      value={props.conversionRate}
      inputProps={{ min: 0.01, step: 0.01 }}
      onChange={e => {
        props.setConversionRate(Number(e.target.value))
      }}
    />
  </FormControl>
)

type InputConversionRateProps = {
  label: string
  conversionRate: number
  setConversionRate: (cr: number) => void
}

type SelectImportTypeProps = {
  label: string
  importType?: YoutubeRawImportType
  setImportType: (s: YoutubeRawImportType) => void
}

type SelectSourceProps = {
  label: string
  source: string
  setSource: (s: string) => void
}

type YTListActionsProps = {
  isModalOpen: boolean
  setIsModalOpen: (b: boolean) => void
}

import Cookies from 'js-cookie'
import { AuthProvider } from 'react-admin'

import { API } from './../helpers/Api.helper'
import { AUTH_COOKIE, AUTH_COOKIE_OPTIONS } from './../helpers/Auth.helper'

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await API.post('/api/authSessions', {
      email: username,
      password,
    }).then(res => {
      Cookies.set(AUTH_COOKIE, res.data.jwt, AUTH_COOKIE_OPTIONS)
    })
  },
  logout: () => {
    Cookies.remove(AUTH_COOKIE)

    return Promise.resolve()
  },
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      Cookies.remove(AUTH_COOKIE)

      return Promise.reject()
    }

    return Promise.resolve()
  },
  checkAuth: () => {
    return Cookies.get(AUTH_COOKIE) ? Promise.resolve() : Promise.reject()
  },
  getPermissions: () => Promise.resolve(),
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Card, CardContent, Grid, Typography } from '@mui/material'
import { Button, useDataProvider, useRedirect, useTranslate, useUnselectAll } from 'react-admin'
import { useParams } from 'react-router-dom'

import { API } from '../../helpers/Api.helper'
import { ImportsHelper } from '../../helpers/Imports.helper'
import styles from './ImportAmendments.module.scss'
import { ImportsAmendArtwork } from './ImportsAmendArtwork'
import { ImportsAmendAssetTitle } from './ImportsAmendAssetTitle'
import { ImportsAmendBeneficiary } from './ImportsAmendBeneficiary'
import { ImportsAmendProduct } from './ImportsAmendProduct'

const MAX_STEP = 3

export const ImportsAmendments = (): JSX.Element => {
  const [importTitle, setImportTitle] = useState('')
  const [step, setStep] = useState(0)
  const [untreatedResultsTotal, setUntreatedResultsTotal] = useState<number>(-1)

  const redirect = useRedirect()
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const unselectAll = useUnselectAll('youtubeRawImportRows')
  const getUntreatedResults = ImportsHelper.useGetUntreatedResults()
  const searchParams = useParams()

  const importId = useMemo(() => searchParams?.importId ?? '', [searchParams?.importId])

  const updateUntreatedResults = useCallback(async () => {
    const untreatedResults = await getUntreatedResults(importId, step)

    setUntreatedResultsTotal(untreatedResults.total ?? -1)
  }, [getUntreatedResults, importId, step])

  const onClickFinish = useCallback(async () => {
    try {
      await API.put(`/api/youtubeRawImports/${importId}`, {
        status: '120_corrected',
      })

      redirect('/youtubeRawImports')
    } catch (e) {
      console.error(`Error - Edit status for ${importId}`, e)
    }
  }, [importId, redirect])

  // Get importName for App title
  useEffect(() => {
    if (!importId) {
      return
    }

    dataProvider
      .getOne('youtubeRawImports', { id: importId as string })
      .then(({ data }) => setImportTitle(`${data?.source} - ${data?.month}/${data?.year}`))
  }, [dataProvider, importId])

  useEffect(() => {
    updateUntreatedResults()
  }, [updateUntreatedResults])

  const stepSettings: StepSettings = React.useMemo(() => {
    switch (step) {
      case 0:
        return {
          component: (
            <ImportsAmendAssetTitle
              updateUntreatedResults={updateUntreatedResults}
              youtubeRawImportId={importId as string}
            />
          ),
          subText: translate('pages.importsAmendment.subtext_amendAssetTitle'),
        }
      case 1:
        return {
          component: (
            <ImportsAmendBeneficiary
              updateUntreatedResults={updateUntreatedResults}
              youtubeRawImportId={importId}
            />
          ),
          subText: translate('pages.importsAmendment.subtext_amendBeneficiary'),
        }
      case 2:
        return {
          component: (
            <ImportsAmendProduct
              updateUntreatedResults={updateUntreatedResults}
              youtubeRawImportId={importId}
            />
          ),
          subText: (
            <>
              {translate('pages.importsAmendment.subtext_amendProduct')}
              <br />
              {translate('pages.importsAmendment.subtext_amendProduct2')}
              <br />
            </>
          ),
        }
      case 3:
        return {
          component: (
            <ImportsAmendArtwork
              importId={importId}
              importTitle={importTitle}
              onChange={updateUntreatedResults}
            />
          ),
          subText: translate('pages.importsAmendment.subtext_amendArtworks'),
        }
      default:
        return {
          component: <></>,
          subText: '',
        }
    }
  }, [step, updateUntreatedResults, importId, translate, importTitle])

  return (
    <Card>
      <CardContent>
        <Grid container direction="row" className={styles.container}>
          <Grid item lg={8}>
            <Typography variant="h5">
              {translate('_generics.step')} {step + 1} / {MAX_STEP + 1}
            </Typography>
          </Grid>
          <Grid item lg={4} className={styles.buttonWrapper}>
            {step > 0 && (
              <Button
                variant="outlined"
                onClick={() => {
                  setStep(step - 1)
                  unselectAll()
                }}
                label={translate('_generics.previous')}
              />
            )}
            {step < MAX_STEP ? (
              <Button
                variant="contained"
                onClick={() => {
                  setStep(step + 1)
                  unselectAll()
                }}
                label={translate('_generics.next')}
                disabled={untreatedResultsTotal !== 0}
              />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={onClickFinish}
                label={translate('_generics.finish')}
                disabled={untreatedResultsTotal !== 0}
              />
            )}
          </Grid>
        </Grid>
        <Grid container className={styles.container}>
          <Typography variant="subtitle2">{stepSettings.subText}</Typography>
        </Grid>
        {stepSettings.component}
      </CardContent>
    </Card>
  )
}

type StepSettings = {
  component: JSX.Element
  subText: JSX.Element | string
  filters?: JSX.Element[]
}

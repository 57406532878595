import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { Grid, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { Toolbar, useGetList, useTranslate } from 'react-admin'
import { useLocation } from 'react-router-dom'

import { AnalyticsContext, AnalyticsDispatchContext } from '../../contexts/AnalyticsContext'
import { API } from '../../helpers/Api.helper'
import { DateHelper } from '../../helpers/Date.helper'
import { CMS_LIST } from '../../helpers/constants'
import { dataProvider } from '../../providers/dataProvider'
import { AnalyticsPreset, RevenueType } from '../../types/Analytics'
import { Artwork } from '../../types/Artwork'
import { Beneficiary } from '../../types/Beneficiary'
import { Product } from '../../types/Product'
import { ProductType } from '../../types/ProductType'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import { AnalyticsSavePreset } from './AnalyticsSavePreset'
import { AnalyticsSetDefaultPreset } from './AnalyticsSetDefaultPreset'

type AnalyticsFilterProps = {
  revenueTypes: RevenueType[]
}

export const AnalyticsActions = ({ revenueTypes }: AnalyticsFilterProps): JSX.Element => {
  const context = useContext(AnalyticsContext)
  const {
    resetFilters,
    setSelectedBeneficiaries,
    setSelectedCMSs,
    setSelectedArtworks,
    setSelectedProducts,
    setSelectedProductTypes,
    setSelectedRevenueTypes,
    setSelectedYoutubeChannels,
    setCurrentPreset,
    setGroupBy,
    setStartDate,
    setEndDate,
  } = useContext(AnalyticsDispatchContext)
  const translate = useTranslate()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const { data: presets } = useGetList<AnalyticsPreset>('datavizPresets', {
    pagination: API.DEFAULT_PAGINATION,
    sort: API.DEFAULT_SORT,
  })

  const onPresetSelect = useCallback(
    async (presetId?: AnalyticsPreset['id']) => {
      if (typeof presetId !== 'undefined') {
        const preset = presets?.find(p => p.id === presetId)
        if (preset) {
          setCurrentPreset(preset)
          const s = preset.settings
          const defaultQuery = {
            pagination: API.DEFAULT_PAGINATION,
            sort: API.DEFAULT_SORT,
          }
          const [sBeneficiaries, sProductTypes, sProducts, sArtworks, sYoutubeChannels] =
            await Promise.all([
              s.beneficiaryIds?.length
                ? dataProvider.getList<Beneficiary>('beneficiaries', {
                    filter: { id: preset.settings.beneficiaryIds },
                    ...defaultQuery,
                  })
                : undefined,
              s.productTypeIds?.length
                ? dataProvider.getList<ProductType>('productTypes', {
                    filter: { id: preset.settings.productTypeIds },
                    ...defaultQuery,
                  })
                : undefined,
              s.productIds?.length
                ? dataProvider.getList<Product>('products', {
                    filter: { id: preset.settings.productIds },
                    ...defaultQuery,
                  })
                : undefined,
              s.artworkIds?.length
                ? dataProvider.getList<Artwork>('artworks', {
                    filter: { id: preset.settings.artworkIds },
                    ...defaultQuery,
                  })
                : undefined,
              s.youtubeAssetChannelIds?.length
                ? dataProvider.getList<YoutubeChannel>('youtubeChannels', {
                    filter: { id: preset.settings.youtubeAssetChannelIds },
                    ...defaultQuery,
                  })
                : undefined,
            ])

          setSelectedBeneficiaries(sBeneficiaries?.data ?? [])
          setSelectedCMSs(CMS_LIST.filter(c => preset.settings.CMSs?.includes(c.id)))
          setSelectedProductTypes(sProductTypes?.data ?? [])
          setSelectedProducts(sProducts?.data ?? [])
          setSelectedArtworks(sArtworks?.data ?? [])
          setSelectedYoutubeChannels(sYoutubeChannels?.data ?? [])
          setSelectedRevenueTypes(
            revenueTypes.filter(r => preset.settings.revenueTypes?.includes(r.id)),
          )
          setStartDate(DateHelper.toDayjs(preset.settings.startDate))
          setEndDate(DateHelper.toDayjs(preset.settings.endDate))
          setGroupBy(preset.settings.groupBy[0])
        }
      } else {
        resetFilters()
        setCurrentPreset(undefined)
      }
    },
    [
      presets,
      resetFilters,
      revenueTypes,
      setCurrentPreset,
      setEndDate,
      setGroupBy,
      setSelectedArtworks,
      setSelectedBeneficiaries,
      setSelectedCMSs,
      setSelectedProductTypes,
      setSelectedProducts,
      setSelectedRevenueTypes,
      setSelectedYoutubeChannels,
      setStartDate,
    ],
  )

  useEffect(() => {
    if (searchParams.has('presetId')) {
      onPresetSelect(searchParams.get('presetId') || undefined)
    }

    // searchParams creates infinite loop
  }, [presets])

  return (
    <Toolbar disableGutters style={{ backgroundColor: 'transparent' }}>
      <Autocomplete<AnalyticsPreset>
        id="presets"
        options={presets ?? []}
        onChange={(_, value) => onPresetSelect(value?.id)}
        getOptionLabel={option => option.name}
        style={{ width: 300 }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={context.currentPreset ?? null}
        renderInput={params => (
          <TextField {...params} label={translate('pages.analytics.presets')} variant="filled" />
        )}
      />
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        alignContent="flex-end"
      >
        <AnalyticsSavePreset />
        <AnalyticsSetDefaultPreset />
      </Grid>
    </Toolbar>
  )
}

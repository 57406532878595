import React from 'react'

import { Button, EditButtonProps, Link, RaRecord, useRecordContext } from 'react-admin'

export const LXButton = ({ innerLabel, setPath }: LXButtonProps & EditButtonProps): JSX.Element => {
  const record = useRecordContext()

  return <Button component={Link} label={innerLabel} to={setPath(record)} />
}

type LXButtonProps = {
  setPath: (record: RaRecord) => string
  innerLabel: string
}

import { ProductCreate } from './ProductCreate'
import { ProductEdit } from './ProductEdit'
import { ProductList } from './ProductList'
import { ProductShow } from './ProductShow'

export const products = {
  create: ProductCreate,
  list: ProductList,
  show: ProductShow,
  edit: ProductEdit,
}

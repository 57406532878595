import React, { useCallback, useContext } from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Autocomplete, TextField } from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { useTranslate } from 'react-admin'

import { LXField } from '../../components/LXField'
import { DatePickerSX } from '../../components/LXSelectDate'
import { AnalyticsContext, AnalyticsDispatchContext } from '../../contexts/AnalyticsContext'
import genericStyles from '../../generics.module.scss'
import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { CMS_LIST } from '../../helpers/constants'
import { AnalyticsGroupBy, RevenueType } from '../../types/Analytics'
import { Artwork } from '../../types/Artwork'
import { Beneficiary } from '../../types/Beneficiary'
import { Product } from '../../types/Product'
import { ProductType } from '../../types/ProductType'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import styles from './Analytics.module.scss'

type AnalyticsFilterProps = {
  artworks: Artwork[]
  beneficiaries: Beneficiary[]
  products: Product[]
  productTypes: ProductType[]
  revenueTypes: RevenueType[]
  youtubeChannels: YoutubeChannel[]
}

export const AnalyticsFilter = ({
  artworks,
  beneficiaries,
  products,
  productTypes,
  revenueTypes,
  youtubeChannels,
}: AnalyticsFilterProps): JSX.Element => {
  const {
    selectedArtworks,
    selectedBeneficiaries,
    selectedCMSs,
    selectedProducts,
    selectedProductTypes,
    selectedRevenueTypes,
    selectedYoutubeChannels,
    startDate,
    endDate,
    groupBy,
  } = useContext(AnalyticsContext)
  const {
    setCurrentPreset,
    setSelectedArtworks,
    setSelectedBeneficiaries,
    setSelectedCMSs,
    setSelectedProducts,
    setSelectedProductTypes,
    setSelectedRevenueTypes,
    setSelectedYoutubeChannels,
    setStartDate,
    setEndDate,
    setGroupBy,
  } = useContext(AnalyticsDispatchContext)
  const translate = useTranslate()

  const onGroupByChange = useCallback(
    (_groupBy: AnalyticsGroupBy | 'noGroupBy') => {
      setGroupBy(_groupBy !== 'noGroupBy' ? _groupBy : undefined)
    },
    [setGroupBy],
  )

  const onFilterChange = useCallback(
    (cb: () => void) => {
      setCurrentPreset(undefined)
      cb()
    },
    [setCurrentPreset],
  )

  return (
    <>
      <div className={styles.filters}>
        <Grid container item lg={6} direction="row" spacing={1}>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-cms-select"
              options={CMS_LIST}
              value={selectedCMSs ?? []}
              onChange={(_, value) => onFilterChange(() => setSelectedCMSs(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.youtubeChannels.cms')}
                  placeholder={translate('pages.analytics.selectCMS')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-beneficiary-select"
              options={beneficiaries}
              value={selectedBeneficiaries ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedBeneficiaries(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.beneficiaries._title')}
                  placeholder={translate('pages.analytics.selectBeneficiary')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-youtube-channel-select"
              options={youtubeChannels}
              value={selectedYoutubeChannels ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedYoutubeChannels(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.youtubeChannels._title')}
                  placeholder={translate('pages.analytics.selectYoutubeChannels')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-product-types-select"
              options={productTypes}
              value={selectedProductTypes ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedProductTypes(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('_generics.productType')}
                  placeholder={translate('pages.analytics.selectProductType')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-products-select"
              options={products}
              value={selectedProducts ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedProducts(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              disabled={selectedBeneficiaries.length === 0}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.products._title')}
                  placeholder={translate('pages.analytics.selectProduct')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-artworks-select"
              options={artworks}
              value={selectedArtworks ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedArtworks(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              disabled={selectedProducts.length === 0}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.artworks._title')}
                  placeholder={translate('pages.analytics.selectArtwork')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
          <Grid item lg={12}>
            <Autocomplete
              multiple
              id="analytics-revenueTypes-select"
              options={revenueTypes}
              value={selectedRevenueTypes ?? []}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => onFilterChange(() => setSelectedRevenueTypes(value))}
              disableCloseOnSelect
              getOptionLabel={option => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={styles.placeholder}
                  label={translate('pages.analytics.revenueType')}
                  placeholder={translate('pages.analytics.selectRevenueTypes')}
                  size="small"
                  variant="outlined"
                />
              )}
              classes={{
                tag: genericStyles.tagRemove,
              }}
            />
          </Grid>
        </Grid>
        <Grid container item lg={6} justifyContent="center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item lg={4}>
              <LXField label={translate('_generics.from')} size={10} labeled>
                <MobileDatePicker
                  value={startDate}
                  onAccept={date => onFilterChange(() => setStartDate(date as dayjs.Dayjs))}
                  views={['month', 'year']}
                  slotProps={{
                    toolbar: { hidden: true },
                  }}
                  sx={DatePickerSX}
                />
              </LXField>
            </Grid>
            <Grid item lg={4}>
              <LXField label={translate('_generics.to')} size={10} labeled>
                <MobileDatePicker
                  value={endDate}
                  onAccept={date => onFilterChange(() => setEndDate(date as dayjs.Dayjs))}
                  views={['month', 'year']}
                  slotProps={{
                    toolbar: { hidden: true },
                  }}
                  sx={DatePickerSX}
                />
              </LXField>
            </Grid>
          </LocalizationProvider>
        </Grid>
      </div>
      <Grid item lg={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Typography variant="body2">{translate('_generics.groupBy')}</Typography>
          </FormLabel>
          <RadioGroup row>
            {AnalyticsHelper.GROUP_BY.map(gb => (
              <FormControlLabel
                key={gb}
                control={
                  <Radio
                    name={gb}
                    size="small"
                    checked={groupBy === gb || (groupBy === undefined && gb === 'noGroupBy')}
                    onChange={() => onFilterChange(() => onGroupByChange(gb))}
                  />
                }
                label={translate(`pages.analytics.groupBy.${gb}`)}
                disabled={gb === 'artworkId' && selectedBeneficiaries.length === 0}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  )
}

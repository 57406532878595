import React, { useContext, useMemo } from 'react'

import { Grid, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { useTranslate } from 'react-admin'

import { AnalyticsContext } from '../../contexts/AnalyticsContext'
import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { MetricItem } from '../../types/Analytics'
import analyticsStyles from './Analytics.module.scss'

type AnalyticsMetricSelectorProps = {
  metric: MetricItem
  onChange: (m: MetricItem) => void
}

export const AnalyticsMetricSelector = ({
  metric,
  onChange,
}: AnalyticsMetricSelectorProps): JSX.Element => {
  const { groupBy } = useContext(AnalyticsContext)
  const translate = useTranslate()

  const metrics = useMemo(
    () =>
      AnalyticsHelper.METRICS.map(m => ({
        id: m,
        name: translate(`pages.analytics.metrics.${m}`),
      }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.id.split('.')[0].localeCompare(b.id.split('.')[0])), // Needed to deduplicate the groupBy's sections
    [translate],
  )

  return (
    <Grid container justifyContent="center">
      <Grid item lg={4}>
        <Autocomplete
          id="analytics-metric-select"
          options={metrics}
          value={metric}
          onChange={(_, value) => onChange(value)}
          groupBy={option =>
            translate(`pages.analytics.metricsSections.${option.id.split('.')[0]}`)
          }
          getOptionLabel={option => option.name}
          getOptionDisabled={option =>
            !AnalyticsHelper.isMetricCompatibleWithGroupBy(option.id, groupBy)
          }
          renderInput={params => (
            <TextField
              {...params}
              className={analyticsStyles.placeholder}
              label={translate('pages.analytics.metric')}
              placeholder={translate('pages.analytics.selectMetric')}
              size="small"
              variant="outlined"
            />
          )}
          disableClearable
        />
      </Grid>
    </Grid>
  )
}

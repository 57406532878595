export const LOCAL_STORAGE_KEYS = {
  MENU_STATE: 'lx-menustate',
}

export const LocalStorage = {
  setItem: (itemName: string, itemValue: string | number | Record<string, unknown>): void => {
    if (typeof itemValue === 'object') {
      try {
        if (window.localStorage) {
          localStorage.setItem(itemName, JSON.stringify(itemValue))
        }
      } catch (e) {
        throw Error('localStorage is undefined')
      }
    } else {
      try {
        if (window.localStorage) {
          localStorage.setItem(itemName, itemValue.toString())
        }
      } catch (e) {
        throw Error('localStorage is undefined')
      }
    }
  },

  setItems: (itemObject: Record<string, string>): void => {
    for (const itemName in itemObject) {
      try {
        if (window.localStorage) {
          localStorage.setItem(itemName, itemObject[itemName])
        }
      } catch (e) {
        throw Error('localStorage is undefined')
      }
    }
  },

  setObject: <T>(itemName: string, itemObject: T): void => {
    try {
      if (window.localStorage) {
        localStorage.setItem(itemName, JSON.stringify(itemObject))
      }
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },

  getObject: <T>(itemName: string): T => {
    try {
      if (window.localStorage) {
        return JSON.parse(localStorage.getItem(itemName) as string)
      }

      return <T>{}
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },

  getArray: <T>(itemName: string): T[] => {
    try {
      if (window.localStorage) {
        return JSON.parse(localStorage.getItem(itemName) as string)
      }

      return <T[]>[]
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },

  getItem: (itemName: string): string | undefined => {
    try {
      if (window.localStorage) {
        return localStorage.getItem(itemName) as string | undefined
      }

      return undefined
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },

  removeItem: (itemName: string): void => {
    try {
      if (window.localStorage) {
        localStorage.removeItem(itemName)
      }
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },

  removeItems: (items: string[]): void => {
    try {
      if (window.localStorage) {
        for (const item in items) {
          localStorage.removeItem(items[item])
        }
      }
    } catch (e) {
      throw Error('localStorage is undefined')
    }
  },
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Divider, Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  Create,
  CreateProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
  useTranslate,
} from 'react-admin'

import { LXCreatePeriodicRates } from './../../components/LXCreatePeriodicRates'
import { LXField } from './../../components/LXField'
import { LXImageInput } from './../../components/LXImageInput'
import genericStyles from './../../generics.module.scss'
import { API } from './../../helpers/Api.helper'
import { BeneficiaryHelper } from './../../helpers/Beneficiary.helper'
import { ListHelper } from './../../helpers/List.helper'
import { Rate } from './../../types/Rates'

export const BeneficiaryCreate = (props: CreateProps & BeneficiaryCreateProps): JSX.Element => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const [rates, setRates] = useState<Rate[]>([BeneficiaryHelper.defaultRate()])
  const ratesRef = useRef<Rate[]>(rates)

  useEffect(() => {
    ratesRef.current = rates
  }, [rates])

  const { onCreate } = props

  const onSuccess = useCallback(
    (rateableId: string) => {
      // Save Rates
      Promise.all(
        ratesRef.current.map(({ rate, startDate, endDate }) => {
          const formattedRate = (rate >= 100 ? 100 : rate) / 100

          return API.post('/api/periodicRates', {
            rateable: 'beneficiary',
            rateableId,
            startDate: startDate.toDate().toISOString(),
            endDate: endDate?.toDate().toISOString(),
            rate: formattedRate,
          })
        }),
      ).then(() => {
        if (onCreate) {
          onCreate()
        } else {
          redirect('show', '/beneficiaries', rateableId)
        }
      })
    },
    [onCreate, redirect],
  )

  return (
    <Create
      {...props}
      mutationOptions={{
        onSuccess: (data: any) => {
          onSuccess(data.id)
        },
      }}
      redirect="false"
    >
      <SimpleForm>
        <Typography variant="h6" className={genericStyles.typo}>
          {translate('pages.beneficiaries._tabs.1')}
        </Typography>
        <BeneficiaryCreateInformations />
        <Divider className={genericStyles.divider} />
        <Typography variant="h6" className={genericStyles.typo}>
          {translate('pages.beneficiaries._tabs.3')}
        </Typography>
        <LXCreatePeriodicRates onRatesUpdate={setRates} />
      </SimpleForm>
    </Create>
  )
}

const BeneficiaryCreateInformations = () => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" rowSpacing={0} columnSpacing={6}>
      <LXImageInput
        source="logoBase64"
        label={translate('_generics.logo')}
        placeholder={translate('pages.beneficiaries.imagePlaceholder')}
        gridSize={12}
      />
      <LXField size={6}>
        <TextInput
          source="name"
          label={translate('_generics.name')}
          validate={[required()]}
          fullWidth
        />
      </LXField>
      <LXField
        source="contactFirstName"
        label={translate('pages.beneficiaries.contactFirstName')}
        elementType={TextInput}
        size={6}
      />
      <LXField
        source="contactName"
        label={translate('pages.beneficiaries.contactName')}
        elementType={TextInput}
        size={6}
      />
      <LXField source="siret" elementType={TextInput} size={6} />
      <LXField source="tvaNumber" elementType={TextInput} size={6} />
      <LXField size={6}>
        <ReferenceInput
          source="beneficiaryTypeId"
          reference="beneficiaryTypes"
          validate={[required()]}
          fullWidth
          sort={ListHelper.DEFAULT_SORT}
        >
          <AutocompleteInput
            optionText="name"
            label={translate('pages.beneficiaries.beneficiaryType')}
          />
        </ReferenceInput>
      </LXField>
      <LXField
        source="contactEmail"
        label={translate('pages.beneficiaries.contactEmail')}
        elementType={TextInput}
        size={6}
      />
      <LXField
        source="address"
        label={translate('_generics.address')}
        elementType={TextInput}
        size={12}
      />
    </Grid>
  )
}

type BeneficiaryCreateProps = {
  onCreate?: () => void
  onCancel?: () => void
}

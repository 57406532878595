import React, { useEffect, useState } from 'react'

import { CircularProgress, Menu } from '@mui/material'
import { Button } from 'react-admin'

type LXDropdownProps = {
  id: string
  children: JSX.Element | JSX.Element[]
  disabled: boolean
  isProcessing: boolean
  label: string
  icon?: JSX.Element
}

export const LXDropdown = ({
  id,
  children,
  label,
  icon,
  isProcessing,
}: LXDropdownProps): JSX.Element => {
  const [isLocallyProcessing, setIsLocallyProcessing] = useState(isProcessing)
  const [anchorElement, setAnchorElement] = useState<undefined | HTMLElement>()
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  useEffect(() => {
    setIsLocallyProcessing(isProcessing)
  }, [isProcessing])

  useEffect(() => {
    setisDropdownOpen(false)
  }, [isLocallyProcessing])

  const handleDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
    setisDropdownOpen(true)
  }

  const handleClose = () => {
    setisDropdownOpen(false)
    setAnchorElement(undefined)
  }

  return (
    <>
      <Button
        id={`${id}-button`}
        label={label}
        onClick={e => handleDropdownClick(e)}
        disabled={isLocallyProcessing}
        aria-controls={isDropdownOpen ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={isDropdownOpen ? 'true' : undefined}
      >
        {isLocallyProcessing ? <CircularProgress size="1rem" /> : icon || undefined}
      </Button>
      <Menu
        id={`${id}-menu`}
        aria-labelledby={`${id}-button`}
        open={isDropdownOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'csv-button',
        }}
      >
        {children}
      </Menu>
    </>
  )
}

import React, { useCallback, useMemo } from 'react'

import { GeoJsonLayer } from '@deck.gl/layers/typed'
import DeckGL, { DeckGLProps } from '@deck.gl/react/typed'
import { useTranslate } from 'react-admin'

import { AnalyticsHelper } from '../../helpers/Analytics.helper'
import { NumberFormatter } from '../../helpers/NumberFormatter.helper'
import { AnalyticsDataResponse, MetricItem } from '../../types/Analytics'
import countries from './world-countries.geo.json'

const INITIAL_VIEW_STATE = {
  longitude: 0,
  latitude: 37,
  zoom: 0,
  pitch: 0,
  bearing: 0,
}

type MapChartProps = {
  data?: AnalyticsDataResponse
  metric: MetricItem
}

const colorStart = [200, 200, 200]
const colorEnd = [0, 0, 255]

export const MapChart = ({ data, metric }: MapChartProps): JSX.Element | null => {
  const translate = useTranslate()
  const isGroupedByCountry = useMemo(() => {
    if (data?.groups) {
      return data.groups.some(v => v.groupBy?.countryCode && Object.keys(v.groupBy).length === 1)
    }

    return false
  }, [data])

  const layers = useMemo(() => {
    if (!isGroupedByCountry) {
      return []
    }
    const MIN = 0
    let MAX = 100
    const valuesByCountryCode = Object.values(data?.groups ?? {}).reduce<{
      [countryCode: string]: number
    }>((acc, v) => {
      const value = AnalyticsHelper.extractValueFromMetric(v.total, metric?.id)
      if (typeof value === 'undefined') {
        return acc
      }
      acc[v.groupKey] = value
      MAX = Math.max(MAX, acc[v.groupKey])

      return acc
    }, {})

    const countriesFeatures: Array<any> = countries.features.map(f => {
      const countryCode = f.properties?.wb_a2
      const value = valuesByCountryCode[countryCode ?? ''] ?? 0

      const base = 10
      const minLogValue = Math.log10(MIN + 1)
      const maxLogValue = Math.log10(MAX + 1)

      const normalizedValue = (Math.log10(value + 1) - minLogValue) / (maxLogValue - minLogValue)
      const interpolationFactor = 1 - normalizedValue ** base
      const red = Math.round(colorStart[0] * interpolationFactor + colorEnd[0] * normalizedValue)
      const green = Math.round(colorStart[1] * interpolationFactor + colorEnd[1] * normalizedValue)
      const blue = Math.round(colorStart[2] * interpolationFactor + colorEnd[2] * normalizedValue)

      return {
        ...f,
        properties: {
          ...f.properties,
          value,
          color: [red, green, blue],
        },
      }
    })

    return [
      new GeoJsonLayer({
        id: 'countries',
        data: {
          type: 'FeatureCollection',
          features: countriesFeatures,
        },
        opacity: 0.8,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 0.5,
        getLineColor: [100, 100, 100],
        getFillColor: f => f.properties?.color ?? [0, 0, 0],
        pickable: true,
      }),
    ]
  }, [isGroupedByCountry, data, metric])

  const getTooltip: DeckGLProps['getTooltip'] = useCallback(
    ({ object }: any) => {
      return (
        object && {
          html: `\
  <div><b>${object.properties.name}</b></div>
  <div>${NumberFormatter.formatLargeNumber(
    object.properties.value ?? 0,
  )} ${AnalyticsHelper.getUnitFromMetric(metric?.id, translate)}</div>
  `,
        }
      )
    },
    [metric?.id, translate],
  )

  if (!isGroupedByCountry) {
    return null
  }

  return (
    <div
      style={{
        width: '100%',
        height: '600px',
        position: 'relative',
      }}
    >
      <DeckGL
        style={{
          width: '100%',
          height: '100%',
        }}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        layers={layers}
        getTooltip={getTooltip}
      />
    </div>
  )
}

import { ResourceProps } from 'react-admin'

import { YoutubeChannelCreate } from './YoutubeChannelCreate'
import { YoutubechannelEdit } from './YoutubeChannelEdit'
import { YoutubechannelList } from './YoutubeChannelList'
import { YoutubechannelShow } from './YoutubeChannelShow'

export const youtubeChannels: Omit<ResourceProps, 'name'> = {
  create: YoutubeChannelCreate,
  list: YoutubechannelList,
  show: YoutubechannelShow,
  edit: YoutubechannelEdit,
}

import React, { useEffect, useState } from 'react'

import CsvIcon from '@mui/icons-material/ListAlt'
import { CircularProgress, Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  BooleanField,
  BulkActionProps,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  Labeled,
  List,
  NumberField,
  RaRecord,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUnselectAll,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import { LXNumberField } from '../../components/LXNumberField'
import { LXShowPeriodicRates } from '../../components/LXShowPeriodicRates'
import { LXStatementsActions } from '../../components/LXStatementsActions'
import { BeneficiaryHelper } from '../../helpers/Beneficiary.helper'
import { NumberFormatter } from '../../helpers/NumberFormatter.helper'
import { CMS_LIST } from '../../helpers/constants'
import { CreateStatementModal } from '../Statements/CreateStatementModal'
import { RegenerateStatementsModal } from '../Statements/RegenerateStatementsModal'
import { StatementDownloadsModal } from '../Statements/StatementDownloadsModal'
import { LXField } from './../../components/LXField'
import { LXImage } from './../../components/LXImage/LXImage'
import { API } from './../../helpers/Api.helper'
import { ListHelper } from './../../helpers/List.helper'
import { BeneficiarySubType } from './../../types/Beneficiary'
import { StatementId } from './../../types/Statement'
import styles from './Beneficiary.module.scss'

export const BeneficiaryShow = (): JSX.Element => {
  const [beneficiaryName, setBeneficiaryName] = useState('')
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const params = useParams()

  // Get beneficiaryName for App title
  useEffect(() => {
    if (!params.id) {
      return
    }

    dataProvider
      .getOne('beneficiaries', { id: params.id as string })
      .then(res => setBeneficiaryName(res?.data.name ?? ''))
  }, [dataProvider, params?.id])

  return (
    <Show
      className={styles.edit}
      title={`${translate('pages.beneficiaries._title')} / ${beneficiaryName}`}
    >
      <TabbedShowLayout>
        <Tab label={translate('pages.beneficiaries._tabs.1')} contentClassName={styles.tab}>
          <BeneficiaryInformations />
        </Tab>
        <Tab
          label={translate('pages.beneficiaries._tabs.2')}
          path="products"
          contentClassName={styles.tab}
        >
          <BeneficiaryProducts beneficiaryId={params.id as string} />
        </Tab>
        <Tab
          label={translate('pages.beneficiaries._tabs.3')}
          path="periodic-rates"
          contentClassName={styles.tab}
        >
          <LXShowPeriodicRates rateable="beneficiary" rateableId={params.id as string} />
        </Tab>
        <Tab
          label={translate('pages.beneficiaries._tabs.4')}
          path="statements"
          contentClassName={styles.tab}
        >
          <BeneficiaryStatements
            beneficiaryId={params.id as string}
            beneficiaryName={beneficiaryName}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const BeneficiaryInformations = () => {
  const translate = useTranslate()
  const record = useRecordContext()

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item lg={2}>
        <LXImage source="logoBase64" gridSize={12} />
      </Grid>
      <Grid container item direction="row" lg={10} spacing={2}>
        <LXField
          source="name"
          label={translate('_generics.name')}
          elementType={TextField}
          labeled
        />
        <Grid item lg={3}>
          <Labeled>
            <FunctionField
              source="cms"
              label={translate('pages.youtubeChannels.cms')}
              render={(record: RaRecord | undefined) =>
                CMS_LIST.find(cms => cms.id === record?.cms)?.name ?? record?.cms
              }
            />
          </Labeled>
        </Grid>
        <LXField source="siret" elementType={NumberField} labeled />
        <LXField source="tvaNumber" elementType={NumberField} labeled />
        <LXField
          source="name"
          label={translate('pages.beneficiaries.beneficiaryType')}
          elementType={TextField}
          wrappingFunction={el => (
            <ReferenceField source="beneficiaryTypeId" reference="beneficiaryTypes" link={false}>
              {el}
            </ReferenceField>
          )}
          labeled
        />
        <LXField
          source="contactEmail"
          label={translate('pages.beneficiaries.contactEmail')}
          elementType={TextField}
          labeled
        />
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={2} />
      <Grid container item direction="row" lg={10} spacing={2}>
        <LXField
          source="dearPoliteForm"
          label={translate('pages.beneficiaries.dearPolite.title')}
          elementType={TextField}
          labeled
        />
        <LXField
          source="contactFirstName"
          label={translate('pages.beneficiaries.contactFirstName')}
          elementType={TextField}
          labeled
        />
        <LXField
          source="contactName"
          label={translate('pages.beneficiaries.contactName')}
          elementType={TextField}
          labeled
        />
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={2} />
      <Grid container item direction="row" lg={10} spacing={2}>
        <LXField
          source="address"
          label={translate('_generics.address')}
          elementType={TextField}
          labeled
        />
        <LXField
          source="address2"
          label={translate('_generics.address2')}
          elementType={TextField}
          labeled
        />
      </Grid>
      <Grid item container direction="row" className={styles.edit} lg={2} />
      <Grid container item direction="row" lg={10} spacing={2}>
        <LXField
          source="isAutoCorrected"
          label={translate('pages.beneficiaries.isAutoCorrected')}
          elementType={BooleanField}
          labeled
        />
        <LXField
          source="name"
          label={translate('pages.beneficiaries.defaultDatavizPreset')}
          elementType={TextField}
          size={6}
          wrappingFunction={el => (
            <ReferenceField
              source="defaultDatavizPresetId"
              reference="datavizPresets"
              link={(rec: any) => `/analytics/?presetId=${rec.defaultDatavizPresetId}`}
            >
              {el}
            </ReferenceField>
          )}
          labeled
        />
        {record.isAutoCorrected && (
          <>
            <LXField
              source="name"
              label={translate('pages.beneficiaries.defaultProductId')}
              elementType={TextField}
              size={6}
              wrappingFunction={el => (
                <ReferenceField source="defaultProductId" reference="products" link="show">
                  {el}
                </ReferenceField>
              )}
              labeled
            />
            <LXField
              source="name"
              label={translate('pages.beneficiaries.defaultProductTypeId')}
              elementType={TextField}
              size={6}
              wrappingFunction={el => (
                <ReferenceField source="defaultProductTypeId" reference="productTypes" link={false}>
                  {el}
                </ReferenceField>
              )}
              labeled
            />
          </>
        )}
      </Grid>
    </Grid>
  )
}

const BeneficiaryProducts = ({ beneficiaryId }: { beneficiaryId: string }): JSX.Element => {
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
  const translate = useTranslate()

  const handleCsvDownload = async (id: string) => {
    setIsDownloadingCsv(true)
    await BeneficiaryHelper.downloadBeneficiaryCatalogCsv(id)
    setIsDownloadingCsv(false)
  }

  const handleAssetsCsvDownload = async (id: string) => {
    setIsDownloadingCsv(true)
    await BeneficiaryHelper.downloadBeneficiaryAssetsCsv(id)
    setIsDownloadingCsv(false)
  }

  return (
    <>
      <Grid lg={12} container>
        <Grid
          lg={12}
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          <Button
            label={translate('pages.beneficiaries.downloadCatalogCsv')}
            onClick={() => handleCsvDownload(beneficiaryId)}
            disabled={isDownloadingCsv}
          >
            {isDownloadingCsv ? <CircularProgress size="1rem" /> : <CsvIcon />}
          </Button>
          <Button
            label={translate('pages.beneficiaries.downloadAssetsCsv')}
            onClick={() => handleAssetsCsvDownload(beneficiaryId)}
            disabled={isDownloadingCsv}
          >
            {isDownloadingCsv ? <CircularProgress size="1rem" /> : <CsvIcon />}
          </Button>
        </Grid>
      </Grid>
      <List
        resource="products"
        filter={{ beneficiaryId }}
        exporter={false}
        perPage={API.DEFAULT_PAGINATION.perPage}
        filters={[
          <SearchInput key="search" source="q" alwaysOn />,
          <ReferenceInput
            key="productType"
            source="productTypeId"
            reference="productTypes"
            alwaysOn
            allowEmpty={false}
            sort={ListHelper.DEFAULT_SORT}
          >
            <AutocompleteInput optionText="name" label={translate('_generics.productType')} />
          </ReferenceInput>,
        ]}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" label={translate('_generics.name')} />
          <ReferenceField
            source="productTypeId"
            reference="productTypes"
            label={translate('pages.products.productType')}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="beneficiaryId"
            reference="beneficiaries"
            label={translate('_generics.beneficiary')}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ShowButton />
        </Datagrid>
      </List>
    </>
  )
}

const BeneficiaryStatementsBulkActionButtons = (props: Pick<BulkActionProps, 'selectedIds'>) => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll('statements')

  const { selectedIds } = props
  const [isRegenerateStatementModalOpen, setIsRegenerateStatementModalOpen] = React.useState(false)
  const onCloseRegenerateStatementModal = React.useCallback(
    (hasRegenerated: boolean) => {
      if (hasRegenerated) {
        unselectAll()
        refresh()
        notify(translate('pages.statements.regenerated'), { type: 'success' })
      }

      setIsRegenerateStatementModalOpen(false)
    },
    [unselectAll, notify, translate, refresh],
  )

  React.useEffect(
    () => () => {
      unselectAll()
    },
    [unselectAll],
  )

  return (
    <>
      <Button
        label={translate('pages.beneficiaries.regenerateStatements')}
        onClick={() => setIsRegenerateStatementModalOpen(true)}
      />
      <RegenerateStatementsModal
        statementIds={selectedIds as StatementId[]}
        isOpen={isRegenerateStatementModalOpen}
        onClose={onCloseRegenerateStatementModal}
      />
    </>
  )
}

const BeneficiaryStatements = ({
  beneficiaryId,
  beneficiaryName,
}: BeneficiarySubType): JSX.Element => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const [isCreateStatementModalOpen, setIsCreateStatementModalOpen] = React.useState(false)
  const [isStatementDownloadsModalOpen, setIsStatementDownloadsModalOpen] = React.useState(false)
  const onCloseCreateStatementModalOpen = React.useCallback(
    (created: boolean) => {
      if (created) {
        refresh()
        notify(translate('pages.statements.created'), { type: 'success' })
      }

      setIsCreateStatementModalOpen(false)
    },
    [notify, translate, refresh],
  )

  return (
    <>
      <Grid container direction="row" className={styles.edit} lg={12}>
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.introduction"
          label={translate('pages.beneficiaries.statementSettings.introduction')}
          size={2}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.globalInformations"
          label={translate('pages.beneficiaries.statementSettings.globalInformation')}
          size={2}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.firstPageLXGreetings"
          label={translate('pages.beneficiaries.statementSettings.firstPageLXGreetings')}
          size={2}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.products"
          label={translate('pages.beneficiaries.statementSettings.products')}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.productTypes"
          label={translate('pages.beneficiaries.statementSettings.productTypes')}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.artworks"
          label={translate('pages.beneficiaries.statementSettings.artworks')}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.shorts"
          label={translate('pages.beneficiaries.statementSettings.shorts')}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.monthlyComparison"
          label={translate('pages.beneficiaries.statementSettings.monthlyComparison')}
        />
        <LXField
          elementType={BooleanField}
          labeled
          source="statementSettings.yearlyComparison"
          label={translate('pages.beneficiaries.statementSettings.yearlyComparison')}
        />
        <Grid item className={styles.newStatement}>
          <Grid container direction="column" alignItems="flex-end">
            <Button
              label={translate('pages.beneficiaries.createStatement')}
              onClick={() => setIsCreateStatementModalOpen(true)}
            />
            <Button
              label={translate('pages.statements.periodicStatementDownloads')}
              onClick={() => setIsStatementDownloadsModalOpen(true)}
            >
              <CsvIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {beneficiaryId && (
        <List
          resource="statements"
          filter={{ beneficiaryId }}
          perPage={API.DEFAULT_PAGINATION.perPage}
          exporter={false}
          sort={{ field: 'period', order: 'DESC' }}
          disableSyncWithLocation
        >
          <Datagrid bulkActionButtons={<BeneficiaryStatementsBulkActionButtons />}>
            <FunctionField
              source="period"
              label={`${translate('_generics.month._title')} / ${translate('_generics.year')}`}
              render={(record: RaRecord | undefined) => (
                <span>{`${record?.month.toString().padStart(2, '0')} / ${record?.year}`}</span>
              )}
            />
            <LXNumberField source="videosViews" format="large" />
            <LXNumberField source="youtubeGeneratedRevenue" format="euros" />
            <LXNumberField source="ugcGeneratedRevenue" format="euros" />
            <LXNumberField source="generatedRevenue" format="euros" />
            <LXNumberField source="appliedRate" format="percentRate" />
            <LXNumberField
              source="netRevenue"
              format="euros"
              label={translate('pages.statements.netRevenue')}
            />
            <FunctionField
              source="adjustmentRows"
              label={translate('pages.statements.adjustment')}
              render={(record: any) => (
                <div className={styles.numberChipContainer}>
                  {record?.adjustmentRows?.map((row: any) => (
                    <Typography
                      key={row.id}
                      component={'div'}
                      variant="body2"
                      className={styles.numberChip}
                    >
                      {NumberFormatter.formatEuros(row.amountInEuros)}
                    </Typography>
                  ))}
                </div>
              )}
            />
            <DateField source="createdAt" />
            <LXStatementsActions />
          </Datagrid>
        </List>
      )}
      <CreateStatementModal
        beneficiaryId={beneficiaryId}
        isOpen={isCreateStatementModalOpen}
        onClose={onCloseCreateStatementModalOpen}
      />
      <StatementDownloadsModal
        beneficiaryId={beneficiaryId}
        isOpen={isStatementDownloadsModalOpen}
        onClose={() => setIsStatementDownloadsModalOpen(false)}
        beneficiaryName={beneficiaryName}
      />
    </>
  )
}

import React, { useEffect, useMemo, useRef, useState } from 'react'

import { PersonAdd, Refresh, Star } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import { ColDef, ICellRendererParams, IGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import pLimit from 'promise-limit'
import {
  Button,
  Link as RALink,
  RaRecord,
  SortPayload,
  useGetList,
  useNotify,
  useTranslate,
} from 'react-admin'

import { AutoAssignBeneficiariesModal } from '../../components/AutoAssignBeneficiariesModal/AutoAssignBeneficiariesModal'
import { LXPageSize } from '../../components/LXPageSize/LXPageSize'
import { YoutubeChannelAutocomplete } from '../../components/YoutubeChannelAutocomplete/YoutubeChannelAutocomplete'
import { API } from '../../helpers/Api.helper'
import { DEFAULT_PAGE_SIZE } from '../../helpers/constants'
import { dataProvider } from '../../providers/dataProvider'
import { YoutubeRawImportRow } from '../../types/YoutubeRawImportRow'
import { useAgGridBulkSelect } from './AgGrid/aggridBulkSelect'
import { AgGridLoader, useAgGridLoader } from './AgGrid/aggridLoader'
import styles from './ImportAmendmentsStep.module.scss'
import { ImportCreateBeneficiaryModal } from './ImportCreateBeneficiaryModal'

export type ImportsAmendBeneficiaryProps = {
  youtubeRawImportId: string
  updateUntreatedResults?: () => void
}

const UpdateBeneficiaryCellRenderer = React.memo((params: any) => {
  const translate = useTranslate()
  const notify = useNotify()

  const beneficiaryName = params.data?.beneficiary?.name ?? params.data?.['beneficiary.name']
  const beneficiaryId = params.data?.beneficiaryId

  const [internalValue, setInternalValue] = React.useState(() =>
    beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null,
  )

  React.useEffect(() => {
    setInternalValue(beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null)
  }, [beneficiaryId, beneficiaryName])

  const onChange = React.useCallback(
    async (_: unknown, value: any) => {
      setInternalValue(value)
      try {
        await API.put(`api/youtubeRawImportRows/${params.data.id}`, {
          beneficiaryId: value?.id,
        })
        params.node?.setData({
          ...params.data,
          beneficiaryId: value?.id,
          beneficiary: value,
          'beneficiary.name': value?.name,
        })
        params.onDidUpdate?.()
        // TODO: find how to notify from here without reloading : there is a werid bug, if we keep the notify :
        // - 1/ select a beneficiary in the autocomplete
        // - 2/ the toast is shown
        // - 3/ open another autocomplete
        // - 4/ when the toast is dismissed, the autocomplete is closed
        // notify('pages.importsAmendment.toast_editBeneficiarySuccess', { type: 'success' })
      } catch (e) {
        setInternalValue(beneficiaryId ? { id: beneficiaryId, name: beneficiaryName } : null)
        notify('pages.importsAmendment.toast_editBeneficiaryError', {
          type: 'error',
        })
      }
    },
    [beneficiaryId, beneficiaryName, params.data?.id, params.node],
  )

  return useMemo(
    () => (
      <Autocomplete
        id="beneficiary-edit"
        options={params.allBeneficiaries}
        value={internalValue}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        accessKey="id"
        getOptionLabel={option => option.name}
        renderInput={_params => <TextField {..._params} key={_params.id} variant="standard" />}
        noOptionsText={translate('_generics.noResult')}
        onChange={onChange}
      />
    ),
    [params.allBeneficiaries, internalValue, translate, onChange],
  )
})

export const ImportsAmendBeneficiary = ({
  youtubeRawImportId,
  updateUntreatedResults,
}: ImportsAmendBeneficiaryProps): JSX.Element => {
  const translate = useTranslate()
  const notify = useNotify()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAutoAssignModalOpen, setIsAutoAssignModalOpen] = useState(false)

  const gridRef = useRef<AgGridReact>(null)
  const updateUntreatedResultsRef = React.useRef(updateUntreatedResults)
  updateUntreatedResultsRef.current = updateUntreatedResults

  const youtubeChannelsQuery = useGetList('youtubeChannels', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })
  const beneficiariesQuery = useGetList('beneficiaries', {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: 'name', order: 'ASC' },
  })

  const [q, setQ] = React.useState('')
  const [selectedYoutubeChannel, setSelectedYoutubeChannel] = React.useState<RaRecord | null>(null)
  const [showOnlyIncomplete, setShowOnlyIncomplete] = React.useState(false)
  const [bulkBeneficiary, setBulkBeneficiary] = React.useState<RaRecord | null>(null)

  const {
    currentPageHasEverythingSelected,
    onCheckAllChanged,
    onSelectionChanged,
    totalSelectionCount,
    selection,
    onPaginationChanged,
  } = useAgGridBulkSelect(gridRef)

  useEffect(() => {
    gridRef.current?.api?.onFilterChanged()
  }, [showOnlyIncomplete, selectedYoutubeChannel, q])

  React.useEffect(() => {
    onSelectionChanged()
  }, [q, selectedYoutubeChannel, showOnlyIncomplete, onSelectionChanged])

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'assetId',
        headerName: 'Asset ID',
        resizable: true,
        checkboxSelection: true,
        headerComponent: () => {
          return (
            <>
              <Checkbox checked={currentPageHasEverythingSelected} onChange={onCheckAllChanged} />
              <div>Asset ID</div>
            </>
          )
        },
      },
      {
        field: 'assetChannelId',
        headerName: translate('_generics.youtubeChannel'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        width: 200,
        valueGetter: params =>
          params.data?.['internalYoutubeChannel.name'] ?? params.data?.internalYoutubeChannel?.name,
        cellRenderer: (params: ICellRendererParams<YoutubeRawImportRow>) => {
          if (!params.data) {
            return null
          }
          if (params.data?.originalAssetChannelId) {
            return (
              <RALink
                to={`/youtubeChannels/${params.data.originalAssetChannelId}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </RALink>
            )
          }

          return (
            <YoutubeChannelAutocomplete
              {...params}
              allYoutubeChannels={youtubeChannelsQuery?.data ?? []}
            />
          )
        },
      },
      {
        field: 'assetTitleEdited',
        headerName: translate('pages.importsAmendment.assetTitle'),
        suppressMenu: true,
        resizable: true,
        sortable: true,
        flex: 1,
        cellStyle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          paddingTop: 15,
          paddingBottom: 15,
        },
        wrapText: true,
        autoHeight: true,
      },
      {
        field: 'beneficiary',
        headerName: translate('_generics.beneficiary'),
        suppressMenu: true,
        resizable: true,
        width: 250,
        equals: (a, b) => a?.beneficiaryId === b?.beneficiaryId,
        cellStyle: {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          paddingTop: 6,
          paddingBottom: 6,
        },
        cellRenderer: (params: any) => (
          <UpdateBeneficiaryCellRenderer
            {...params}
            allBeneficiaries={beneficiariesQuery?.data ?? []}
            onDidUpdate={updateUntreatedResultsRef.current}
          />
        ),
      },
    ],
    [
      translate,
      currentPageHasEverythingSelected,
      onCheckAllChanged,
      youtubeChannelsQuery?.data,
      beneficiariesQuery?.data,
    ],
  )

  const { startLoading, stopLoading } = useAgGridLoader(gridRef)
  const getRows = React.useCallback(
    async (params: IGetRowsParams) => {
      const perPage = gridRef.current?.api.paginationGetPageSize() ?? 100
      startLoading(`${params.startRow}`)
      const data = await dataProvider.getList('youtubeRawImportRows', {
        pagination: { page: params.startRow / perPage + 1, perPage },
        sort:
          params.sortModel.length > 0
            ? ({
                field: params.sortModel[0].colId,
                order: params.sortModel[0].sort.toUpperCase(),
              } as SortPayload)
            : ({ field: 'assetId', order: 'ASC' } as SortPayload),
        filter: {
          youtubeRawImportId,
          q: q.trim().length > 0 ? q : undefined,
          ...(selectedYoutubeChannel ? { assetChannelId: selectedYoutubeChannel.id } : {}),
          ...(showOnlyIncomplete ? { beneficiaryId: true } : {}),
        },
      })
      params.successCallback(data.data, data.total)
      stopLoading(`${params.startRow}`)
    },
    [q, selectedYoutubeChannel, showOnlyIncomplete, youtubeRawImportId, startLoading, stopLoading],
  )

  const datasource = React.useMemo(() => {
    return { getRows }
  }, [getRows])

  const onAutoAssignBeneficiariesModalChange = React.useCallback(() => {
    gridRef.current?.api?.refreshInfiniteCache()
  }, [])

  const onBulkUpdate = React.useCallback(() => {
    const limit = pLimit(10)
    const beneficiaryId = bulkBeneficiary?.id
    if (!beneficiaryId) {
      return
    }
    Promise.all(
      selection.map(node =>
        limit(async () => {
          await API.put(`api/youtubeRawImportRows/${node.data.id}`, {
            beneficiaryId,
          })
          node.setData({
            ...node.data,
            beneficiaryId,
            beneficiary: bulkBeneficiary,
            'beneficiary.name': bulkBeneficiary?.name,
          })
        }),
      ),
    )
      .then(() => {
        updateUntreatedResultsRef.current?.()
        gridRef.current?.api.deselectAll()
        notify('pages.importsAmendment.toast_editBulkBeneficiarySuccess', {
          type: 'success',
        })
      })
      .catch(() => {
        notify('pages.importsAmendment.toast_editBeneficiaryError', {
          type: 'error',
        })
      })
  }, [bulkBeneficiary, notify, selection])

  return (
    <>
      <Grid container direction="row" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            setIsAutoAssignModalOpen(true)
          }}
          label={translate('pages.importsAmendment.autoAssignBeneficiariesModal.title')}
          className={styles.button}
        >
          <Star />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsModalOpen(true)
          }}
          label={translate('pages.beneficiaries.addBeneficiary')}
          className={styles.button}
        >
          <PersonAdd />
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            gridRef.current?.api?.refreshInfiniteCache()
          }}
          label={translate('_generics.refresh')}
          className={styles.button}
        >
          <Refresh />
        </Button>
      </Grid>
      <Grid
        item
        container
        lg={12}
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={2}
        style={{
          paddingLeft: 12,
          paddingTop: 12,
          paddingBottom: 12,
          marginTop: 12,
        }}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-start"
          lg={6}
          spacing={2}
        >
          <Grid item lg={6}>
            <TextField
              hiddenLabel
              label=""
              fullWidth
              value={q}
              onChange={e => setQ(e.target.value)}
              placeholder={translate('ra.action.search')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item lg={6}>
            <Autocomplete
              id="artworks-list-merge"
              options={youtubeChannelsQuery?.data ?? []}
              value={selectedYoutubeChannel}
              accessKey="id"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  {...params}
                  key={params.id}
                  placeholder={translate('_generics.youtubeChannel')}
                  variant="standard"
                />
              )}
              noOptionsText={translate('_generics.noResult')}
              onChange={(_, value) => setSelectedYoutubeChannel(value)}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                value={showOnlyIncomplete}
                onChange={(e, checked) => setShowOnlyIncomplete(checked)}
              />
            }
            label={translate('pages.importsAmendment.showOnlyMissing')}
          />
        </Grid>
      </Grid>
      {totalSelectionCount > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          lg={12}
          style={{ backgroundColor: '#e9ecf7', padding: '12px' }}
        >
          <Grid item lg={4} style={{ alignSelf: 'center' }}>
            <Typography variant="body2">
              {totalSelectionCount}{' '}
              {translate('_generics.selected', {
                smart_count: totalSelectionCount,
              })}
              <Button
                size="small"
                onClick={() => {
                  gridRef.current?.api?.deselectAll()
                }}
              >
                <ClearIcon />
              </Button>
            </Typography>
          </Grid>
          <Grid item container lg={8} spacing={2} justifyContent="flex-end">
            <Grid item lg={6}>
              <Autocomplete
                id="beneficiary-edit-bulk"
                options={beneficiariesQuery?.data ?? []}
                value={bulkBeneficiary}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                accessKey="id"
                getOptionLabel={option => option.name}
                renderInput={_params => (
                  <TextField
                    {..._params}
                    key={_params.id}
                    placeholder={translate('_generics.beneficiary')}
                    variant="standard"
                  />
                )}
                noOptionsText={translate('_generics.noResult')}
                onChange={async (_, value) => {
                  setBulkBeneficiary(value)
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                label={translate('_generics.update')}
                onClick={onBulkUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <div className="ag-theme-material" style={{ width: '100%', height: '80vh' }}>
        <AgGridReact
          ref={gridRef}
          enableCellTextSelection
          ensureDomOrder
          rowModelType="infinite"
          datasource={datasource}
          pagination={true}
          paginationPageSize={DEFAULT_PAGE_SIZE}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          onPaginationChanged={onPaginationChanged}
          loadingOverlayComponent={AgGridLoader}
        />
        {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
        <LXPageSize onPageSizeChange={size => gridRef.current?.api!.paginationSetPageSize(size)} />
      </div>
      <ImportCreateBeneficiaryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <AutoAssignBeneficiariesModal
        youtubeImportId={youtubeRawImportId}
        isModalOpen={isAutoAssignModalOpen}
        setIsModalOpen={setIsAutoAssignModalOpen}
        onChange={onAutoAssignBeneficiariesModalChange}
      />
    </>
  )
}

const defaultColDef = {
  cellStyle: { paddingLeft: 8, paddingRight: 8 },
}

import * as React from 'react'

import Typography from '@mui/material/Typography'
import { Labeled, useRecordContext } from 'react-admin'

import { NumberFormatter } from '../helpers/NumberFormatter.helper'

export interface LXNumberFieldProps {
  source: string
  label?: string
  format: 'euros' | 'large' | 'percentRate'
  component?: React.ElementType
  className?: string
  hideLabel?: boolean
}

export const LXNumberField: React.FC<LXNumberFieldProps> = ({
  source,
  format,
  label,
  component,
  className,
  hideLabel = false,
}) => {
  const record = useRecordContext()

  const displayed = React.useMemo(() => {
    const numberValue = record?.[source]
    switch (format) {
      case 'euros':
        return NumberFormatter.formatEuros(numberValue)
      case 'large':
        return NumberFormatter.formatLargeNumber(numberValue)
      case 'percentRate':
        return NumberFormatter.formatRate(numberValue)
      default:
        return typeof numberValue === 'number' ? numberValue.toString() : ''
    }
  }, [format, record, source])

  const value = (
    <Typography component={component ?? 'span'} variant="body2" className={className}>
      {displayed}
    </Typography>
  )

  if (hideLabel) {
    return value
  }

  return (
    <Labeled label={label} hidden={true}>
      {value}
    </Labeled>
  )
}

import React, { useCallback, useEffect, useState } from 'react'

import { Edit as EditIcon } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import {
  AutocompleteInput,
  Button,
  Edit,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'

import { NoDeleteEditToolbar } from '../../components/NoDeleteEditToolbar'
import { Artwork } from '../../types/Artwork'
import { LXField } from './../../components/LXField'
import { API } from './../../helpers/Api.helper'
import { ListHelper } from './../../helpers/List.helper'
import { Beneficiary } from './../../types/Beneficiary'
import { Modal } from './../../types/Modal'
import { Product } from './../../types/Product'
import styles from './ArtworksEdit.module.scss'

export const ArtworksEdit = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [artworkName, setArtworksName] = useState('')
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const params = useParams()

  // Get artworkName for App title
  useEffect(() => {
    if (!params.id) {
      return
    }

    dataProvider
      .getOne('artworks', { id: params.id as string })
      .then(({ data }) => setArtworksName(data.name))
      .catch(() => setArtworksName(''))
  })

  return (
    <>
      <Edit
        mutationMode="pessimistic"
        title={`${translate('pages.artworks._title')} / ${artworkName}`}
      >
        <SimpleForm toolbar={<NoDeleteEditToolbar />}>
          <ArtworksInformations setIsModalOpen={setIsModalOpen} />
          <ArtworksEditProductModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        </SimpleForm>
      </Edit>
    </>
  )
}

const ArtworksInformations = ({ setIsModalOpen }: Omit<Modal, 'isModalOpen'>) => {
  const translate = useTranslate()

  return (
    <Grid container direction="row" spacing={2}>
      <Grid container item direction="row" lg={12} spacing={2}>
        <LXField
          source="name"
          label={translate('_generics.name')}
          elementType={TextInput}
          size={6}
        />
        <LXField
          source="name"
          label={translate('_generics.product')}
          elementType={TextField}
          size={6}
          wrappingFunction={el => (
            <span style={{ position: 'relative' }}>
              <ReferenceField source="productId" reference="products" link={false}>
                {el}
              </ReferenceField>
              <EditIcon
                color="primary"
                className={styles.edit}
                onClick={() => setIsModalOpen(true)}
              />
            </span>
          )}
          labeled
        />
        <LXField
          source="productionYear"
          label={translate('_generics.productionYear')}
          elementType={TextInput}
        />
      </Grid>
    </Grid>
  )
}

const ArtworksEditProductModal = ({ isModalOpen, setIsModalOpen }: Modal) => {
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [currentBeneficiary, setCurrentBeneficiary] = useState<Beneficiary>()
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState<string>()
  const [selectedProductId, setSelectedProductId] = useState<string>()
  const [impactedRows, setImpactedRows] = useState<number>(-1)
  const [impactedRowsHash, setImpactedRowsHash] = useState<string>('')
  const translate = useTranslate()
  const record = useRecordContext<Artwork>()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()

  useEffect(() => {
    const getCurrentData = async () => {
      setCurrentProduct(record.product)
      setSelectedProductId(record.productId)

      const res = await dataProvider.getOne<Beneficiary>('beneficiaries', {
        id: record.product.beneficiaryId,
      })
      setCurrentBeneficiary(res.data)
    }
    if (record) {
      getCurrentData()
    }
  }, [dataProvider, record])

  const patchArtwork = useCallback(
    async (artworkId: string, productId: string, isDryRun = true) => {
      const res: ArtworkPatchRes = await API.patch(`/api/artworks/${artworkId}`, {
        productId,
        isDryRun,
        impactedImportRowsHash: impactedRowsHash,
      })

      setImpactedRows(res.data.impactedImportRows.count)
      setImpactedRowsHash(res.data.impactedImportRows.hash)

      return { isDryRun: res.data.isDryRun }
    },
    [impactedRowsHash],
  )

  const onProductChange = useCallback(
    async (productId: string) => {
      setSelectedProductId(productId)

      try {
        await patchArtwork(record.id as string, productId)
      } catch (e) {
        console.error(e)
      }
    },
    [record, setSelectedProductId, patchArtwork],
  )

  const transferArtworkToOtherProduct = useCallback(async () => {
    try {
      const res = await patchArtwork(record.id as string, selectedProductId as string, false)

      refresh()

      if (res.isDryRun === false) {
        setIsModalOpen(false)
        reset()
      }
    } catch (e) {
      console.error(e)
    }
  }, [record, selectedProductId, patchArtwork, refresh, setIsModalOpen])

  const reset = () => {
    setSelectedProductId(undefined)
    setImpactedRows(-1)
    setImpactedRowsHash('')
  }

  React.useEffect(() => {
    if (isModalOpen && currentBeneficiary?.id) {
      setSelectedBeneficiaryId(currentBeneficiary.id)
      reset()
    }
  }, [currentBeneficiary?.id, isModalOpen])

  return (
    <Dialog aria-labelledby="artwork-edit-product-modal" open={isModalOpen}>
      <DialogTitle id="artwork-edit-product-modal">
        {translate('pages.artworks.modal_transferArtworkProduct')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <LXField label={translate('pages.artworks.modal_currentBeneficiary')} labeled size={6}>
            {currentBeneficiary?.name}
          </LXField>
          <LXField label={translate('pages.artworks.modal_currentProduct')} labeled size={6}>
            {currentProduct?.name}
          </LXField>
        </Grid>
        <hr className={styles.separator} />
        <Typography variant="body1" color="textPrimary" className={styles.modalDescription}>
          <i>{translate('pages.artworks.modal_chooseBeneficiaryToFilter')}</i>
        </Typography>
        <Grid container direction="row" spacing={2}>
          <Grid item lg={6}>
            <ReferenceInput
              source="beneficiaryId"
              reference="beneficiaries"
              label={translate('_generics.beneficiary')}
              sort={ListHelper.DEFAULT_SORT}
            >
              <AutocompleteInput
                optionText="name"
                helperText={false}
                label="_generics.beneficiary"
                defaultValue={record.product.beneficiaryId}
                onChange={id => {
                  reset()
                  setSelectedBeneficiaryId(id)
                  refresh()
                }}
              />
            </ReferenceInput>
          </Grid>
          <Grid item lg={6}>
            <ReferenceInput
              resource="products"
              source="productId"
              reference="products"
              label={'_generics.product'}
              sort={ListHelper.DEFAULT_SORT}
              filter={{ beneficiaryId: selectedBeneficiaryId }}
            >
              <AutocompleteInput
                optionText="name"
                helperText={false}
                label="_generics.product"
                defaultValue={selectedProductId}
                onChange={onProductChange}
              />
            </ReferenceInput>
          </Grid>
        </Grid>
        <Typography variant="body1" color="textPrimary" className={styles.information}>
          <strong>
            {impactedRows > 0 &&
              translate('pages.artworks.modal_impactedRows', {
                smart_count: impactedRows,
              })}
            {impactedRows === 0 && translate('pages.artworks.modal_noImpactedRows')}
          </strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsModalOpen(false)
          }}
          label={translate('_generics.cancel')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            transferArtworkToOtherProduct()
          }}
          label={translate('_generics.transfer')}
          disabled={impactedRowsHash === ''}
        />
      </DialogActions>
    </Dialog>
  )
}

type ArtworkPatchRes = {
  data: {
    isDryRun: boolean
    impactedImportRows: {
      count: number
      hash: string
    }
  }
}

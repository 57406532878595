import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SortPayload } from 'react-admin'

import { getJWT } from './Auth.helper'

export const API = {
  KEY_HEADER: 'x-api-key',
  KEY: process.env.REACT_APP_API_KEY || '',
  URL: process.env.REACT_APP_API_URL || '',
  DEFAULT_PAGINATION: { page: 1, perPage: 100 },
  DEFAULT_SORT: { field: 'id', order: 'DESC' } as SortPayload,
  get: <T>(path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> =>
    axios.get(path, config),
  post: <T, D = any>(
    path: string,
    body: T,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<D>> => axios.post(path, body, config),
  put: <T>(path: string, body: T, config: AxiosRequestConfig = {}): Promise<AxiosResponse> =>
    axios.put(path, body, config),
  patch: <T>(path: string, body: T, config: AxiosRequestConfig = {}): Promise<AxiosResponse> =>
    axios.patch(path, body, config),
  delete: (path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> =>
    axios.delete(path, config),
}

axios.defaults.baseURL = API.URL
axios.defaults.headers.common = {
  [API.KEY_HEADER]: API.KEY,
}

axios.interceptors.request.use(config => {
  const jwt = getJWT()
  if (jwt) {
    config.headers = {
      ...(config.headers ?? {}),
      Authorization: `Bearer ${jwt}`,
    }
  }

  config.headers = {
    ...(config.headers ?? {}),
    'x-current-pathname': window.location.pathname,
  }

  return config
})

export type LXResponse = AxiosResponse

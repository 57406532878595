import React, { useCallback, useContext, useState } from 'react'

import SettingsApplications from '@mui/icons-material/SettingsApplications'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { Button, useNotify, useTranslate } from 'react-admin'

import { AnalyticsContext } from '../../contexts/AnalyticsContext'
import { API } from '../../helpers/Api.helper'

export const AnalyticsSetDefaultPreset = (): JSX.Element => {
  const context = useContext(AnalyticsContext)
  const translate = useTranslate()
  const notify = useNotify()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onDefineAsDefaultPreset = useCallback(async () => {
    setIsLoading(true)
    try {
      const beneficiary = context.selectedBeneficiaries[0]
      await API.put(`/api/beneficiaries/${beneficiary.id}`, {
        defaultDatavizPresetId: context.currentPreset?.id,
      })

      notify(
        translate('pages.analytics.toast_setDefaultPresetSuccess', {
          beneficiaryName: beneficiary.name,
        }),
        { type: 'success' },
      )
      setIsLoading(false)
      setIsModalOpen(false)
    } catch (e: unknown) {
      const message =
        (e as AxiosError)?.response?.data.message || (e as AxiosError)?.response?.data || e

      notify(`${translate('pages.analytics.toast_setDefaultPresetError')} - ${message}`, {
        type: 'error',
      })
      console.error('Error - save preset', e)
    }
  }, [context.currentPreset?.id, context.selectedBeneficiaries, notify, translate])

  return (
    <>
      <Button
        label={translate('pages.analytics.defineAsDefaultPreset')}
        onClick={() => setIsModalOpen(true)}
        disabled={context.selectedBeneficiaries.length !== 1 && !!context.currentPreset}
      >
        <SettingsApplications />
      </Button>
      <Dialog
        aria-labelledby="analytics-set-default-preset"
        open={isModalOpen}
        maxWidth="xs"
        fullWidth
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle id="analytics-set-default-preset">
          {translate('pages.analytics.defineAsDefaultPreset')}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" color="textPrimary">
            {translate('pages.analytics.modal_confirmSetDefaultPreset', {
              presetName: context.currentPreset?.name,
              beneficiaryName: context.selectedBeneficiaries[0]?.name,
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsModalOpen(false)
            }}
            label={translate('_generics.cancel')}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={onDefineAsDefaultPreset}
            label={translate('_generics.confirm')}
            disabled={isLoading}
          >
            <SettingsApplications />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

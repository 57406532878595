import React, { useCallback, useState } from 'react'

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Button, useNotify, useTranslate } from 'react-admin'

import { LXSelectDate } from '../../components/LXSelectDate'
import { API } from '../../helpers/Api.helper'
import { BeneficiaryHelper } from '../../helpers/Beneficiary.helper'
import { DateHelper } from '../../helpers/Date.helper'
import genericStyles from './../../generics.module.scss'
import styles from './CreateStatementModal.module.scss'

export interface CreateStatementModalProps {
  beneficiaryId?: string
  type?: 'beneficiary' | 'allBeneficiaries' | 'statementsSummaryCSV'
  isOpen?: boolean
  onClose: (created: boolean) => void
}

export const CreateStatementModal: React.FC<CreateStatementModalProps> = ({
  beneficiaryId,
  type = 'beneficiary',
  isOpen = false,
  onClose,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const [creating, setCreating] = React.useState(false)
  const [date, setDate] = useState(DateHelper.currentDate().subtract(1, 'month'))
  const [error, setError] = useState<string>()

  React.useEffect(() => {
    if (!isOpen) {
      setDate(DateHelper.currentDate().subtract(1, 'month'))
      setCreating(false)
    }
  }, [isOpen])

  const closeModal = useCallback(
    (isCreated: boolean) => {
      onClose(isCreated)
      setError(undefined)
    },
    [onClose, setError],
  )

  const createStatement = React.useCallback(async () => {
    if (type === 'beneficiary' && !beneficiaryId) {
      throw new Error('Should have beneficiaryId or shouldTargetAllBeneficiaries=true')
    }
    setCreating(true)
    setError(undefined)
    try {
      const month = date.get('month') + 1
      const year = date.get('year')

      if (type === 'statementsSummaryCSV') {
        await BeneficiaryHelper.downloadStatementsSummaryCsv(month, year)
      } else {
        const url = type === 'allBeneficiaries' ? '/api/statements/all' : '/api/statements'
        await API.post(url, { beneficiaryId, month, year })
      }
      closeModal(true)
      setCreating(false)
    } catch (e: any) {
      const message = e.response?.data?.message ?? e.response

      notify(`${message}`, { type: 'error' })
      console.error('Error - create statement', e)
      setError(`${message} - ${e.response?.data.content?.fieldError ?? e.response?.data.content}`)
    }
  }, [beneficiaryId, date, notify, closeModal, type])

  return (
    <Dialog aria-labelledby="youtube-import-modal" open={isOpen} onClose={() => closeModal(false)}>
      <DialogTitle id="youtube-import-modal">
        {translate(
          type === 'statementsSummaryCSV'
            ? 'pages.statements.createSummary'
            : type === 'allBeneficiaries'
              ? 'pages.statements.createAll'
              : 'pages.beneficiaries.createStatement',
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="row" spacing={4}>
          <Grid item lg={12}>
            <LXSelectDate
              label={translate('_generics.date')}
              date={date}
              setDate={setDate}
              datePickerProps={{
                maxDate: DateHelper.currentDate(),
              }}
            />
          </Grid>
        </Grid>
        {creating && !error && (
          <div className={styles.message}>
            <CircularProgress size="1rem" />
          </div>
        )}
        {error && (
          <p className={genericStyles.error}>
            <i>{translate('_generics.errorOccured')}</i>:&nbsp;
            <strong>{error}</strong>
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal(false)}
          label={translate('_generics.cancel')}
          disabled={creating && !error}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            createStatement()
          }}
          label={translate('_generics.create')}
          disabled={creating}
        />
      </DialogActions>
    </Dialog>
  )
}

import React from 'react'

import ProductIcon from '@mui/icons-material/Collections'
import { MenuItemLink, MenuProps, useTranslate } from 'react-admin'

import { MenuHelper } from './../helpers/Menu.helper'
import { SubMenu } from './SubMenu'

export const Menu = ({ dense = false }: MenuProps): JSX.Element => {
  const translate = useTranslate()

  return (
    <div>
      <SubMenu name={translate('_generics.catalog')} icon={<ProductIcon />} dense={dense}>
        {
          <MenuItemLink
            to="/youtubeChannels"
            primaryText={translate('pages.youtubeChannels._title')}
            leftIcon={<MenuHelper.icons.youtubeChannels />}
            dense={dense}
          />
        }
        {
          <MenuItemLink
            to="/beneficiaries"
            primaryText={translate('pages.beneficiaries._title')}
            leftIcon={<MenuHelper.icons.beneficiaries />}
            dense={dense}
          />
        }
        {
          <MenuItemLink
            to="/products"
            primaryText={translate('pages.products._title')}
            leftIcon={<MenuHelper.icons.products />}
            dense={dense}
          />
        }
        {
          <MenuItemLink
            to="/artworks"
            primaryText={translate('pages.artworks._title')}
            leftIcon={<MenuHelper.icons.artworks />}
            dense={dense}
          />
        }
      </SubMenu>
      {
        <MenuItemLink
          to="/youtubeRawImports"
          primaryText={translate('pages.youtubeRawImports._title')}
          leftIcon={<MenuHelper.icons.youtubeRawImports />}
          dense={dense}
        />
      }
      {
        <MenuItemLink
          to="/statements"
          primaryText={translate('pages.statements._title')}
          leftIcon={<MenuHelper.icons.statements />}
          dense={dense}
        />
      }
      {
        <MenuItemLink
          to="/analytics"
          primaryText={translate('pages.analytics._title')}
          leftIcon={<MenuHelper.icons.analytics />}
          dense={dense}
        />
      }
      {
        <MenuItemLink
          to="/automated-treatments"
          primaryText={translate('pages.automatedTreatments._title')}
          leftIcon={<MenuHelper.icons.automatedTreatments />}
          dense={dense}
        />
      }
    </div>
  )
}

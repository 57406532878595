import { Warning } from '@mui/icons-material'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  TextField as MUITextField,
  Tooltip,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  GetListParams,
  Link,
  List,
  RaRecord,
  SearchInput,
  TextField,
  useTranslate,
} from 'react-admin'
import { API } from '../../helpers/Api.helper'
import { dataProvider } from '../../providers/dataProvider'
import { YoutubeChannel } from '../../types/YoutubeChannel'
import styles from './AutomatedTreatments.module.scss'

type YTChannelType = Pick<YoutubeChannel, 'id' | 'name'>

const MONETIZE_FILTER = { isAutoMonetizationEnabled: true }
const AUTOTAG_FILTER = { isAutoTagUpdateEnabled: true }

const DEFAULT_GET_OPTIONS: Pick<GetListParams, 'sort' | 'pagination'> = {
  sort: { field: 'id', order: 'ASC' },
  pagination: { page: 1, perPage: 999 },
}

export const AutomatedMonetization = () => {
  const translate = useTranslate()
  const [selectedChannelId, setSelectedChannelId] = useState<string | undefined>()
  const [channelsWithMonetize, setChannelsWithMonetize] = useState<
    Pick<YoutubeChannel, 'id' | 'name'>[]
  >([])

  useEffect(() => {
    const getYoutubeChannels = async () => {
      const monetizedChannelsQuery = await dataProvider.getList<YoutubeChannel>('youtubeChannels', {
        ...DEFAULT_GET_OPTIONS,
        filter: MONETIZE_FILTER,
      })
      const autotaggedChannelsQuery = await dataProvider.getList<YoutubeChannel>(
        'youtubeChannels',
        {
          ...DEFAULT_GET_OPTIONS,
          filter: AUTOTAG_FILTER,
        },
      )

      const channels = [...monetizedChannelsQuery.data, ...autotaggedChannelsQuery.data].filter(
        (yc, index, _channels) => _channels.findIndex(yc2 => yc2.id === yc.id) === index,
      )

      setChannelsWithMonetize(
        channels.map(({ id, name }) => ({
          id,
          name,
        })),
      )
    }

    getYoutubeChannels()
  }, [])

  return (
    <List
      resource="youtubeAutoEditVideos"
      exporter={false}
      perPage={API.DEFAULT_PAGINATION.perPage}
      filter={{
        channelId: selectedChannelId ?? channelsWithMonetize.map(c => c.id),
      }}
      filters={[<SearchInput key="search" source="q" alwaysOn />]}
      empty={false}
      sort={{ field: 'monetizedAt NULL LAST', order: 'DESC' }}
      actions={
        <Grid container justifyContent="flex-end" alignItems="flex-end" paddingBottom={1}>
          <Autocomplete<YTChannelType>
            key="channels"
            options={channelsWithMonetize}
            value={channelsWithMonetize.find(c => c.id === selectedChannelId)}
            className={styles.filter}
            getOptionLabel={option => option.name}
            renderInput={(p: AutocompleteRenderInputParams) => (
              <MUITextField
                label={translate('pages.youtubeChannels._title')}
                {...p}
                key={p.id}
                style={{ marginTop: '0.5rem' }}
                variant="standard"
              />
            )}
            onChange={(_, channel) => setSelectedChannelId(channel?.id)}
          />
        </Grid>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          sortable={false}
          source="channelId"
          label={translate('_generics.youtubeChannel')}
          render={(record: RaRecord | undefined) => {
            const ytc = channelsWithMonetize?.find(cs => cs.id === record?.channelId)

            return <Link to={`/youtubeChannels/${ytc?.id}/show`}>{ytc?.name}</Link>
          }}
        />
        <TextField
          sortable={false}
          source="monetizationPolicyId"
          label={translate('pages.youtubeChannels.autoMonetizationPolicyId')}
        />
        <TextField
          sortable={false}
          source="monetizationAssetId"
          label={translate('pages.automatedTreatments.monetizationAssetId')}
        />
        <DateField
          sortBy="monetizedAt NULL LAST"
          source="monetizedAt"
          label={translate('pages.automatedTreatments.monetizedAt')}
        />
        <TextField
          sortable={false}
          source="insertedTags"
          label={translate('pages.automatedTreatments.insertedTags')}
        />
        <DateField
          sortBy="insertedTagsAt NULL LAST"
          source="insertedTagsAt"
          label={translate('pages.automatedTreatments.insertedTagsAt')}
        />
        <DateField
          source="publishedOnYoutubeAt"
          label={translate('pages.automatedTreatments.publishedOnYoutubeAt')}
        />
        <FunctionField
          sortable={false}
          source="error"
          label={translate('pages.automatedTreatments.error')}
          render={(record: RaRecord | undefined) =>
            record?.error ? (
              <Tooltip title={JSON.stringify(record?.error)} placement="top" arrow>
                <Warning color="error" />
              </Tooltip>
            ) : (
              <></>
            )
          }
        />
      </Datagrid>
    </List>
  )
}

import React from 'react'

import { Card, CardContent } from '@mui/material'
import { Title } from 'react-admin'

export const NotFound = (): JSX.Element => (
  <Card>
    <Title title="Not Found" />
    <CardContent>
      <h1>404</h1>
      <h2>Page Not Found</h2>
    </CardContent>
  </Card>
)

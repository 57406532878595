import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import { AutomatedMonetization } from './AutomatedMonetization'
import { AutomatedTranslation } from './AutomatedTranslation'
import styles from './AutomatedTreatments.module.scss'

type TabOption = 'monetization' | 'translation'

export const AutomatedTreatments = () => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const params = useParams()
  const [tab, setTab] = useState<TabOption>(
    (params.automatedTreatmentTab as TabOption) ?? 'translation',
  )

  useEffect(() => {
    navigate(`/automated-treatments/${tab}`)
  }, [])

  const onTabChange = useCallback(
    (tabValue: TabOption) => {
      setTab(tabValue)
      navigate(`/automated-treatments/${tabValue}`)
    },
    [navigate],
  )

  return (
    <Card>
      <CardContent className={styles.container}>
        <Tabs value={tab} onChange={(_, v) => onTabChange(v)}>
          <Tab value="translation" label={translate('pages.automatedTreatments.translation')} />
          <Tab value="monetization" label={translate('pages.automatedTreatments.monetization')} />
        </Tabs>
        {tab === 'translation' && <AutomatedTranslation />}
        {tab === 'monetization' && <AutomatedMonetization />}
      </CardContent>
    </Card>
  )
}

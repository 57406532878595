import * as React from 'react'

import { Layout, LayoutProps } from 'react-admin'

import { AppBar } from './AppBar/AppBar'
import { Menu } from './Menu'

export const LXLayout = (props: LayoutProps): JSX.Element => (
  <Layout {...props} menu={Menu} appBar={AppBar} />
)
